import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  IconButton,
  TableRow,
  TableCell,
  TableContainer
} from '@mui/material'
import React from 'react'
import AddEntityForm from './AddEntityForm'
import { type ReviewState, type ReviewStateItem, findAliasItems, aliasOf } from '../../../services/reviewState'
import ReviewStateItemRow from './ReviewStateItemRow'
import { ExpandMore } from '@mui/icons-material'
import { useGlobals } from '../../../context/GlobalsContext'
import { type AliasRule, type RuleKey } from 'features/anonymization/types'
import { type Globals } from 'features/globals/types'
import { tableContainerSx } from './InputReviewTable.styles'
import { useIntl } from 'react-intl'

interface Props {
  state: ReviewState
  onAddAnonRule: (cleartext: string, entityType: string, asAliasOf?: string) => void
  onChange: (
    prevItem: ReviewStateItem,
    item: ReviewStateItem,
    newItem?: ReviewStateItem,
    includeAliases?: boolean
  ) => void
  onChangeAliasRule: (aliasRule: AliasRule) => void
  onDeleteAnonRule: (key: RuleKey) => void
}

const InputReviewTable: React.FC<Props> = ({
  state,
  onAddAnonRule,
  onChange,
  onChangeAliasRule,
  onDeleteAnonRule
}) => {
  const intl = useIntl()
  const globals = useGlobals() as Globals

  const [openedGroups, setOpenedGroups] = React.useState<boolean[]>(
    Array.from(globals.entityTypesByGroup).map(() => true)
  )

  return (
    <TableContainer component={Paper} sx={tableContainerSx}>
      <Table aria-label="detected-tags-table" stickyHeader>
        <TableHead>
          <AddEntityForm
            scope='msg-specific'
            existingEntities={state.items.map(({ cleartext }) => cleartext)}
            onAddAnonRule={onAddAnonRule}
          />
        </TableHead>
        <TableBody>
          {Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => {
            const entityTypes = entityTypeInfos.map((eti) => eti.entityType)
            const rows = [
              <TableRow key={idx + 1}>
                <TableCell colSpan={3}>
                  {/* Button to fold / un-fold the group */}
                  <IconButton
                    aria-label="expand"
                    size="small"
                    onClick={() => {
                      const newOpenedGroups = [...openedGroups]
                      newOpenedGroups[idx] = !openedGroups[idx]
                      setOpenedGroups(newOpenedGroups)
                    }}
                  >
                    {openedGroups[idx] ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                  </IconButton>
                  <b>
                    {
                      intl.formatMessage({
                        id: `app.entity-group.${group}`,
                        defaultMessage: group
                      })
                    }
                  </b>
                </TableCell>
              </TableRow>
            ]
            if (openedGroups[idx]) {
              rows.push(
                ...state.items.filter((item) => {
                  // Show items that belong to this group,
                  // and that are not aliases of other items
                  return (
                    entityTypes.includes(item.entityType) &&
                    aliasOf(item, state.items) === null
                  )
                }).map((item, idx2) => (
                  <ReviewStateItemRow
                    key={(idx + 1) * 100 + (idx2 + 1)}
                    item={item}
                    items={state.items}
                    aliasItems={findAliasItems(item, state.items)}
                    entityTypeToActiveEntitiesCleartext={state.entityTypeToActiveEntitiesCleartext}
                    scope='msg-specific'
                    onChange={onChange}
                    onChangeAliasRule={onChangeAliasRule}
                    onDeleteAnonRule={onDeleteAnonRule}
                  />
                ))
              )
            }
            return rows
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InputReviewTable
