import { Avatar, Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  width: 44,
  height: 44
}))

export const StyledAvatar = styled(Avatar)(() => ({
  position: 'relative',
  top: -42,
  left: -2,
  width: 44,
  height: 44
}))
