import { type AttachmentListItem, DOC_TYPE_CONTRACT } from 'features/documents'
import { AnalysisTaskParams, type UserInputForm } from '../types'
import { contractIsAttached } from 'features/documents/utils/contractIsAttached'

/**
 * Update the user input form based on the given uploaded attachments.
 */
export const updateUserInputFormWithAttachments = (
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentListItem[]
): Partial<UserInputForm> => {
  // User input form has a docType field, which is used to determine
  // whether a contract is attached or not.
  // FIXME: This field is deprecated and should be removed.
  const docType = (
    contractIsAttached(attachments)
      ? DOC_TYPE_CONTRACT
      : (
          attachments.length > 0
            ? attachments[0].docType
            : undefined
        )
  )

  // Update taskParams if necessary.
  // If the user has selected 'Analyze' as the main task and
  // the SpecificAnalysis action, but the attachments no longer
  // contain a contract, it should reset the task params to undefined.
  let taskParams = userInputForm.taskParams
  if (
    taskParams !== undefined &&
    taskParams !== null &&
    userInputForm.mainTask === 'Analyze' &&
    (taskParams as AnalysisTaskParams).action === 'Specific analysis' &&
    docType !== DOC_TYPE_CONTRACT
  ) {
    taskParams = undefined
  }

  return { ...userInputForm, docType, taskParams }
}
