import { withAuthenticationRequired } from '@auth0/auth0-react'
import type React from 'react'
import AuthProvider from './AuthProvider'
import Loading from './Loading'

interface Props {
  component: React.ComponentType<any>
}

/**
 * Ensures that the user is authenticated before rendering the component.
 */
export const AuthenticationGuard: React.FC<Props> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => {
      console.debug('User not authenticated, will redirect to login')
      return <Loading />
    }
  })

  return (
    <AuthProvider>
      <Component />
    </AuthProvider>
  )
}

export default AuthenticationGuard
