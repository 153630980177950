import { Box, Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  px: { xs: 0, sm: 1 },
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  minWidth: '42px',
  alignSelf: 'flex-start',
  color: theme.palette.primary.main,
  '&.Mui-disabled': {
    color: 'gray'
  }
}))

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  alignSelf: 'center'
}))
