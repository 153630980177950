import { type SxProps, type Theme, styled, Box } from '@mui/material'

export const listItemSx = (selected: boolean): SxProps<Theme> => (
  {
    // This ensures that the height will not change depending on whether
    // the item is selected or not
    minHeight: '100px',
    gap: 2,
    bgcolor: selected ? 'primary.main' : 'none',
    color: selected ? 'primary.contrastText' : 'inherit',
    ':hover': {
      cursor: 'pointer',
      bgcolor: 'primary.dark',
      color: 'primary.contrastText'
    },
    // Make all child MuiTypography inherit the color
    '& .MuiTypography-root': { color: 'inherit' }
  }
)

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}))
