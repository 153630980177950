import { type AliasRule, type AnonRule, type AnonymizationSettings, type Entity, type Tag } from '../types'

import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type ReviewStateItem } from 'services/reviewState'

export interface AnonymizeResponse {
  texts: string[]
  anonTexts: string[]
  inputDiffs: Array<Array<[string, number, Tag | null]>>
  reviewItems: ReviewStateItem[]
  existingEntities: Entity[]
}

export const anonymize = async (
  token: string,
  text: string,
  attachmentIds: string[],
  settings: AnonymizationSettings,
  msgAnonymizationRules: AnonRule[],
  msgAliasRules: AliasRule[],
  existingEntities: Entity[],
  user: User,
  abortController?: AbortController
): Promise<AnonymizeResponse> => {
  const response = await axiosInstance.post<AnonymizeResponse>(
    '/anonymize',
    {
      text,
      attachmentIds,
      settings,
      msgAnonymizationRules,
      msgAliasRules,
      existingEntities
    },
    {
      headers: createAuthHeaderFromToken(token),
      id: 'anonymize',
      cache: {
        ttl: defaultTTL
      },
      // If AbortController is provided, use it to cancel the request
      signal: abortController?.signal
    }
  )
  return response.data
}
