import { Button, Divider, Typography } from '@mui/material'
import BackIconButton from 'components/buttons/BackIconButton'
import { GatewaiLogo } from 'components/left-pane/GatewaiLogo'
import { WelcomeModalContainer } from 'components/WelcomeModalContainer'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

function WelcomePage (): JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const [selection, setSelection] = useState<string | undefined>(undefined)

  if (selection === 'sign-up') {
    const iframeSrc = 'https://a017fa2e.sibforms.com/serve/MUIFAMkDgqEy4mNPIud9lRpB5AfZrYdlnjjAprS-QH__XmMlfoC0LSmn1emae935mcMu2CyKhlQyHhT-3uT0IRv37PQEhqQoKLW9vsEh0S5yfZVM3l4Xlhee6CWJV4rdo4VON2dLUq9xMkYy5c2VUQiPGW08IMWPT5MkgvluUl8E0VGyrXJBY5CmiIhmv-Ri2IP67isqEzKskQPX'
    return (
      <WelcomeModalContainer>
        <BackIconButton onClick={() => { setSelection(undefined) }} />
        <Typography variant="body2" sx={{ m: 2 }}>
          <p>
            {
              intl.formatMessage({
                id: 'app.welcome-page.intro',
                defaultMessage: (
                  'We are currently running an early version of our assistant with a ' +
                  'first circle of lawyers and firms helping us build the right products ' +
                  'for the right problems.'
                )
              })
            }
          </p>
          <p>
            {
              intl.formatMessage({
                id: 'app.welcome-page.intro-2',
                defaultMessage: (
                  'If you are interested in testing Gatewai’s Legal AI assistant and ' +
                  'working with us to develop AI solutions suited to your needs, please let ' +
                  'us know and we will reach out to organize an introductory meeting as ' +
                  'soon as possible.'
                )
              })
            }
          </p>
        </Typography>
        <iframe width="540" height="380" src={iframeSrc} frameBorder="0" scrolling="auto" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }} />
      </WelcomeModalContainer>
    )
  }
  return (
    <WelcomeModalContainer>
      <GatewaiLogo />
      <Typography variant="h6">
        {
          intl.formatMessage({
            id: 'app.welcome-page.title',
            defaultMessage: 'Welcome to Copilex!'
          })
        }
      </Typography>
      <Button
        variant="contained"
        onClick={() => { navigate('/login') }}
      >
        Sign in
      </Button>
      <Divider />
      <Typography variant="body2">
        {/*  */}
        {
          intl.formatMessage({
            id: 'app.welcome-page.message',
            defaultMessage: (
              'Copilex is only available to our early adopters at this stage. ' +
              'If you would like to try it, ' +
              'please contact us and we will get in touch as soon as possible'
            )
          })
        }
      </Typography>
      <Button
        variant="contained"
        onClick={() => { setSelection('sign-up') }}
      >
        {
          intl.formatMessage({
            id: 'app.welcome-page.get-early-access',
            defaultMessage: 'Get an early access'
          })
        }
      </Button>
    </WelcomeModalContainer>
  )
}

export default WelcomePage
