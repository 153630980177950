/**
 * Dropdown to select the language to translate the message to
 */

import { MenuItem, Select } from '@mui/material'
import { type TranslateTaskParams, type UserInputForm } from 'features/userinputform/types'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
  isTarget: boolean
}

const supportedLanguages: string[] = ['en', 'fr', 'de', 'es', 'it', 'nl', 'pt', 'ru', 'zh', 'ja', 'ko', 'ar']
const sourceLanguages = ['all', ...supportedLanguages]
const targetLanguages = supportedLanguages

const TranslateLanguageSelect = ({
  userInputForm,
  onChange,
  isTarget
}: Props): JSX.Element => {
  const intl = useIntl()

  const key = isTarget ? 'targetLang' : 'sourceLang'
  const langs = isTarget ? targetLanguages : sourceLanguages
  const defaultLang = langs[0]

  if (
    userInputForm === undefined ||
    userInputForm === null ||
    userInputForm.mainTask !== 'Translate'
  ) {
    throw new Error('TranslateLanguageSelect must be used with a Translate task')
  }

  const taskParams = (userInputForm.taskParams ?? {}) as TranslateTaskParams

  return (
    <Select
      value={taskParams[key] ?? defaultLang}
      displayEmpty
      onChange={(event) => {
        const action = event.target.value
        onChange({
          ...userInputForm,
          taskParams: {
            ...taskParams,
            [key]: action
          }
        })
      }}
    >
      {/* Show a menu item for each selectable language */}
      {Object.values(langs).map((lang, idx) => (
        <MenuItem key={idx} value={lang}>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${lang}.label`,
              defaultMessage: lang
            })
          }
        </MenuItem>
      ))}
    </Select>
  )
}

export default TranslateLanguageSelect
