import { type Theme, rgbToHex } from '@mui/material'

export const sendIconStyle: any = {
  width: '63px',
  height: '63px',
  color: 'disabled'
}

export const fill = (disabled: boolean, palette: Theme['palette']): string => (
  disabled ? 'grey' : rgbToHex(palette.primary.main)
)
