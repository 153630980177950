import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { type Session } from '../types'

export function useSessions (): UseQueryResult<Session[], Error> {
  const { getAccessTokenSilently } = useAuth0()

  const queryFn = async (): Promise<Session[]> => {
    return await getAccessTokenSilently().then(async (token) =>
      await getSessions(token)
    )
  }

  return useQuery({
    queryKey: ['sessions'],
    queryFn
  })
}

const getSessions = async (token: string): Promise<Session[]> => {
  const authHeader = createAuthHeaderFromToken(token)
  return await axiosInstance.get<Session[]>(
    '/sessions',
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  ).then((response) => {
    return response.data
  })
}
