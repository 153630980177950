import { Box, Typography } from '@mui/material'
import { AnalysisTaskAction, AnalysisTaskParams, TranslateTaskParams, type UserInputForm } from 'features/userinputform/types'
import { type TaskEntry, tasks, mainTaskToLabel } from '../compose/TaskSelect'
import AttachmentsStack from 'features/documents/components/AttachmentsStack'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: UserInputForm
}

const rowSx = { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flexWrap: 'wrap' }

/**
 * Display a digest of the user input form for a user message,
 * e.g., what was the selected task and what attachments were uploaded
 * at the time of the message.
 */
export const UserInputFormDigest: React.FC<Props> = ({ userInputForm }: Props) => {
  const intl = useIntl()

  const addAnalyzeSpecificTaskComponents = (
    taskParams: AnalysisTaskParams,
    taskComponents: JSX.Element[]
  ): void => {
    taskComponents.push(
      <Typography key={2}>
        {' > '}
        {taskParams.action}
      </Typography>
    )
    if (
      taskParams.action === AnalysisTaskAction.SanityCheck &&
      taskParams.sanityCheckOptions !== undefined
    ) {
      // List of sanity check options to choose from
      const sanityCheckOptionsComponent = (
        <Typography key={3}>
          {' > ['}
          {
            taskParams.sanityCheckOptions.map((option) =>
              option.toString()
            ).join(', ')
          }
          {']'}
        </Typography>
      )
      taskComponents.push(sanityCheckOptionsComponent)
    } else if (
      taskParams.action === AnalysisTaskAction.SpecificAnalysis &&
      taskParams.specificAnalysisOptions !== undefined
    ) {
      // List of sanity check options to choose from
      const specificAnalysisOptionsComponent = (
        <Typography key={3}>
          {' > ['}
          {
            taskParams.specificAnalysisOptions.map((option) =>
              option.toString()
            ).join(', ')
          }
          {']'}
        </Typography>
      )
      taskComponents.push(specificAnalysisOptionsComponent)
    }
  }

  const addTranslateSpecificTaskComponents = (
    taskParams: TranslateTaskParams,
    taskComponents: JSX.Element[]
  ): void => {
    const { sourceLang, targetLang } = taskParams
    taskComponents.push(
      <Box key={2} sx={{ display: 'flex', gap: 1 }}>
        <Typography>
          {
            intl.formatMessage({
              id: 'app.translate-task-language-select.from.label',
              defaultMessage: 'From'
            })
          }
        </Typography>
        <Typography>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${sourceLang}.label`,
              defaultMessage: sourceLang
            })
          }
        </Typography>
        <Typography>
        {
            intl.formatMessage({
              id: 'app.translate-task-language-select.to.label',
              defaultMessage: 'to'
            })
          }
        </Typography>
        <Typography>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${targetLang}.label`,
              defaultMessage: targetLang
            })
          }
        </Typography>
      </Box>
    )
  }

  // Construct a list of components to render for the "Task" part
  const taskComponents: JSX.Element[] = []
  if (userInputForm.mainTask !== undefined && userInputForm.mainTask !== null) {
    const task: TaskEntry | undefined = tasks.find((task) => task.userInputForm.mainTask === userInputForm.mainTask)
    if (task !== undefined) {
      taskComponents.push(
        <Box key={0} sx={rowSx}>
          {task.icon}
        </Box>
      )
      taskComponents.push(
        <Typography key={1}>
          {mainTaskToLabel(userInputForm.mainTask).toUpperCase()}
        </Typography>
      )
      if (userInputForm.taskParams !== undefined && userInputForm.taskParams !== null) {
        if (userInputForm.mainTask === 'Analyze') {
          addAnalyzeSpecificTaskComponents(userInputForm.taskParams as AnalysisTaskParams, taskComponents)
        } else if (userInputForm.mainTask === 'Translate') {
          addTranslateSpecificTaskComponents(userInputForm.taskParams as TranslateTaskParams, taskComponents)
        }
      }
    }
  }

  return <Box className='user-input-form-digest' sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
    <Box sx={rowSx}>
      <Typography variant="caption" color="text.secondary">
        {
          intl.formatMessage({
            id: 'app.user-input-form-digest.task',
            defaultMessage: 'Task:'
          })
        }
      </Typography>
      {taskComponents}
    </Box>
    <Box sx={rowSx}>
      <Typography variant="caption" color="text.secondary">
        {
          intl.formatMessage({
            id: 'app.user-input-form-digest.attachments',
            defaultMessage: 'Attachments:'
          })
        }
      </Typography>
      <AttachmentsStack
        attachments={{
          uploaded: userInputForm.attachments,
          pending: []
        }}
        deletable={false}
      />
    </Box>
  </Box>
}

export default UserInputFormDigest
