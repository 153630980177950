import { type SxProps, type Theme } from '@mui/material'

export const iconButtonSx = (snap: boolean, palette: Theme['palette']): SxProps<Theme> => ({
  borderRadius: 0,
  backgroundColor: snap ? palette.primary.main : 'none',
  color: snap ? palette.primary.contrastText : palette.primary.main,
  fill: snap ? palette.primary.contrastText : palette.primary.main,
  '&:hover': {
    backgroundColor: snap ? palette.primary.dark : 'none'
  }
})
