import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type ReviewStateItem } from 'services/reviewState'
import { type AnonymizationSettings, type Entity } from '../types'

export const listUserSpecificRules = async (
  token: string,
  settings: AnonymizationSettings,
  existingEntities: Entity[],
  user: User
): Promise<ReviewStateItem[]> => {
  const response = await axiosInstance.post<ReviewStateItem[]>(
    '/user-specific-rules',
    {
      settings,
      existingEntities
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}
