import {
  Switch,
  Tooltip
} from '@mui/material'
import type React from 'react'
import { AutoRuleColoredIcon, ExcludeRuleIcon } from './RuleIcons'
import { type EntityType } from 'features/anonymization/types'
import { gatewaiBlueColor } from 'theme'
import { useIntl } from 'react-intl'

interface Props {
  entityType: EntityType
  active: boolean
  onChangeActive: (active: boolean) => void
}

/**
 * Renders a switch that allows to activate or deactivate the anonymization
 * for a specific entity type.
 */
const EntityTypeRuleSwitch: React.FC<Props> = ({ entityType, active, onChangeActive }: Props) => {
  const intl = useIntl()

  const switchTooltipTitle = (entityType: EntityType, active: boolean): string => {
    if (active) {
      return intl.formatMessage({
        id: 'app.entity-type-rule-switch.enabled-tooltip',
        defaultMessage: 'Click to not anonymize {entityType} entities by default'
      }, { entityType })
    }
    return intl.formatMessage({
      id: 'app.entity-type-rule-switch.disabled-tooltip',
      defaultMessage: 'Click to anonymize {entityType} entities by default'
    }, { entityType })
  }

  return <Tooltip title={switchTooltipTitle(entityType, active)}>
    <span>
      <Switch
        className='entity-type-rule-switch'
        checked={active}
        onChange={(event) => { onChangeActive(event.target.checked) }}
        checkedIcon={<AutoRuleColoredIcon />}
        icon={<ExcludeRuleIcon />}
        inputProps={{ 'aria-label': 'controlled' }}
        color='default'
        sx={{
          '.MuiSvgIcon-root': {
            // Gatewai blue when active, or a color that
            // contrasts with the background when inactive
            color: active ? gatewaiBlueColor : 'text.primary'
          }
        }}
      />
    </span>
  </Tooltip>
}

export default EntityTypeRuleSwitch
