import EmailIcon from '@mui/icons-material/Email'
import WarningIcon from '@mui/icons-material/Warning'
import { Badge, Button, Tooltip } from '@mui/material'
import { type Message } from 'features/messages/types'
import { useIntl } from 'react-intl'

interface Props {
  lastAssistantMessage?: Message
}

const ExportEmailButton: React.FC<Props> = ({ lastAssistantMessage }: Props) => {
  const intl = useIntl()

  const body = encodeURI(lastAssistantMessage?.cleartextContent ?? '')
  const emailIcon = <EmailIcon id={'export-email-icon'} sx={{
    opacity: (lastAssistantMessage === undefined ? 0.495 : 1.0),
    color: 'text.primary',
    width: '32px',
    height: '32px',
    m: 0
  }} />
  const warningIcon = (
    <WarningIcon
      sx={{
        color: 'text.primary',
        width: '16px',
        height: '16px'
      }}
    />
  )

  const outputTooLong = body.length > 2000

  const tooltip = intl.formatMessage({
    id: 'app.export-email-button.tooltip',
    defaultMessage: 'Export to email (experimental), max length 2000 characters'
  })

  // Note: did not use IconButton because I could not add the badge on top
  // of it when the body is too long
  return <Tooltip title={tooltip} arrow>
    <span>
      <Button
        startIcon={
          outputTooLong
            ? (
              <Badge badgeContent={warningIcon}>
                {emailIcon}
              </Badge>
              )
            : emailIcon
        }
        disabled={lastAssistantMessage === undefined}
        onClick={() => {
          // Truncated to 2000 characters because of email body length limit
          window.open(
            `mailto:?body=${body.slice(0, 2000)}`
          )
        } }
        sx={{
          // Show a grey background on hover to be consistent with the other buttons
          '&:hover': {
            // bgcolor: primaryMainColor,
            backgroundOrigin: 'content-box'
          },
          // Remove the extra right margin to center the icon
          '.MuiButton-startIcon': {
            m: 0
          }
        }}
      />
    </span>
  </Tooltip>
}

export default ExportEmailButton
