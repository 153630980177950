import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type EntityType, type MsgSpecificRules } from '../types'

export const addMsgSpecificRules = async (
  token: string,
  cleartext: string,
  entityType: EntityType,
  position: [number, number] | undefined,
  asAliasOf: string | undefined,
  msgSpecificRules: MsgSpecificRules,
  user: User
): Promise<MsgSpecificRules> => {
  const response = await axiosInstance.post<MsgSpecificRules>(
    '/msg-specific-rules',
    {
      cleartext,
      entityType,
      position,
      asAliasOf,
      msgAnonymizationRules: msgSpecificRules.msgAnonymizationRules,
      msgAliasRules: msgSpecificRules.msgAliasRules
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}
