import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Tooltip } from '@mui/material'
import type React from 'react'
import { useDeleteSession } from '../api/deleteSession'
import Loading from 'components/Loading'
import { useIntl } from 'react-intl'

interface DeleteSessionProps {
  sessionId: string
}

const DeleteSession: React.FC<DeleteSessionProps> = ({ sessionId }: DeleteSessionProps) => {
  const intl = useIntl()

  const { mutate, isError, isPending, error } = useDeleteSession()

  const handleClick = (): void => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      mutate(sessionId)
    }
  }

  if (isPending) return <Loading />
  if (isError) return <p>Error: {error.message}</p>

  const tooltip = intl.formatMessage({
    id: 'app.delete-session.tooltip',
    defaultMessage: 'Delete session'
  })

  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleClick}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}

export default DeleteSession
