import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { queryClient } from 'services/react-query'
import { type AttachmentsAndUploads } from '../types'
import { useSession } from 'context/SessionContext'

interface DeleteAttachmentProps {
  /**
   * The ID of the attachment to delete.
   * May not be defined yet for optimistic updates.
   */
  attachmentId: string | undefined
}

interface DeleteAttachmentContext {
  previousAttachments: AttachmentsAndUploads
}

/**
 * A mutation hook to delete an attachment from the current session.
 */
export function useDeleteAttachment ({ attachmentId }: DeleteAttachmentProps): UseMutationResult<void, Error, void, DeleteAttachmentContext> {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()

  if (selectedSession === null) {
    throw new Error('No session selected')
  }

  const mutationFn = async (): Promise<void> => {
    if (attachmentId === null || attachmentId === undefined) {
      throw new Error('No attachment ID provided')
    }
    await getAccessTokenSilently().then(async (token) => {
      await deleteAttachment(token, selectedSession.id, attachmentId)
    })
  }

  const queryKey = ['sessions', selectedSession.id, 'attachments']

  return useMutation({
    mutationKey: ['deleteAttachment', selectedSession.id],
    mutationFn,
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey, exact: true })

      // Snapshot the previous value
      const attachments = queryClient.getQueryData(queryKey) as AttachmentsAndUploads

      // Optimistically update to the new value
      const updatedAttachments = {
        uploaded: attachments.uploaded.filter((a) => a.id !== attachmentId),
        pending: attachments.pending
      }
      queryClient.setQueryData(queryKey, updatedAttachments)

      // Return a context object with the snapshot value
      return { previousAttachments: attachments }
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error(`Delete attachment ${attachmentId ?? 'null'} failed`, error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(queryKey, context.previousAttachments)
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries({
        queryKey,
        exact: true
      })
    }
  })
}

export const deleteAttachment = async (
  token: string,
  sessionId: string,
  attachmentId: string
): Promise<void> => {
  const authHeader = createAuthHeaderFromToken(token)
  await axiosInstance.delete(
    `/sessions/${sessionId}/attachments/${attachmentId}`,
    {
      headers: authHeader,
      cache: false
    }
  )
}
