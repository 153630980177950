import { SvgIcon } from '@mui/material'
import ShortcutIcon from '@mui/icons-material/Shortcut'

/**
 * Shortcut icon from MUI, with a "+" in the bottom right corner
 */
export const AddAliasIcon: React.FC = () => (
  <SvgIcon>
    <svg
      color={'inherit'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m21 11-6-6v5H8c-2.76 0-5 2.24-5 5v4h2v-4c0-1.65 1.35-3 3-3h7v5l6-6z"
        id="path2" />
      <path
        d="m 19.983911,19.76438 v 2.99 c 0,0 -1.99,0.01 -2,0 v -2.99 h -3 v -2 h 3 v -3 h 2 v 3 h 3 v 2 z"
        id="path1642" />
    </svg>
  </SvgIcon>
)

export const AliasIcon = ShortcutIcon
