import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import {
  Box,
  Tooltip
} from '@mui/material'
import { StyledIconButton } from './SidePaneToggleButton.styles'
import { useIntl } from 'react-intl'

interface Props {
  leftPaneOpen: boolean
  setLeftPaneOpen: (open: boolean) => void
}

const SidePaneToggleButton: React.FC<Props> = ({ leftPaneOpen, setLeftPaneOpen }: Props) => {
  const intl = useIntl()

  const tooltip = (
    leftPaneOpen
      ? intl.formatMessage({
        id: 'app.side-pane-toggle.close',
        defaultMessage: 'Close anonymization option panel'
      })
      : intl.formatMessage({
        id: 'app.side-pane-toggle.open',
        defaultMessage: 'More anonymization options'
      })
  )

  return <Box sx={{
    // Don't display on mobile: there's not enough space
    // to display the left pane properly, even by itself.
    display: { xs: 'none', sm: 'flex' },
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <Tooltip title={tooltip}>
      <StyledIconButton onClick={() => { setLeftPaneOpen(!leftPaneOpen) }}>
        {leftPaneOpen ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
      </StyledIconButton>
    </Tooltip>
  </Box>
}

export default SidePaneToggleButton
