import { ErrorCode, type FileRejection } from 'react-dropzone'
import { type Limits } from '../types'

/**
 * Get a human-readable message for a given error code.
 */
function codeToMessage (code: string, limits: Limits): string {
  switch (code) {
    case ErrorCode.FileInvalidType:
      return 'File type not supported'
    case ErrorCode.FileTooLarge:
      return `File too large (${limits.maxDocSizeInMb} MB max)`
    case ErrorCode.FileTooSmall:
      return 'File too small'
    case ErrorCode.TooManyFiles:
      return 'Too many files'
    default:
      return 'Unknown error'
  }
}

/**
 * Convert a list of file rejections from the react dropzone component
 * to a human-readable error message.
 */
function fileRejectionsToErrorMsg (
  fileRejections: FileRejection[],
  limits: Limits
): string {
  const tooManyFile = fileRejections.some((fileRejection) => {
    return fileRejection.errors.some((error) => {
      return error.code === ErrorCode.TooManyFiles
    })
  })

  const allOtherErrors = fileRejections.map((fileRejection) => {
    const errors: string[] = fileRejection.errors.filter((error) =>
      // Exclude the "too many files" error from the list, as it's already displayed separately
      error.code !== ErrorCode.TooManyFiles
    ).map((error) => {
      return codeToMessage(error.code, limits)
    })
    return {
      filename: fileRejection.file.name,
      errors
    }
  }).filter(({ errors }) =>
    errors.length > 0
  ).map(({ filename, errors }) =>
    `- ${filename}: ${errors.join(', ')}`
  ).join('\n')

  const msgs = []
  if (tooManyFile) {
    msgs.push(`Too many files: can only upload ${limits.uploadQuotaRemaining} more docs before reaching the maximum per session (${limits.maxNbDocs}).`)
  }
  if (allOtherErrors.length > 0) {
    msgs.push(`Some files are not supported:\n${allOtherErrors}`)
  }

  return msgs.join('\n\n')
}

export default fileRejectionsToErrorMsg
