import { Paper, Typography, styled } from '@mui/material'

export const InputDiffContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  border: '2px solid',
  borderColor: theme.palette.background.paper
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  userSelect: 'none', // Text not selectable, to avoid confusion
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}))
