import { useAuth0 } from '@auth0/auth0-react'
import ErrorModal from 'components/ErrorModal'
import ProgressBackdrop from 'components/ProgressBackdrop'
import CentralPane from 'components/central-pane/CentralPane'
import ChatLayout from 'components/central-pane/chat/ChatLayout'
import { Compose } from 'components/central-pane/compose/Compose'
import DrawerPane from 'components/drawer-pane/DrawerPane'
import { getAssistantId } from 'components/left-pane/AssistantSelect'
import { LeftPane } from 'components/left-pane/LeftPane'
import LeftPaneHeader from 'components/left-pane/LeftPaneHeader'
import ReviewModal from 'components/review-modal/ReviewModal/ReviewModal'
import { RightPane } from 'components/right-pane/RightPane'
import { useAuth, type User } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { localeToLanguageName } from 'context/IntlProviderWrapper'
import { useSession } from 'context/SessionContext'
import { UserSettingsProvider, useUserSettings } from 'context/UserSettingsContext'
import { anonymize, type AnonymizeResponse } from 'features/anonymization/api/anonymize'
import { mustReview } from 'features/anonymization/api/checkSensitive'
import { type Entity } from 'features/anonymization/types'
import { useAttachments } from 'features/documents/api/getAttachments'
import { type ConfidentialityLevel, type Globals } from 'features/globals/types'
import { getSessionMessages } from 'features/messages/api/getMessages'
import { sendAndFetchResponse } from 'features/messages/api/sendMessage'
import { updateMessage } from 'features/messages/api/updateMessage'
import { Role, type Message } from 'features/messages/types'
import { type SessionSettings } from 'features/sessions/types'
import { type UserInputForm } from 'features/userinputform/types'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'

const initAgentStatus = 'Waiting for input'

function HomePage (): JSX.Element {
  const { sessionId } = useParams<string>()
  const currentUser = useAuth() as User
  const { getAccessTokenSilently } = useAuth0()
  const windowWidth = useRef(window.innerWidth)

  const intl = useIntl()
  const userSettings = useUserSettings()
  const globals = useGlobals() as Globals
  const { selectedSession: session } = useSession()
  const { data: attachments } = useAttachments({
    withContent: true,
    autoRefetch: false
  })

  const [leftPaneOpen, setLeftPaneOpen] = React.useState<boolean>(windowWidth.current > 800)
  const [rightPaneOpen, setRightPaneOpen] = React.useState<boolean>(windowWidth.current > 1200)

  const [messages, setMessages] = React.useState<Message[]>([])
  const [agentStatus, setAgentStatus] = React.useState<string>(initAgentStatus)
  const [input, setInput] = React.useState<string>('')
  const [attachmentIds, setAttachmentIds] = React.useState<string[]>([])
  const [userInputForm, setUserInputForm] = React.useState<Partial<UserInputForm>>({})
  const [processingInput, setProcessingInput] = React.useState<boolean>(false)
  const [abortController, setAbortController] = React.useState<AbortController | null>(null)
  const [reviewModalOpen, setReviewModalOpen] = React.useState<boolean>(false)
  const [reviewModalInitResponse, setReviewModalInitResponse] = React.useState<AnonymizeResponse | undefined>(undefined)
  const [error, setError] = React.useState<boolean>(false)

  React.useEffect(() => {
    console.debug('> App [session, currentUser, globals]', session)

    if (session === null) {
      setMessages([])
      return
    }

    // Fetch messages for the session
    getAccessTokenSilently().then(async (token) =>
      await getSessionMessages(token, currentUser, session.id)
    ).then(({ history, lastUserInputForm }) => {
      setMessages(history)

      const uiUserLanguage = localeToLanguageName[intl.locale]

      // If there is a last user input form, use it as the initial state
      if (lastUserInputForm !== null) {
        // Remove some keys, textInput and attachments,
        // which we want to reassign ourselves
        const { textInput, attachments, ...newUserInputForm } = lastUserInputForm

        // If user language is not set or does match the one of the UI,
        // set it to the UI language
        if (newUserInputForm.userLanguage === undefined || newUserInputForm.userLanguage !== uiUserLanguage) {
          newUserInputForm.userLanguage = uiUserLanguage
        }

        setUserInputForm(newUserInputForm)
      } else {
        // Otherwise, create an empty initial state,
        // except for the user language, which is set to the UI language
        setUserInputForm({ userLanguage: uiUserLanguage })
      }
    }).catch((error) => {
      console.error(error)
    })
  }, [session, currentUser, globals])

  React.useEffect(() => {
    console.debug('> App [input]')
  }, [input])

  React.useEffect(() => {
    console.debug('> App [userSettings]')
  }, [userSettings])

  const existingEntities = (
    messages.length > 0
      ? messages[messages.length - 1].existingEntities ?? []
      : []
  )

  const onReviewSubmitted = (anonTexts: string[], attachmentIds: string[], existingEntities: Entity[]): void => {
    setReviewModalOpen(false)
    setProcessingInput(true)
    void (async function () {
      await sendMessage(input, anonTexts, attachmentIds, existingEntities, userInputForm)
      setInput('') // Clear the input
    })()
  }

  const submitWithoutAnonymizationConfirmation = intl.formatMessage({
    id: 'app.home-page.submit-without-anon-confirmation',
    defaultMessage: 'Submit input WITHOUT anonymization?'
  })

  const onReviewSkipped = (texts: string[]): void => {
    if (window.confirm(submitWithoutAnonymizationConfirmation)) {
      setReviewModalOpen(false)
      setProcessingInput(true)
      void (async function () {
        await sendMessage(input, texts, attachmentIds, existingEntities, userInputForm)
        setInput('') // Clear the input
      })()
    }
  }

  const onReviewCancelled = (prevInput: string): void => {
    setReviewModalOpen(false)
    setProcessingInput(false)
    setInput(prevInput) // Restore the input (was cleared upon submitting for review)
  }

  /**
   * Called when the user submits a message.
   */
  const onSubmitInput = (
    input: string,
    attachmentIds: string[],
    confidentialityLevel: ConfidentialityLevel,
    userInputFormKwargs: Partial<UserInputForm>
  ): void => {
    if (userSettings === null) throw new Error('User settings not set!')
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    setInput(input)
    setAttachmentIds(attachmentIds)
    setUserInputForm(userInputFormKwargs)

    setProcessingInput(true)

    void (async function () {
      const token = await getAccessTokenSilently()
      // Check if the input must be reviewed, or can be sent directly
      mustReview(token, input, attachmentIds, confidentialityLevel, userSettings.anonymizationSettings, currentUser).then(async (mustReview) => {
        if (mustReview) {
          // Run anonymization and open the review modal

          // Set up an abort controller, in case the user whats to
          // cancels the processing before it's done.
          const abortController = new AbortController()
          setAbortController(abortController)

          anonymize(
            token,
            input,
            attachmentIds,
            userSettings.anonymizationSettings,
            [], // No message-specific rules at first
            [],
            existingEntities,
            currentUser,
            abortController
          ).then((response) => {
            setReviewModalInitResponse(response)
            setReviewModalOpen(true)
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            setProcessingInput(false)
            setAbortController(null)
          })
        } else {
          // Send the cleartext input directly.

          // Must get the attachments text, if any.
          // FIXME: should rather have the backend
          // load the attachments text itself, rather than
          // doing this back and forth.
          const attachmentsTexts = (
            attachmentIds.length > 0
              ? await getAttachmentsTexts()
              : []
          )

          await sendMessage(
            input,
            [input, ...attachmentsTexts],
            attachmentIds,
            existingEntities,
            userInputFormKwargs
          )
          setInput('') // Clear the input
        }
      }).catch((error) => {
        console.error(error)
        // TODO pop up an error message
        setProcessingInput(false)
      })
    })()
  }

  const getAttachmentsTexts = async (): Promise<string[]> => {
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')
    if (attachments === undefined) {
      throw new Error('Attachments not loaded!')
    }

    const texts = attachments.uploaded.map((a) => a.text)
    // Make sure that none of them are null
    if (texts.some((t) => t === null)) {
      throw new Error('Some returned attachments do not have text!')
    }
    return texts as string[]
  }

  const sendMessage = async (
    input: string,
    anonTexts: string[],
    attachmentIds: string[],
    existingEntities: Entity[],
    userInputFormKwargs?: Partial<UserInputForm>
  ): Promise<void> => {
    if (userSettings === null) throw new Error('User settings not set!')
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')
    if (session === null) throw new Error('Session not set!')

    const assistantId = getAssistantId(session, globals)

    // First element of anonTexts is the anonymized user input
    const anonMsg = anonTexts[0]
    // The rest are the anonymized attachments' content
    const anonAttachmentContents = anonTexts.slice(1)

    const confidentialityLevel = globals.defaultLevel.level
    const sessionSettings: SessionSettings = {
      assistantId,
      confidentialityLevel
    }
    console.debug('sendMessage: sessionSettings=', sessionSettings)
    const params = {
      cleartextMsg: input,
      anonMsg,
      anonAttachmentContents,
      attachmentIds,
      existingEntities,
      sessionSettings,
      userInputFormKwargs
    }
    setAgentStatus('Processing')
    void getAccessTokenSilently().then(async (token) => {
      await sendAndFetchResponse(
        token,
        currentUser,
        sessionId,
        params,
        onStep,
        onDone,
        onError
      )
      setProcessingInput(false)
    })
  }

  const onStep = (step: string): void => {
    console.debug('step :', step)
    setAgentStatus(step)
  }

  const onDone = (userMsg: Message, assistantMsg: Message): void => {
    console.debug('done: userMsg=', userMsg)
    console.debug('done: assistantMsg=', assistantMsg)
    setMessages([...messages, userMsg, assistantMsg])
    setAgentStatus('Done')
  }

  const onError = (): void => {
    setError(true)
  }

  const handleCorrectionSubmitted = (sessionId: string, idx: number, correction: string): void => {
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    void (async function () {
      const token = await getAccessTokenSilently()
      await updateMessage(token, currentUser, sessionId, idx, { correction })
    })()

    // Update the local state as well
    const newMessages = [...messages]
    newMessages[idx].correction = correction
    setMessages(newMessages)
  }

  const handleFeedbackSubmitted = (sessionId: string, idx: number, feedback: number): void => {
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    void (async function () {
      const token = await getAccessTokenSilently()
      await updateMessage(token, currentUser, sessionId, idx, { feedback })
    })()

    // Update the local state as well
    const newMessages = [...messages]
    newMessages[idx].feedback = feedback
    setMessages(newMessages)
  }

  const getLastAssistantMessage = (messages: Message[]): Message | undefined => {
    if (messages.length === 0) return undefined
    for (let i = messages.length - 1; i >= 0; i--) {
      const msg = messages[i]
      if (msg.role === Role.ASSISTANT) return msg
    }
    return undefined
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (eventData.initial[0] < windowWidth.current / 2 || rightPaneOpen) {
        setLeftPaneOpen(false)
      } else {
        setRightPaneOpen(true)
      }
    },
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > windowWidth.current / 2 || leftPaneOpen) {
        setRightPaneOpen(false)
      } else {
        setLeftPaneOpen(true)
      }
    }
  })

  return (
    <div className="App">
      <UserSettingsProvider>
        <ProgressBackdrop
          open={processingInput && !reviewModalOpen}
          // FIXME: Ugly guessing whether we are analyzing the input
          // for anonymization (no msg), or running the assistant (show msg).
          msg={agentStatus !== initAgentStatus && agentStatus !== 'Done' ? agentStatus : undefined}
          onCancel={
            abortController !== null ? () => { abortController.abort() } : undefined
          }
        />

        <ErrorModal open={error} onClose={() => { setError(false) }} />

        {/* Left pane */}
        <DrawerPane
          initOpen={leftPaneOpen}
          onChange={setLeftPaneOpen}
          anchor={'left'}
          headerChildren={<LeftPaneHeader />}
        >
          <LeftPane
            onClose={() => { setLeftPaneOpen(false) }}
          />
        </DrawerPane>

        {/* Right pane */}
        <DrawerPane
          initOpen={rightPaneOpen}
          onChange={setRightPaneOpen}
          anchor={'right'}
        >
          <RightPane
            lastAssistantMessage={getLastAssistantMessage(messages)}
            agentStatus={agentStatus}
            onClose={() => { setRightPaneOpen(false) }}
          />
        </DrawerPane>

        {/* Central pane */}
        <CentralPane leftPaneOpen={leftPaneOpen} rightPaneOpen={rightPaneOpen} swipeHandlers={swipeHandlers}>
          {session !== null && (
            <ChatLayout
              session={session}
              messages={messages}
              onCorrectionSubmitted={(idx, correction) => { handleCorrectionSubmitted(session.id, idx, correction) }}
              onFeedbackSubmitted={(idx, feedback) => { handleFeedbackSubmitted(session.id, idx, feedback) }}/>
          )}
          {sessionId != null && (
            <Compose
              initInput={input}
              initUserInputForm={userInputForm}
              processing={processingInput}
              onSubmit={onSubmitInput}
            />
          )}
        </CentralPane>

        {reviewModalInitResponse !== undefined && <ReviewModal
          open={reviewModalOpen}
          input={input}
          attachmentIds={attachmentIds}
          initExistingEntities={existingEntities}
          initResponse={reviewModalInitResponse}
          onClose={onReviewCancelled}
          onSkip={onReviewSkipped}
          onSubmit={onReviewSubmitted}
        />}
      </UserSettingsProvider>
    </div>
  )
}

export default HomePage
