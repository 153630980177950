import AddIcon from '@mui/icons-material/Add'
import {
  Button,
  FormControl,
  InputLabel,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { type EntityType } from 'features/anonymization/types'
import EntityTypeSelector from '../selectors/EntityTypeSelector'
import { ReviewTableCell } from './ReviewStateItemRow.styles'
import { useIntl } from 'react-intl'

interface AddEntityFormProps {
  scope: 'global' | 'msg-specific'
  existingEntities: string[]
  onAddAnonRule: (cleartext: string, entityType: EntityType, asAliasOf: string | undefined) => void
}

export const AddEntityForm: React.FC<AddEntityFormProps> = ({
  scope,
  existingEntities,
  onAddAnonRule
}: AddEntityFormProps) => {
  const intl = useIntl()

  const [open, setOpen] = useState<boolean>(false)
  const [entity, setEntity] = useState<string>('')
  const [category, setCategory] = useState<string>('')

  const handleAddEntity = (): void => {
    console.log(`Adding a rule to anonymize "${entity}" as a ${category}`)
    onAddAnonRule(entity, category, undefined)
    // Reset our inputs
    setEntity('')
    setCategory('')
  }

  const disabled = entity === '' || existingEntities.includes(entity) || category === ''

  const entityTextfieldPlaceholder = intl.formatMessage({
    id: 'app.add-entity-form.entity-textfield.placeholder',
    defaultMessage: 'Entity'
  })

  const replacementSelectLabel = intl.formatMessage({
    id: 'app.add-entity-form.replacement-select.label',
    defaultMessage: 'Replacement'
  })

  return (
    <TableRow>
      <ReviewTableCell component="th" scope="row">
        <TextField
          id='entity-textfield'
          placeholder={entityTextfieldPlaceholder}
          variant="outlined"
          value={entity}
          error={existingEntities.includes(entity)}
          onChange={(event) => { setEntity(event.target.value) }}
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
      </ReviewTableCell>

      <ReviewTableCell>
        <FormControl sx={{ display: 'flex' }}>
          <InputLabel id='replacement-select-label'>{replacementSelectLabel}</InputLabel>
          <EntityTypeSelector
            label={replacementSelectLabel}
            value={category}
            onSelectEntityCategory={(category) => {
              setCategory(category)
              setOpen(false)
            }}
            open={open}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
          />
        </FormControl>
      </ReviewTableCell>

      <ReviewTableCell>
        {disabled
          ? (
            <Typography align='center'>
              {
                scope === 'global'
                  ? intl.formatMessage({
                    id: 'app.add-entity-form.global-rule',
                    defaultMessage: 'Global rule'
                  })
                  : intl.formatMessage({
                    id: 'app.add-entity-form.active',
                    defaultMessage: 'Active'
                  })
              }
            </Typography>
            )
          : (
            <Button
              variant="contained"
              disabled={disabled}
              onClick={handleAddEntity}
              startIcon={<AddIcon />}
              fullWidth
            >
              {
                intl.formatMessage({
                  id: 'app.add-entity-form.add-button.label',
                  defaultMessage: 'Add'
                })
              }
            </Button>
            )
        }
      </ReviewTableCell>
    </TableRow>
  )
}

export default AddEntityForm
