import { DRAWER_WIDTH } from '../drawer-pane/DrawerPaneButton'
import { type SwipeableHandlers } from 'react-swipeable'
import { Box } from '@mui/material'

interface Props {
  leftPaneOpen: boolean
  rightPaneOpen: boolean
  swipeHandlers: SwipeableHandlers
  children: React.ReactNode
}

const CentralPane: React.FC<Props> = ({
  leftPaneOpen, rightPaneOpen, swipeHandlers, children
}: Props) => {
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      marginLeft: (leftPaneOpen ? `${DRAWER_WIDTH}px` : { xs: 0, sm: 4, md: 8 }),
      marginRight: (rightPaneOpen ? `${DRAWER_WIDTH}px` : { xs: 0, sm: 4, md: 8 }),
      // border: 1,
      // borderColor: 'red',
      ...((leftPaneOpen || rightPaneOpen) && { display: { xs: 'none', sm: 'flex' } })
    }}
    {...swipeHandlers}
  >
    {children}
  </Box>
}

export default CentralPane
