import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Fade,
  Typography,
  IconButton,
  Modal,
  TextField
} from '@mui/material'
import React, { useEffect } from 'react'
import CancelButton from '../../buttons/CancelButton'
import { ModalContainer, ModalHeader } from '../../../theme'
import { FormButtonsContainer, StyledFormControl } from './AddAliasModal.styles'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { useIntl } from 'react-intl'

interface AddAliasModalProps {
  open: boolean
  existingCleartexts: string[]
  onClose: () => void
  onSubmit: (aliases: string[]) => void
}

export const AddAliasModal: React.FC<AddAliasModalProps> = (
  { open, existingCleartexts, onClose, onSubmit }: AddAliasModalProps
) => {
  const intl = useIntl()

  const defaultAliases = ['']
  const [aliases, setAliases] = React.useState([...defaultAliases])

  useEffect(() => {
    console.debug('> AddAliasModal [open]')
    setAliases([...defaultAliases])
  }, [open])

  const canSubmit = (): boolean => {
    return (
      aliases.some((alias) => alias !== '') &&
      !aliases.some((alias) => matchesExistingCleartext(alias))
    )
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    // Prevent the parent ReviewModal from closing as well
    event.stopPropagation()
    event.preventDefault()
    // Keep only non-empty aliases
    const nonEmptyAliases = aliases.filter((alias) => alias !== '')
    onSubmit(nonEmptyAliases)
  }

  const matchesExistingCleartext = (alias: string): boolean => {
    return existingCleartexts.some((cleartext) => cleartext === alias)
  }

  const editForm = (
    <Box>
      <form onSubmit={handleSubmit}>
        <StyledFormControl>
          {/* Text fields to enter aliases */}
          {aliases.map((alias, idx) => (
            <TextField
              key={idx}
              id={`add-alias-modal-input-${idx}`}
              autoFocus
              error={matchesExistingCleartext(aliases[idx])}
              variant="outlined"
              value={aliases[idx]}
              onKeyDown={e => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              onChange={(event) => {
                const newAliases = [...aliases]
                newAliases[idx] = event.target.value
                setAliases(newAliases)
              }}
            />
          ))}
        </StyledFormControl>

        {/* Buttons */}
        <FormButtonsContainer>
          <CancelButton onClick={onClose} />
          <Button
            type="submit"
            variant="contained"
            startIcon={<AddIcon />}
            disabled={!canSubmit()}
          >
            {
              intl.formatMessage({
                id: 'app.add-alias-modal.submit-button.label',
                defaultMessage: 'Add'
              })
            }
          </Button>
        </FormButtonsContainer>
      </form>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-alias-modal-title"
      aria-describedby="add-alias-modal-description"
    >
      <Fade in={open}>
        <ModalContainer>
          <ModalHeader>
            <Typography id="add-alias-modal-title" variant="h4">
              {
                intl.formatMessage({
                  id: 'app.add-alias-modal.title',
                  defaultMessage: 'Add variants'
                })
              }
            </Typography>
            <CloseModalButton onClick={onClose} />
          </ModalHeader>
          {editForm}
        </ModalContainer>
      </Fade>
    </Modal>
  )
}

export default AddAliasModal
