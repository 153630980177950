import { createAuthHeaderFromToken, axiosInstance } from 'services/axios'
import { type AttachmentsAndUploads } from '../types'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { queryClient } from 'services/react-query'
import { useSession } from 'context/SessionContext'
import { type AxiosError } from 'axios'

interface RetryUploadAttachmentVariables {
  taskId: string
}

/**
 * A mutation hook to retry uploading an attachment to the current session.
 */
export function useRetryUploadAttachment (): UseMutationResult<PostRetryAttachmentsResponse, Error, RetryUploadAttachmentVariables> {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()

  if (selectedSession === null) {
    throw new Error('No session selected')
  }

  const mutationFn = async ({ taskId }: RetryUploadAttachmentVariables): Promise<PostRetryAttachmentsResponse> => {
    return await getAccessTokenSilently().then(async (token) =>
      await retryUpload(token, taskId, selectedSession.id)
    )
  }

  const queryKey = ['sessions', selectedSession.id, 'attachments']

  return useMutation({
    mutationKey: ['retryUploadAttachment', selectedSession.id],
    mutationFn,
    onMutate: async ({ taskId }) => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
        exact: true
      })

      // Snapshot the previous value
      const attachments = queryClient.getQueryData(queryKey) as AttachmentsAndUploads

      // Optimistically update to the new value
      const newPendingAttachments = attachments.pending.map((attachment) => {
        if (attachment.taskId === taskId) {
          return {
            taskId: undefined,
            taskUri: undefined,
            filename: attachment.filename,
            status: 'PENDING' // Reset the status to PENDING
          }
        }
        return attachment
      })
      const updatedAttachments = {
        uploaded: attachments.uploaded,
        pending: newPendingAttachments
      }
      queryClient.setQueryData(queryKey, updatedAttachments)

      // Return a context object with the snapshot value
      return { previousAttachments: attachments }
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error('Upload failed: ', error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(queryKey, context.previousAttachments)
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries({
        queryKey,
        exact: true
      })
    }
  })
}

interface PostRetryAttachmentsResponse {
  taskId: string
  taskUri: string
}

interface PostAttachmentsErrorResponse {
  detail: string
}

const retryUpload = async (
  token: string,
  taskId: string,
  sessionId: string
): Promise<PostRetryAttachmentsResponse> => {
  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.post<PostRetryAttachmentsResponse>(
    `/sessions/${sessionId}/uploads/${taskId}/retry`,
    {},
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  ).catch((error: AxiosError) => {
    // Extract the message from the error response to make it more user-friendly
    const errorResponse = error.response?.data as PostAttachmentsErrorResponse | undefined
    throw new Error(`Failed to upload attachment: ${errorResponse?.detail ?? error.message}`)
  })
  return response.data
}
