import { Box, FormControl, TextField, styled } from '@mui/material'

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const FormButtonsContainer = styled(Box)(({ theme }) => ({
  flex: 0,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(1)
}))

export const StyledTextField = styled(TextField)(() => ({
  flexBasis: '100%'
}))
