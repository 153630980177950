import { Divider, Paper } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Role, type Message } from 'features/messages/types'
import UserAvatar from '../../UserAvatar'
import { CopyToClipboardButton } from '../../buttons/CopyToClipboardButton'
import { ThumbsDownButton, ThumbsUpButton } from '../../buttons/ThumbsButtons'
import CorrectionModal from './CorrectionModal'
import UserInputFormDigest from './UserInputFormDigest'
import { AvatarContainer, BottomSection, FeedbackButtons, MessageContentContainer, Spacer, TopSection, containerSx } from './MessageBox.styles'
import AIAvatar from './AIAvatar'
import ToggleRawButton from './ToggleRawButton'
import MessageText from './MessageText'

interface MessageBoxProps {
  msg: Message
  disableButtons?: boolean
  onFeedbackSubmitted?: (feedback: number) => void
  onCorrectionSubmitted?: (correction: string) => void
}

export const MessageBox: React.FC<MessageBoxProps> = ({
  msg, disableButtons, onFeedbackSubmitted, onCorrectionSubmitted
}: MessageBoxProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [showRaw, setShowRaw] = useState<boolean>(msg.role === 'user')
  const [feedback, setFeedback] = useState<number>(msg.feedback ?? 0)

  useEffect(() => {
    if (ref.current != null) {
      ref.current.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }, [])

  useEffect(() => {
    setFeedback(msg.feedback ?? 0)
  }, [msg])

  const handleCopyToClipboard = (): void => {
    void (async function () {
      await navigator.clipboard.writeText(msg.cleartextContent)
    })()
  }

  const handleThumbsUp = (): void => {
    if (onFeedbackSubmitted === undefined) throw new Error('onFeedbackSubmitted is undefined')
    const newFeedback = feedback === 1 ? 0 : 1
    // Update local state for immediate feedback
    setFeedback(newFeedback)
    // Callback for API call
    onFeedbackSubmitted(newFeedback)
  }

  const handleThumbsDown = (): void => {
    if (onFeedbackSubmitted === undefined) throw new Error('onFeedbackSubmitted is undefined')
    const newFeedback = feedback === -1 ? 0 : -1
    // Update local state for immediate feedback
    setFeedback(newFeedback)
    // Callback for API call
    onFeedbackSubmitted(newFeedback)
  }

  const handleCorrectionSubmit = (correction: string): void => {
    if (onCorrectionSubmitted === undefined) throw new Error('onCorrectionSubmitted is undefined')
    onCorrectionSubmitted(correction)
  }

  const content = msg.cleartextContent.trim()

  return (
    <Paper ref={ref} sx={containerSx(msg)}>
      <AvatarContainer>
        {msg.role === Role.USER ? <UserAvatar /> : <AIAvatar settings={msg.settings} />}
      </AvatarContainer>

      {/* Message content (text input and user input form, if any) */}
      <MessageContentContainer>
        {
          msg.role === Role.USER && msg.state?.userInputForm !== undefined && (
            <TopSection>
              <UserInputFormDigest userInputForm={msg.state?.userInputForm} />
              <Divider />
            </TopSection>
          )
        }

        <MessageText
          content={content}
          showRaw={showRaw}
        />

        {
          msg.role === 'assistant' && disableButtons !== true && (
            <BottomSection>
              <Divider />
              <FeedbackButtons>
                <ThumbsUpButton feedback={feedback} onClick={handleThumbsUp} />
                <ThumbsDownButton feedback={feedback} onClick={handleThumbsDown} />
                { onCorrectionSubmitted !== undefined && content.length > 0 && (
                  <CorrectionModal
                    initCorrection={msg.correction ?? msg.cleartextContent}
                    previouslyEdited={msg.correction !== undefined && msg.correction !== msg.cleartextContent}
                    onSubmit={handleCorrectionSubmit}
                  />
                )}
                <Spacer />
                <ToggleRawButton showRaw={showRaw} setShowRaw={setShowRaw} />
                <CopyToClipboardButton onClick={handleCopyToClipboard} />
              </FeedbackButtons>
            </BottomSection>
          )
        }

      </MessageContentContainer>
    </Paper>
  )
}
