import { Stack } from '@mui/material'
import type React from 'react'
import AttachmentChip from './AttachmentChip'
import { type AttachmentsAndUploads } from '../types'
import UploadInProgress from './UploadInProgress'

interface Props {
  attachments: AttachmentsAndUploads
  deletable?: boolean
}

const AttachmentsStack: React.FC<Props> = (
  { attachments, deletable }: Props
) => {
  const uploadInProgress = attachments.pending.some((a) =>
    a.status !== 'SUCCESS' && a.status !== 'FAILURE'
  )

  return <Stack direction='row' spacing={1}>
    {attachments.uploaded.map((a) => (
      <AttachmentChip
        key={a.id}
        attachment={a}
        deletable={deletable}
      />
    ))}
    {uploadInProgress && <UploadInProgress />}
  </Stack>
}

export default AttachmentsStack
