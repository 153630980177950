import AddIcon from '@mui/icons-material/Add'
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  type SxProps,
  type Theme,
  Typography
} from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { type EntityType } from 'features/anonymization/types'
import { AddAliasIcon } from '../AliasIcons'
import EntitySelectMenuItem from './EntitySelectMenuItem'
import { useState } from 'react'
import { useIntl } from 'react-intl'

const defaultOpenDirection = 'right'

interface Props {
  open: boolean
  onOpen?: () => void
  onClose?: () => void
  value: string
  openDirection?: 'left' | 'right'
  disabled?: boolean
  cleartext: string
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  onAddExactMatch?: (entityType: EntityType, asAliasOf?: string) => void
  sx?: SxProps<Theme>
}

/**
 * Component to select an entity type, or existing entity
 * for which to create an alias of.
 */
const EntityTypeOrAliasSelector: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  value,
  openDirection,
  disabled,
  cleartext,
  entityTypeToActiveEntitiesCleartext,
  onAddExactMatch,
  sx
}: Props) => {
  const intl = useIntl()
  const [openedLevel1MenuIdx, setOpenedLevel1MenuIdx] = useState<number | null>(null)
  const [openedLevel2MenuIdx, setOpenedLevel2MenuIdx] = useState<number | null>(null)

  const globals = useGlobals()
  if (globals === null) return <></>

  const handleSelect = (entityType: EntityType, asAliasOf?: string): void => {
    if (onAddExactMatch === undefined) return

    // Will create an anonymization rule for the selected text,
    // as well as an alias rule for the selected entity's cleartext
    onAddExactMatch(entityType, asAliasOf)

    if (onClose !== undefined) onClose()
  }

  const listAliasOptions = (entityType: EntityType): JSX.Element[] => {
    const aliasCleartexts: string[] = (entityTypeToActiveEntitiesCleartext.get(entityType) ?? [])
      .filter((aliasCleartext) => aliasCleartext !== cleartext)

    if (aliasCleartexts.length === 0) return []

    return [
      <Divider key={1} />,
      <MenuItem
        key={2}
        data-value={''}
        onClick={() => {} }
        disabled
      >
        {
          intl.formatMessage({
            id: 'app.add-entity-type-select.alias-of',
            defaultMessage: 'Alias of'
          })
        }
      </MenuItem>,
      ...aliasCleartexts.map((aliasCleartext, idx3) => (
        <MenuItem
          key={idx3 + 3}
          onClick={() => {
            handleSelect(entityType, aliasCleartext)
          }}
          sx={{ gap: 1 }}
        >
          <AddAliasIcon />
          {`${aliasCleartext}`}
        </MenuItem>
      ))
    ]
  }

  return (
    <FormControl className="entity-type-alias-selector" sx={{ display: 'flex' }}>
      <Select
        id="add-entity-type-select"
        labelId='add-entity-type-select-label'
        value={value}
        renderValue={() => <Typography>{value}</Typography>}
        open={open}
        onOpen={onOpen ?? (() => {})}
        onClose={onClose ?? (() => {})}
        sx={sx ?? {}}
        disabled={disabled ?? false}
        autoWidth
      >
        {[
          // Dummy menu item to avoid a MUI warning about value not being a selectable option
          <MenuItem key={0} value={value} sx={{ display: 'none' }} />,

          ...Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => (
            // First level of menu is entity groups.
            // Make a sub-menu for each or them,
            // except for those that we want to "flatten".
            (globals.flattenedGroups.includes(group)
              ? entityTypeInfos.map((entityTypeInfo, idx2) => (
                <MenuItem
                  key={(idx + 1) * 100 + idx2}
                  data-value={'menu-item'}
                  onClick={() => {
                    handleSelect(entityTypeInfo.entityType, undefined)
                  } }
                  onMouseEnter={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                  sx={{ gap: 1 }}
                >
                  <AddIcon />
                  {
                    intl.formatMessage({
                      id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                      defaultMessage: entityTypeInfo.prettyName
                    })
                  }
                </MenuItem>
              ))
              : [
                <EntitySelectMenuItem
                  key={(idx + 1) * 100}
                  label={
                    intl.formatMessage({
                      id: `app.entity-group.${group}`,
                      defaultMessage: group
                    })
                  }
                  openDirection={openDirection ?? defaultOpenDirection}
                  open={openedLevel1MenuIdx === idx}
                  onOpen={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                >
                  {entityTypeInfos.map((entityTypeInfo, idx2) => (
                    // Second level of menu is entity types.
                    // If aliases are not enabled for this entity type,
                    // then clicking on the entry should select it.
                    !entityTypeInfo.aliasesEnabled
                      ? (
                          <MenuItem
                            key={(idx + 1) * 100 + idx2}
                            data-value={'menu-item'}
                            onClick={() => {
                              handleSelect(entityTypeInfo.entityType, undefined)
                            } }
                            sx={{ gap: 1 }}
                          >
                            <AddIcon />
                            {
                              intl.formatMessage({
                                id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                                defaultMessage: entityTypeInfo.prettyName
                              })
                            }
                          </MenuItem>
                        )
                      : (
                        // Otherwise, make it a sub-menu
                        <EntitySelectMenuItem
                          key={(idx + 1) * 100 + idx2}
                          label={
                            intl.formatMessage({
                              id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                              defaultMessage: entityTypeInfo.prettyName
                            })
                          }
                          openDirection={openDirection ?? defaultOpenDirection}
                          open={openedLevel2MenuIdx === idx2}
                          onOpen={() => { setOpenedLevel2MenuIdx(idx2) }}
                        >
                          {/* Either add as a new entity... */}
                          <MenuItem
                            key={0}
                            data-value={'sub-menu-item'}
                            onClick={() => {
                              handleSelect(entityTypeInfo.entityType, undefined)
                            } }
                            sx={{ gap: 1 }}
                          >
                            <AddIcon />
                            {
                              intl.formatMessage({
                                id: 'app.add-entity-type-select.add-new-entity',
                                defaultMessage: 'New'
                              })
                            }
                          </MenuItem>

                          {/* ...or as an alias of an existing one */}
                          {entityTypeInfo.aliasesEnabled && listAliasOptions(entityTypeInfo.entityType)}
                        </EntitySelectMenuItem>
                        )
                  ))}
                </EntitySelectMenuItem>
                ]
            )
          )).flat()
        ]}
      </Select>
    </FormControl>
  )
}

export default EntityTypeOrAliasSelector
