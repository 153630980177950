import { styled } from '@mui/material'
import { ModalContainer } from '../theme'

export const WelcomeModalContainer = styled(ModalContainer)(({ theme }) => ({
  width: '400px', // Fixed width to match the child FirebaseUI widget
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4)
}))
