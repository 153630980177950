export const containerSx = {
  display: 'flex',
  flexDirection: 'row',
  gap: { xs: 0.5, sm: 1 },
  mb: 1,
  minHeight: '50px',
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  // Hide scrollbar
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  // Make sure that children components are not too small
  '& > *': {
    minWidth: '120px'
  }
}
