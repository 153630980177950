import { createAuthHeaderFromToken, axiosInstance } from 'services/axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { queryClient } from 'services/react-query'
import { useSession } from 'context/SessionContext'
import { type AttachmentsAndUploads } from '../types'

interface CancelUploadProps {
  /**
   * The URI of the task to cancel.
   * May be null if the task is already over,
   * or undefined for optimistic updates.
   */
  taskUri: string | null | undefined
}
/**
 * A mutation hook to upload an attachment to the current session.
 */
export function useCancelUpload ({ taskUri }: CancelUploadProps): UseMutationResult<CancelUploadResponse, Error> {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()

  if (selectedSession === null) {
    throw new Error('No session selected')
  }

  const mutationFn = async (): Promise<CancelUploadResponse> => {
    if (taskUri === null || taskUri === undefined) {
      throw new Error('No task URI provided')
    }
    return await getAccessTokenSilently().then(async (token) =>
      await cancelUpload(token, taskUri)
    )
  }

  const queryKey = ['sessions', selectedSession.id, 'attachments']

  return useMutation({
    mutationKey: ['cancelUpload', taskUri],
    mutationFn,
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey, exact: true })

      // Snapshot the previous value
      const attachments = queryClient.getQueryData(queryKey) as AttachmentsAndUploads

      // Optimistically update to the new value
      const updatedAttachments = {
        uploaded: attachments.uploaded,
        pending: attachments.pending.filter((a) => a.taskUri !== taskUri)
      }
      queryClient.setQueryData(queryKey, updatedAttachments)

      // Return a context object with the snapshot value
      return { previousAttachments: attachments }
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error(`Cancel upload ${taskUri ?? 'null'} failed`, error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(queryKey, context.previousAttachments)
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries({
        queryKey,
        exact: true
      })
    }
  })
}

interface CancelUploadResponse {
  taskId: string
}

const cancelUpload = async (
  token: string,
  taskUri: string
): Promise<CancelUploadResponse> => {
  if (taskUri === undefined) {
    throw new Error('No task URI provided')
  }

  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.delete<CancelUploadResponse>(
    taskUri,
    {
      headers: authHeader,
      cache: false
    }
  )
  return response.data
}
