import { type Entity } from 'features/anonymization/types'
import { type AssistantSettings } from 'features/assistants/types'
import { type UserInputForm } from 'features/userinputform/types'

export interface SessionHistory {
  history: Message[]
  lastUserInputForm: UserInputForm | null
} export enum Role {
  ASSISTANT = 'assistant',
  USER = 'user'
}

export interface MasterAgentState {
  userInputForm?: UserInputForm
  reformulatedRequest?: string
}

export interface Message {
  idx?: number
  role: Role
  cleartextContent: string
  anonContent: string
  existingEntities?: Entity[]
  feedback?: number
  correction?: string
  settings?: AssistantSettings
  state?: MasterAgentState
}
