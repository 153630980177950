import { useGlobals } from 'context/GlobalsContext'
import { type Globals } from 'features/globals'
import { DOC_TYPE_NOT_CERTAIN, type DocType } from '../types'

export const useSelectableDocTypes = (): DocType[] => {
  const { docTypes } = useGlobals() as Globals
  return Array.from(docTypes.keys()).filter((key) =>
    key !== DOC_TYPE_NOT_CERTAIN
  )
}
