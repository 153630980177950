import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'

export const generateDocxOutput = async (token: string, sessionId: string, messageId: number): Promise<Blob> => {
  const authHeader = createAuthHeaderFromToken(token)
  return await axiosInstance.get(
    `/sessions/${sessionId}/messages/${messageId}/export?format=docx`,
    {
      headers: authHeader,
      cache: false,
      responseType: 'blob'
    }
  ).then(({ data }) => {
    return new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    })
  })
}
