import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'components/Loading'
import { useEffect } from 'react'

const SignUpPage = (): JSX.Element => {
  const {
    loginWithRedirect
  } = useAuth0()

  useEffect(() => {
    void loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup'
      }
    })
  }, [])

  return <Loading />
}

export default SignUpPage
