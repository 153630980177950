import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { ListItem, ListItemText, Tooltip } from '@mui/material'
import type React from 'react'
import { useEffect, useRef } from 'react'
import RenameModal from 'components/left-pane/RenameModal'
import { type Session } from '../types'
import { ButtonsContainer, listItemSx } from './SessionItem.styles'
import DeleteSessionButton from './DeleteSession'
import { useUpdateSession } from '../api/updateSession'
import { useIntl } from 'react-intl'

interface Props {
  session: Session
  selected: boolean
  onClick: () => void
}

const SessionItem: React.FC<Props> = ({
  session,
  selected,
  onClick
}: Props) => {
  const intl = useIntl()

  const ref = useRef<HTMLLIElement>(null)

  const { mutate } = useUpdateSession()

  useEffect(() => {
    if (ref.current != null && selected) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selected])

  const handleSessionRenamed = (newTitle: string): void => {
    if (session === undefined || session === null) {
      throw new Error('No session selected!')
    }

    mutate({ sessionId: session.id, update: { title: newTitle } })
  }

  /**
   * "2023-09-01T16:50:52.000Z" -> "09/01/2023 4:50:52 PM"
   */
  const renderCreatedAt = (createdAt: string | undefined): string => {
    if (createdAt === undefined) return ''
    const date = new Date(createdAt)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  // Needed to truncate long session titles
  const typographyProps = {
    style: {
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }

  const tooltip = intl.formatMessage({
    id: 'app.session-item.tooltip',
    defaultMessage: 'Click to select session'
  })

  return (
    <ListItem onClick={onClick} sx={listItemSx(selected)} ref={ref}>
      <ChatBubbleIcon />

      {/* Session title and creation time */}
      <Tooltip title={tooltip}>
        <ListItemText
          primary={session.title}
          primaryTypographyProps={typographyProps}
          secondary={renderCreatedAt(session.createdAt)}
          secondaryTypographyProps={typographyProps}
        />
      </Tooltip>

      {/* Edit and delete buttons */}
      {selected && (
        <ButtonsContainer>
          <RenameModal initTitle={session.title} onRenamed={handleSessionRenamed} />
          <DeleteSessionButton sessionId={session.id} />
        </ButtonsContainer>
      )}
    </ListItem>
  )
}

export default SessionItem
