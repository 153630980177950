import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type AnonRule, type RuleKey, type AnonymizationSettings, type AliasRule } from '../types'

export const applyAnonRulesToAnonymizationSettings = async (
  token: string,
  user: User,
  rulesToAdd: AnonRule[],
  keysToDelete: RuleKey[]
): Promise<AnonymizationSettings> => {
  return await axiosInstance.post<AnonymizationSettings>(
    '/users/me/settings/anonymization-settings/anonymization-rules',
    { rulesToAdd, keysToDelete },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        update: {
          'get-user-settings': 'delete'
        },
        ttl: defaultTTL
      }
    }
  ).then((response) => {
    return response.data
  })
}

export const applyAliasRulesToAnonymizationSettings = async (
  token: string,
  user: User,
  rulesToAdd: AliasRule[],
  keysToDelete: RuleKey[]
): Promise<AnonymizationSettings> => {
  return await axiosInstance.post<AnonymizationSettings>(
    '/users/me/settings/anonymization-settings/alias-rules',
    { rulesToAdd, keysToDelete },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        update: {
          'get-user-settings': 'delete'
        },
        ttl: defaultTTL
      }
    }
  ).then((response) => {
    return response.data
  })
}
