import { Box, Typography } from '@mui/material'
import { type AttachmentListItem } from 'features/documents/types'
import { contractIsAttached } from 'features/documents/utils/contractIsAttached'
import { AnalysisTaskAction, type TranslateTaskParams, type AnalysisTaskParams, type UserInputForm } from 'features/userinputform/types'
import AnalysisTaskActionSelect from './AnalysisTaskActionSelect'
import SanityCheckOptionsSelect from './SanityCheckOptionsSelect'
import SpecificAnalysisOptionsSelect from './SpecificAnalysisOptionsSelect'
import TaskButtons from './TaskButtons'
import TaskSelect from './TaskSelect'
import { containerSx } from './UserPromptButtons.styles'
import TranslateLanguageSelect from './TranslateLanguageSelect'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
  uploadedAttachments: AttachmentListItem[]
}

const UserPromptButtons: React.FC<Props> = (
  { userInputForm, onChange, uploadedAttachments }: Props
) => {
  const intl = useIntl()

  // Construct a list of components to render,
  // based on the current state of the user input form.
  const children: JSX.Element[] = []

  if (userInputForm.mainTask === undefined || userInputForm.mainTask === null) {
    // If no task has been chosen yet,
    // represented each task to choose from as a button.
    const taskButtonsComponent = (
      <TaskButtons key={0} userInputForm={userInputForm} onChange={onChange} />
    )
    children.push(taskButtonsComponent)
  } else {
    // If task has already been selected, show it
    // as selected from a dropdown menu.
    const taskSelectComponent = (
      <TaskSelect
        key={0}
        userInputForm={userInputForm}
        onChange={onChange}
      />
    )
    children.push(taskSelectComponent)

    // Add task-specific components

    // Analyze task
    if (userInputForm.mainTask === 'Analyze') {
      // If attachments have been uploaded, show analysis action dropdown menu.
      if (uploadedAttachments.length > 0) {
        const analysisActionSelectComponent = (
          <AnalysisTaskActionSelect
            key={1}
            userInputForm={userInputForm}
            contractIsAttached={contractIsAttached(uploadedAttachments)}
            onChange={onChange}
          />
        )
        children.push(analysisActionSelectComponent)

        const taskParams = userInputForm.taskParams as AnalysisTaskParams

        if (taskParams?.action === AnalysisTaskAction.SanityCheck) {
          // List of sanity check options to choose from
          const sanityCheckOptionsComponent = (
            <SanityCheckOptionsSelect
              key={2}
              userInputForm={userInputForm}
              onChange={onChange}
            />
          )
          children.push(sanityCheckOptionsComponent)
        } else if (taskParams?.action === AnalysisTaskAction.SpecificAnalysis) {
          // List of sanity check options to choose from
          const specificAnalysisOptionsComponent = (
            <SpecificAnalysisOptionsSelect
              key={2}
              userInputForm={userInputForm}
              onChange={onChange}
            />
          )
          children.push(specificAnalysisOptionsComponent)
        }
      }
    } else if (userInputForm.mainTask === 'Translate') {
      // If the task is Translate but the params have not been set yet,
      // call the onChange function to set the default source and target languages.
      const taskParams = userInputForm.taskParams as TranslateTaskParams | undefined
      if (
        taskParams === undefined ||
        taskParams.sourceLang === undefined ||
        taskParams.sourceLang === null ||
        taskParams.targetLang === undefined ||
        taskParams.targetLang === null
      ) {
        onChange({
          ...userInputForm,
          taskParams: {
            sourceLang: 'all',
            targetLang: 'en'
          }
        })
      }

      children.push(
        <Typography key={1} sx={{ alignContent: 'center', minWidth: '0px' }}>
          {
            intl.formatMessage({
              id: 'app.translate-task-language-select.from.label',
              defaultMessage: 'From'
            })
          }
        </Typography>
      )
      children.push(
        <TranslateLanguageSelect
          key={2}
          userInputForm={userInputForm}
          onChange={onChange}
          isTarget={false}
        />
      )
      children.push(
        <Typography key={3} sx={{ alignContent: 'center', minWidth: '0px' }}>
          {
            intl.formatMessage({
              id: 'app.translate-task-language-select.to.label',
              defaultMessage: 'to'
            })
          }
        </Typography>
      )
      children.push(
        <TranslateLanguageSelect
          key={4}
          userInputForm={userInputForm}
          onChange={onChange}
          isTarget={true}
        />
      )
    }
  }

  return (
    <Box className={'user-prompt-buttons'} sx={containerSx}>
      {children}
    </Box>
  )
}

export default UserPromptButtons
