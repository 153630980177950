import Loading from 'components/Loading'
import { useSession } from 'context/SessionContext'
import { useSessions } from 'features/sessions/api/getSessions'
import { type Session } from '../types'
import { StyledList } from './ListSessions.styles'
import SessionItem from './SessionItem'

export const ListSessions: React.FC = (): JSX.Element => {
  const { isPending, isError, data, error } = useSessions()
  const { selectedSessionId, changeSelectedSession } = useSession()

  if (isPending) return <Loading />
  if (isError) return <p>Error: {error.message}</p>

  return (
    <StyledList>
      {data.map((s: Session) => (
        <SessionItem
          key={s.id}
          session={s}
          selected={s.id === selectedSessionId}
          onClick={() => { changeSelectedSession(s.id) }}
        />
      ))}
    </StyledList>
  )
}

export default ListSessions
