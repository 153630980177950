import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { useUploadAttachment } from 'features/documents/api/uploadAttachment'
import { DOC_TYPE_CONTRACT, type Limits, type AttachmentOrUploadListItem } from 'features/documents/types'
import { type Globals } from 'features/globals/types'
import { useCallback } from 'react'
import { useDropzone, type DropEvent, type FileRejection } from 'react-dropzone'
import useListAttachmentsAndUploads from '../api/listAttachmentsAndUploads'
import fileRejectionsToErrorMsg from '../utils/fileRejectionsToErrorMsg'

export interface FileUploadHookResult {
  attachmentsAndUploads: AttachmentOrUploadListItem[] | undefined
  limits: Limits
  dropzoneState: ReturnType<typeof useDropzone>
  uploadDisabled: boolean
}

interface Props {
  onError: (error: Error) => void
}

/**
 * A hook to handle file uploads, providing the state for a dropzone components.
 */
export const useFileUpload = ({ onError }: Props): FileUploadHookResult | undefined => {
  const globals = useGlobals() as Globals
  const { selectedSession: session } = useSession()
  const attachmentsAndUploads = useListAttachmentsAndUploads()
  const uploadAttachmentMutation = useUploadAttachment()

  const processFile = (file: File): void => {
    if (session === null || session === undefined) {
      throw new Error('No session loaded!')
    }

    // Use a default docType for now
    const docType = DOC_TYPE_CONTRACT

    void uploadAttachmentMutation.mutateAsync({ file, docType }).then((task) => {
      console.debug('Upload task', task)
    }).catch((error) => {
      onError(error)
    })
  }

  const handleDrop = useCallback((acceptedFiles: File[]): void => {
    acceptedFiles.forEach(processFile)
  }, [])

  const handleError = useCallback((error: Error) => {
    const errorMessage = `Error uploading file: ${error.message}`
    console.error(errorMessage)
    onError(new Error(errorMessage))
  }, [])

  const handleDropRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
    const errorMessage = fileRejectionsToErrorMsg(fileRejections, limits)
    console.error(errorMessage)
    onError(new Error(errorMessage))
  }, [])

  const acceptedFiletypes = globals.attachments.acceptedFiletypes.map((filetype) => {
    return {
      [filetype.mime]: filetype.extensions
    }
  }).reduce((acc, curr) => ({ ...acc, ...curr }), {})

  // Limit the number of files that can be uploaded
  // to the difference between the max number of files and the current number of files
  const uploadQuotaRemaining = Math.max(0, globals.attachments.maxNbDocs - (attachmentsAndUploads?.length ?? 0))

  const uploadDisabled = uploadQuotaRemaining === 0

  const dropzoneState = useDropzone({
    onDrop: handleDrop,
    onError: handleError,
    onDropRejected: handleDropRejected,
    accept: acceptedFiletypes,
    maxFiles: uploadQuotaRemaining,
    maxSize: globals.attachments.maxDocSizeInMb * 1024 * 1024,
    // Disable dropzone if the whole component is disabled,
    // or if the docType is not selected yet
    disabled: uploadDisabled
  })

  if (session === undefined || attachmentsAndUploads === undefined) {
    return undefined
  }

  const limits = {
    uploadQuotaRemaining,
    ...globals.attachments
  }

  return {
    attachmentsAndUploads,
    limits,
    dropzoneState,
    uploadDisabled
  }
}
