import { createContext, useContext } from 'react'
import { getCookies } from '../services/cookies'

interface ColorModeContextProps {
  colorMode: 'light' | 'dark'
  setColorMode: (_: 'light' | 'dark') => void
}

const cookies = getCookies()
const storedColorMode: 'light' | 'dark' | undefined = cookies.get('colorMode')

const ColorModeContext = createContext<ColorModeContextProps>({
  colorMode: storedColorMode ?? 'light',
  setColorMode: (_: 'light' | 'dark') => {}
})

export default ColorModeContext

export const useColorMode = (): ColorModeContextProps => (
  useContext(ColorModeContext)
)
