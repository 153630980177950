import { Chip, Tooltip } from '@mui/material'
import { DOC_TYPE_NOT_CERTAIN } from 'features/documents/types'
import { type AttachmentListItem } from 'features/documents/types'
import WarningIcon from '@mui/icons-material/Warning'
import { useDeleteAttachment } from '../api/deleteAttachment'
import { DocTypeTypography, FilenameTypography, LabelContainer } from './AttachmentChip.styles'
import { Cancel } from '@mui/icons-material'
import { useIntl } from 'react-intl'

interface Props {
  attachment: AttachmentListItem
  deletable?: boolean
}

const AttachmentChip = ({ attachment, deletable }: Props): JSX.Element => {
  const intl = useIntl()
  const deleteAttachmentMutation = useDeleteAttachment({ attachmentId: attachment.id })

  const confirmationMsg = intl.formatMessage({
    id: 'app.attachment-chip.confirmation-msg',
    defaultMessage: 'Are you sure you want to delete this attachment?'
  })

  const handleDelete = (
    deletable ?? true
      ? (): void => {
          if (window.confirm(confirmationMsg)) {
            deleteAttachmentMutation.mutate()
          }
        }
      : undefined
  )

  const translateDocType = (docType: string): string => (
    intl.formatMessage({
      id: `app.doc-type.${docType}`,
      defaultMessage: docType
    })
  )

  const docTypeDescription: string =
    (
      attachment.docType === undefined ||
      attachment.docType === DOC_TYPE_NOT_CERTAIN
    )
      ? ''
      : ` (${translateDocType(attachment.docType)})`

  const label = <LabelContainer>
    <FilenameTypography>{attachment.filename}</FilenameTypography>
    <DocTypeTypography>{docTypeDescription}</DocTypeTypography>
  </LabelContainer>

  // const title: string = `Attached file ${attachment.filename}
  //   ${attachment.truncated
  //     ? ' (File too big, only part of it will be used)'
  //     : ''}`

  const truncatedMsg = intl.formatMessage({
    id: 'app.attachment-chip.truncated',
    defaultMessage: 'File too big, only part of it will be used'
  })

  const title = intl.formatMessage({
    id: 'app.attachment-chip.title',
    defaultMessage: 'Attached file {filename}'
  }, {
    filename: attachment.filename
  }) + (attachment.truncated ? ` (${truncatedMsg})` : '')

  const deleteTooltip = intl.formatMessage({
    id: 'app.attachment-chip.delete-tooltip',
    defaultMessage: 'Delete attachment'
  })
  const deleteIcon = (
    <Tooltip title={deleteTooltip}>
      <Cancel />
    </Tooltip>
  )

  return <Tooltip
    key={attachment.id}
    title={title}
  >
    <Chip
      label={label}
      onDelete={handleDelete}
      deleteIcon={deleteIcon}
      icon={
        attachment.truncated
          ? <WarningIcon sx={{ opacity: 0.5 }} />
          : undefined
      }
    />
  </Tooltip>
}

export default AttachmentChip
