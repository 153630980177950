import { type Entity } from 'features/anonymization/types'

import { type User } from 'context/AuthContext'
import { type SessionSettings } from 'features/sessions'
import { type UserInputForm } from 'features/userinputform/types'
import { createAuthHeaderFromToken, axiosBaseURL, defaultRequestTimeout } from 'services/axios'
import { transformKeysToSnakeCase, transformKeysToCamelCase } from 'services/utils'
import { type Message } from '../types'

export interface SendMessageParams {
  cleartextMsg: string
  anonMsg?: string
  anonAttachmentContents?: string[]
  attachmentIds?: string[]
  existingEntities?: Entity[]
  sessionSettings?: SessionSettings
  userInputFormKwargs?: Partial<UserInputForm>
}

export type SendMessageResponse = StartingResponse | StepResponse | DoneResponse | ErrorResponse

export interface StartingResponse {
  status: 'starting'
}
export interface StepResponse {
  status: 'step'
  step: string
}
export interface DoneResponse {
  status: 'done'
  userMsg: Message
  assistantMsg: Message
  durationS: number
}
export interface ErrorResponse {
  status: 'error'
}

export async function sendAndFetchResponse (
  token: string,
  user: User,
  sessionId: string,
  params: SendMessageParams,
  onStep: (step: string) => void,
  onDone: (userMsg: Message, assistantMsg: Message) => void,
  onError: () => void
): Promise<void> {
  // Since we are using fetch instead of axios (which has a case converter middleware),
  // we need to manually convert the params from camel to snake case
  const paramsSnakeCase = transformKeysToSnakeCase(params)

  const authHeader = createAuthHeaderFromToken(token)
  const url = new URL(`${axiosBaseURL}/sessions/${sessionId}/send`).toString()

  // Fetch the response
  let response: Response
  try {
    const _response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(paramsSnakeCase),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader
      },
      signal: AbortSignal.timeout(defaultRequestTimeout)
    }).catch((err) => {
      console.error('Error while sending message:', err)
      onError()
    })
    if (_response === undefined) return
    response = _response
  } catch (err: unknown) {
    if (err instanceof Error) {
      if (err.name === 'TimeoutError') {
        console.error('Error while sending message:', err)
      } else {
        // Some other problem.
        console.error(`Error: type: ${err.name}, message: ${err.message}`)
      }
    } else {
      console.error('Unknown error:', err)
    }
    // If an error occurred, call the onError callback
    // and return early
    onError()
    return
  }

  // Check that the response body is valid
  if (response.body == null) {
    console.error('No body found in /send response')
    onError()
    return
  }

  // Read the response body as a stream
  const reader = response.body.getReader()
  let buffer = ''
  let receivedFinalResponse = false
  while (!receivedFinalResponse) {
    const { done, value } = await reader.read()

    // Did the stream end?
    if (done) {
      // If so and we haven't received a final response
      // (either 'done' or 'error'), signal an error
      if (!receivedFinalResponse) {
        console.error('Stream ended, but no final response received')
        onError()
      }
      break
    }

    // Check if the value is undefined. If done is false, it should not be.
    if (value === undefined) {
      console.error('Received undefined value from /send response')
      onError()
      return
    }

    // Get the chunk as a string and add it to the buffer
    const text = new TextDecoder('utf-8').decode(value)
    buffer += text

    // Each intermediate response is on separate line.
    // We only need to process the most recent one, the last line
    const lines = buffer.trim().split('\n')
    if (lines.length === 0) continue
    const lastLine = lines[lines.length - 1]
    console.debug('lastLine: ', lastLine)

    // Try to parse the last line as JSON.
    // If it fails, it means that the response is not complete yet.
    let response: SendMessageResponse
    try {
      response = transformKeysToCamelCase(JSON.parse(lastLine)) as SendMessageResponse
      console.debug('response:', response)
    } catch (err) {
      if (err instanceof SyntaxError) {
        // The response is not complete yet, continue reading
        continue
      } else {
        console.error('Error while parsing response:', err)
        onError()
        return
      }
    }

    // Handle the intermediate or final response
    if (response.status === 'starting') {
      console.debug('Assistant is starting...')
    } else if (response.status === 'step') {
      onStep(response.step)
    } else if (response.status === 'done') {
      console.log(`Assistant took ${response.durationS}s to process message`)
      onDone(response.userMsg, response.assistantMsg)
      receivedFinalResponse = true
    } else if (response.status === 'error') {
      onError()
      receivedFinalResponse = true
    } else {
      console.error(`Response with unknown status: ${lastLine}`)
      onError()
      return
    }
  }
}
