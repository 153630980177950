import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { type User, useAuth } from '../../context/AuthContext'
import UserAvatar from '../UserAvatar'
import { LogoutButton } from './LogoutButton'

export const UserProfile: React.FC = () => {
  const currentUser = useAuth() as User

  // Needed to truncate long names and emails
  const typographyProps = {
    style: {
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }

  return (
    <ListItem>
      <ListItemAvatar>
        <UserAvatar />
      </ListItemAvatar>
      <ListItemText
        primary={currentUser.userProfile.fullName}
        primaryTypographyProps={typographyProps}
        secondary={currentUser.userProfile.email}
        secondaryTypographyProps={typographyProps}
      />
      <LogoutButton />
    </ListItem>
  )
}

export default UserProfile
