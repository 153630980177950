
import { useIntl } from 'react-intl'
import { StyledTypography } from './InputDiffLabel.styles'

interface Props {
  docIdx: number
}

const InputDiffLabel: React.FC<Props> = ({ docIdx }: Props) => {
  const intl = useIntl()

  // First doc is always the user input, then goes the attachments, if any
  const text = (
    docIdx === 0
      ? intl.formatMessage({
        id: 'app.review-modal.input-diff-label.input',
        defaultMessage: 'Input:'
      })
      : intl.formatMessage({
        id: 'app.review-modal.input-diff-label.attachment',
        defaultMessage: 'Attachment #{docIdx}:'
      }, {
        docIdx
      })
  )
  return (
    <StyledTypography className="input-diff-label">
      {text}
    </StyledTypography>
  )
}

export default InputDiffLabel
