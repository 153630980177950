import { Box, styled } from '@mui/material'

export const RightPaneContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: theme.spacing(1),
  gap: theme.spacing(1)
}))
