import { IconButton, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  right: '8px',
  top: '-12px',
  width: '16px',
  height: '16px',
  color: theme.palette.error.contrastText,
  backgroundColor: theme.palette.error.dark,
  '&:hover': {
    backgroundColor: theme.palette.error.light
  },
  '.MuiSvgIcon-root': {
    width: '14px',
    height: '14px'
  }
}))
