import { styled } from '@mui/material'

import { type SxProps, type Theme } from '@mui/material'

export const userInputTypographySx = (showRaw: boolean): SxProps<Theme> => ({
  // When text is formatted, it will already have some padding at the top
  paddingTop: showRaw ? 1 : 0,
  paddingBottom: 1
})

export const StyledBox = styled('div')({
  whiteSpace: 'pre-line',
  '& table': {
    borderCollapse: 'collapse'
  },
  '& th': {
    border: '1px solid black',
    padding: '0.5em'
  },
  '& td': {
    border: '1px solid black',
    padding: '0.5em'
  }
})
