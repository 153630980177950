import { useAuth0 } from '@auth0/auth0-react'
import FeedbackIcon from '@mui/icons-material/Feedback'
import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  Button,
  Fade,
  FormControl,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { createReport } from 'features/reports/api/createReport'
import { type Report } from 'features/reports/types'
import type React from 'react'
import { useState } from 'react'
import { useAuth, type User } from '../../context/AuthContext'
import { useSession } from '../../context/SessionContext'
import { ModalContainer } from '../../theme'
import CancelButton from '../buttons/CancelButton'
import { OutlinedButton } from '../buttons/OutlinedButton'
import { FormButtonsContainer, ModalHeader, formControlSx, textFieldSx } from './ReportModal.styles'
import { useIntl } from 'react-intl'

interface ReportModalProps {
  initOpen: boolean
}

export const ReportModal: React.FC<ReportModalProps> = (
  { initOpen }: ReportModalProps
) => {
  const intl = useIntl()
  const currentUser = useAuth() as User
  const { selectedSession: session } = useSession()
  const { getAccessTokenSilently } = useAuth0()

  const [open, setOpen] = useState(initOpen)
  const [comment, setComment] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const report: Report = {
      comment,
      url: window.location.href,
      sessionId: session?.id ?? undefined
    }
    void getAccessTokenSilently().then(async (token) => {
      await createReport(token, report, currentUser)
      setComment('')
      setOpen(false)
    })
  }

  const onClose = (): void => {
    setComment('')
    setOpen(false)
  }

  const editForm = (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl sx={formControlSx}>
          <TextField
            id="report-modal-input"
            multiline
            autoFocus
            fullWidth
            rows={19}
            variant="outlined"
            value={comment}
            onChange={(event) => { setComment(event.target.value) }}
            sx={textFieldSx}
          />
        </FormControl>

        {/* Buttons */}
        <FormButtonsContainer>
          <CancelButton onClick={onClose} />
          <Button
            type="submit"
            variant="contained"
            disabled={comment === ''}
            startIcon={<SendIcon />}
          >
            {
              intl.formatMessage({
                id: 'app.report-modal.send-button.label',
                defaultMessage: 'Send'
              })
            }
          </Button>
        </FormButtonsContainer>
      </form>
    </Box>
  )

  return (
    <div>
      <OutlinedButton
        type="submit"
        variant="outlined"
        startIcon={<FeedbackIcon />}
        onClick={ () => { setOpen(true) } }
      >
        {
          intl.formatMessage({
            id: 'app.report-modal.button.label',
            defaultMessage: 'Report / Feedback'
          })
        }
      </OutlinedButton>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="report-modal-title"
        aria-describedby="report-modal-description"
      >
        <Fade in={open}>
          <ModalContainer>
            <ModalHeader>
              <Typography id="report-modal-title" variant="h4">
                {
                  intl.formatMessage({
                    id: 'app.report-modal.title',
                    defaultMessage: 'Report a bug or make a suggestion'
                  })
                }
              </Typography>
              <CloseModalButton onClick={onClose} />
            </ModalHeader>
            <Box>
              {editForm}
            </Box>
          </ModalContainer>
        </Fade>
      </Modal>
    </div>
  )
}

export default ReportModal
