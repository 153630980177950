import { FormControl, Paper, TextField } from '@mui/material'
import { useState } from 'react'
import { type User, useAuth } from '../context/AuthContext'
import { type AnonymizationSettings } from 'features/anonymization/types'
import { anonymize } from 'features/anonymization/api/anonymize'
import { type ReviewState, responseToState } from '../services/reviewState'
import InputDiff from '../components/review-modal/inputs/InputDiff'
import ProgressBackdrop from '../components/ProgressBackdrop'
import { useAuth0 } from '@auth0/auth0-react'

const emptyAnonymizationSettings: AnonymizationSettings = {
  entityTypesRules: [],
  anonymizationRules: [],
  aliasRules: []
}

function AnonPlaygroundPage (): JSX.Element {
  const currentUser = useAuth() as User
  const { getAccessTokenSilently } = useAuth0()

  const [input, setInput] = useState('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [state, setState] = useState<ReviewState | undefined >(undefined)

  const handleSubmit = (): void => {
    // Call anonymize API with empty settings and a single input
    setProcessing(true)
    void (async function () {
      const token = await getAccessTokenSilently()
      const response = await anonymize(
        token,
        input,
        [],
        emptyAnonymizationSettings,
        [],
        [],
        [],
        currentUser
      )
      setState(responseToState(response))
      setProcessing(false)
    })()
  }

  return (
    <>
      <FormControl sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifySelf: 'center',
        justifyContent: 'space-between',
        gap: 1,
        mx: 'auto',
        my: 2,
        maxWidth: '1200px',
        height: '100%'
      }}>
        {/* Text area to input some text to anonymize */}
        <Paper sx={{
          display: 'flex',
          flex: '1 1 600px'
        }}>
          <TextField
            id="text-input"
            fullWidth
            multiline
            value={input}
            disabled={processing}
            onChange={(event) => { setInput(event.target.value) }}
            onKeyUp={(event) => {
              if (event.ctrlKey && event.key === 'Enter') { handleSubmit() }
            }}
          />
        </Paper>

        {/* Show the anonymized text */}
        <Paper sx={{
          display: 'flex',
          flex: '1 1 600px'
        }}>
          {state !== undefined && <InputDiff
            inputDiff={state.inputDiffs[0]}
            docIdx={0}
            entityTypeToActiveEntitiesCleartext={state.entityTypeToActiveEntitiesCleartext}
          />}
        </Paper>
      </FormControl>
      <ProgressBackdrop open={processing} />
    </>
  )
}

export default AnonPlaygroundPage
