import LogoutIcon from '@mui/icons-material/Logout'
import {
  IconButton,
  Tooltip
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useIntl } from 'react-intl'

export const LogoutButton = (): JSX.Element => {
  const intl = useIntl()
  const { logout } = useAuth0()

  const handleLogout = (): void => {
    const logoutURL = window.location.origin + '/login'
    void logout({ logoutParams: { returnTo: logoutURL } })
  }

  const tooltip = intl.formatMessage({
    id: 'app.logout-button.tooltip',
    defaultMessage: 'Logout'
  })

  return (
    <Tooltip title={tooltip}>
      <IconButton
        edge="end"
        size="small"
        onClick={handleLogout}
        sx={{ alignSelf: 'start' }}
      >
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  )
}
