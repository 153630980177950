import { createAuthHeaderFromToken, axiosInstance } from 'services/axios'
import { type Session } from '../types'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { queryClient } from 'services/react-query'

interface UpdateSessionVariables {
  sessionId: string
  update: Partial<Session>
}

interface UpdateSessionContext {
  previousSessions: Session[]
}

export function useUpdateSession (): UseMutationResult<void, Error, UpdateSessionVariables, UpdateSessionContext> {
  const { getAccessTokenSilently } = useAuth0()

  const mutationFn = async ({ sessionId, update }: UpdateSessionVariables): Promise<void> => {
    await getAccessTokenSilently().then(async (token) => {
      await updateSession(token, sessionId, update)
    })
  }

  return useMutation({
    mutationKey: ['updateSession'],
    mutationFn,
    onMutate: async ({ sessionId, update }) => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['sessions'], exact: true })

      // Snapshot the previous value
      const sessions = queryClient.getQueryData(['sessions']) as Session[]

      // Optimistically update to the new value
      const sessionIndex = sessions.findIndex((session) => session.id === sessionId)
      if (sessionIndex === -1) {
        return
      }

      const updatedSessions = sessions.map((session, index) =>
        index === sessionIndex ? { ...session, ...update } : session
      )
      queryClient.setQueryData(['sessions'], updatedSessions)

      // Return a context object with the snapshot value
      return { previousSessions: sessions }
    },
    onError: (error, { sessionId }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error(`Update session ${sessionId} failed`, error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(['sessions'], context.previousSessions)
    },
    onSettled: async () => {
      // Always refetch after error or success
      await queryClient.invalidateQueries({
        queryKey: ['sessions'],
        exact: true
      })
    }
  })
}

const updateSession = async (
  token: string,
  sessionId: string,
  update: Partial<Session>
): Promise<void> => {
  const authHeader = createAuthHeaderFromToken(token)
  await axiosInstance.patch(
    `/sessions/${sessionId}`,
    update,
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  )
}
