import { GatewaiLogo } from './GatewaiLogo'
import ToggleColorMode from '../ToggleColorMode'
import { LeftPaneHeaderContainer } from './LeftPaneHeader.styles'

const LeftPaneHeader: React.FC = () => (
  <LeftPaneHeaderContainer>
    <GatewaiLogo />
    <ToggleColorMode />
  </LeftPaneHeaderContainer>
)

export default LeftPaneHeader
