import { Typography } from '@mui/material'
import MuiMarkdown, { type Overrides } from 'mui-markdown'
import { useIntl } from 'react-intl'
import { StyledBox, userInputTypographySx } from './MessageText.styles'

interface Props {
  content: string
  showRaw?: boolean
}

const MessageText: React.FC<Props> = ({ content, showRaw }: Props) => {
  const intl = useIntl()

  // Create an empty overrides object to pass to MuiMarkdown,
  // to remove its default styles which are not to our liking.
  const overrides: Overrides = {}

  const showRaw_ = showRaw ?? false

  return (
    <StyledBox className='message-text'>
      {
        content.length > 0
          ? (
              showRaw_
                ? content
                : <MuiMarkdown overrides={overrides}>{content}</MuiMarkdown>
            )
          : <Typography sx={userInputTypographySx(showRaw_)}>
              <i>{
                intl.formatMessage({
                  id: 'app.message-text.no-additional-input',
                  defaultMessage: 'No additional input'
                })
              }</i>
            </Typography>
      }
    </StyledBox>
  )
}

export default MessageText
