import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  IconButton,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { type AliasRule, type EntityType, type RuleKey } from 'features/anonymization/types'
import type React from 'react'
import { useState } from 'react'
import { PosAnonymizeStatus, aggregatePosAnonymizeStatuses, areAliasesEnabled, isDefaultSettingItem, itemToReplacement, posAnonymizeStatusToBooleanOrNull, replacementSelectorSx, type ReviewStateItem } from '../../../services/reviewState'
import { AliasIcon } from '../AliasIcons'
import EntityTypeOrAliasSelector from '../selectors/EntityTypeOrAliasSelector'
import ActiveTripleSwitch from '../switches/ActiveTripleSwitch'
import RuleTripleSwitch, { RuleSwitchState } from '../switches/RuleTripleSwitch'
import AddAliasModal from './AddAliasModal'
import { AddIconButton, CleartextContainer, ReviewTableCell, ReviewTableCellContainer, SwitchTableCell, aliasItemTypographySx, cleartextTypographySx } from './ReviewStateItemRow.styles'
import { useIntl } from 'react-intl'
import { useColorMode } from 'context/ColorModeContext'

interface Props {
  item: ReviewStateItem
  items: ReviewStateItem[]
  aliasItems: ReviewStateItem[]
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  scope: 'global' | 'msg-specific'
  onChange: (
    prevItem: ReviewStateItem,
    item: ReviewStateItem,
    newItem?: ReviewStateItem,
    includeAliases?: boolean
  ) => void
  onChangeAliasRule: (aliasRule: AliasRule) => void
  onDeleteAnonRule: (key: RuleKey) => void
}

/**
 * A row in the review modal, showing an entity and its replacement.
 * Switches are used to modify the anonymization or alias rule.
 */
const ReviewStateItemRow: React.FC<Props> = (
  {
    item,
    items,
    aliasItems,
    entityTypeToActiveEntitiesCleartext,
    scope,
    onChange,
    onChangeAliasRule,
    onDeleteAnonRule
  }: Props
) => {
  const intl = useIntl()
  const theme = useTheme()
  const { colorMode } = useColorMode()
  const globals = useGlobals()
  if (globals === null) return <></>

  const [addAliasModalOpen, setAddAliasModalOpen] = useState(false)
  const [selectorOpen, setSelectorOpen] = useState(false)

  // , entityId, posAnonymizeStatus
  const { cleartext, entityType, anonymize, aliases } = item
  const isDefaultSetting = isDefaultSettingItem(item)
  const aliasesEnabled = areAliasesEnabled(item, globals)
  const canAddAlias = anonymize !== false && !isDefaultSetting && aliasesEnabled

  // List of all cleartext and aliases from the tag of each item (when defined),
  // to prevent adding an alias that already exists.
  const existingCleartexts = items.map((item) => [cleartext, ...aliases]).flat()

  const handleAddAlias = (): void => {
    setAddAliasModalOpen(true)
  }

  // const handlePromoteAlias = (alias: string): void => {
  //   onChange({
  //     ...item,
  //     cleartext: alias,
  //     aliases: [...aliases.filter((a) => a !== alias), cleartext]
  //   })
  // }

  const handleAddAliasModalClose = (): void => {
    setAddAliasModalOpen(false)
  }

  const handleAddAliasModalSubmit = (newAliases: string[]): void => {
    setAddAliasModalOpen(false)
    onChangeAliasRule({
      cleartext,
      entityType,
      aliases: [...aliases, ...newAliases]
    })
  }

  const handleDeleteAlias = (alias: string): void => {
    // if (window.confirm('Are you sure you want to delete this variant?')) {
    onChangeAliasRule({
      cleartext,
      entityType,
      aliases: aliases.filter((a) => a !== alias)
    })
    // }
  }

  // const handleDeleteEntity = (item: ReviewStateItem): void => {
  //   if (window.confirm('Are you sure you want to delete this rule?')) {
  //     onDelete(item.cleartext)
  //   }
  // }

  const handleChangeActiveSwitch = (
    changedItem: ReviewStateItem,
    posAnonymizeStatus: PosAnonymizeStatus,
    includeAliases: boolean
  ): void => {
    switch (posAnonymizeStatus) {
      case PosAnonymizeStatus.NONE:
        onChange(changedItem, { ...changedItem, posAnonymizeStatus }, undefined, includeAliases)
        break
      case PosAnonymizeStatus.SOME:
        throw new Error(
          'Tried switched Active to "some", should not be possible'
        )
      case PosAnonymizeStatus.ALL:
        onChange(changedItem, { ...changedItem, posAnonymizeStatus }, undefined, includeAliases)
        break
    }
  }

  const handleChangeGlobalRuleSwitch = (
    changedItem: ReviewStateItem,
    state: RuleSwitchState
  ): void => {
    switch (state) {
      case RuleSwitchState.EXCLUDE:
        onChange(changedItem, { ...changedItem, anonymize: false })
        break
      case RuleSwitchState.AUTO:
        // Delete the rule without asking for confirmation
        onDeleteAnonRule({
          cleartext: changedItem.cleartext,
          entityType: changedItem.entityType
        })
        break
      case RuleSwitchState.ALWAYS:
        onChange(changedItem, { ...changedItem, anonymize: true })
        break
    }
  }

  // For msg-specific scope, if there are aliases, the switch state should correspond
  // to an aggregate of the main item and all aliases' status.
  const aggregatedItem = (
    aliasItems.length > 0
      ? { ...item, posAnonymizeStatus: aggregatePosAnonymizeStatuses([item, ...aliasItems]) }
      : item
  )

  const anonymizeSwitchStatus: boolean | null = (
    scope === 'global'
      ? item.anonymize
      : posAnonymizeStatusToBooleanOrNull(aggregatedItem.posAnonymizeStatus)
  )

  const notAnonymized = anonymizeSwitchStatus === false

  const addAliasTooltip = intl.formatMessage({
    id: 'app.review-state-item-row.add-alias-button.tooltip',
    defaultMessage: 'Add a variant for this entity'
  })

  const deleteAliasTooltip = intl.formatMessage({
    id: 'app.review-state-item-row.delete-alias-button.tooltip',
    defaultMessage: 'Delete this variant'
  })

  const aliasForTooltip = (cleartext: string): string => intl.formatMessage({
    id: 'app.review-state-item-row.alias-for.tooltip',
    defaultMessage: 'Alias for "{cleartext}"'
  }, {
    cleartext
  })

  return (
    <>
      {/*
        Row representing the main replacement for the entity.
        Aliases will be in other rows below
      */}
      <TableRow className='review-state-item-row'>

        {/* Entity */}

        <ReviewTableCell component="th" scope="row">
          <ReviewTableCellContainer>
            <CleartextContainer>
              <Typography sx={cleartextTypographySx(notAnonymized, colorMode)}>
                <span>{cleartext}</span>
              </Typography>
              {canAddAlias && <Tooltip title={addAliasTooltip}>
                <AddIconButton onClick={handleAddAlias} />
              </Tooltip>}
            </CleartextContainer>
            {canAddAlias && <>
              <AddAliasModal
                existingCleartexts={existingCleartexts}
                open={addAliasModalOpen}
                onClose={handleAddAliasModalClose}
                onSubmit={handleAddAliasModalSubmit}
              />
            </>}
          </ReviewTableCellContainer>
        </ReviewTableCell>

        {/* Replacement */}

        <ReviewTableCell>
          <EntityTypeOrAliasSelector
            open={selectorOpen}
            onOpen={() => { setSelectorOpen(true) }}
            onClose={() => { setSelectorOpen(false) }}
            value={
              // Show the replacement for msg-specific rules,
              // or the entity type for global rules
              (
                scope === 'msg-specific' ? itemToReplacement(aggregatedItem) : null
              ) ?? item.entityType
            }
            sx={
              replacementSelectorSx(
                item.entityType,
                anonymizeSwitchStatus,
                isDefaultSetting,
                globals.entityTypeToColor,
                theme.palette
              )
            }
            disabled={notAnonymized || isDefaultSetting}
            openDirection='right'
            cleartext={cleartext}
            entityTypeToActiveEntitiesCleartext={entityTypeToActiveEntitiesCleartext}
            onAddExactMatch={(entityType, asAliasOf) => {
              const itemsToChange: ReviewStateItem[] = (
                scope === 'msg-specific' && aliasItems.length > 0
                  ? [item, ...aliasItems]
                  : [item]
              )
              itemsToChange.forEach((item) => {
                onChange(item, { ...item, entityType })
                if (asAliasOf !== undefined) {
                  // Find rules that matches asAliasOf
                  // and get the existing aliases, if any,
                  const aliasRule = items.find((item) => item.cleartext === asAliasOf)
                  const existingAliases = aliasRule?.aliases ?? []
                  onChangeAliasRule({
                    cleartext: asAliasOf,
                    entityType,
                    aliases: [...existingAliases, cleartext]
                  })
                }
              })
            }}
          />
        </ReviewTableCell>

        {/* Anon rule switch (msg-specific or global) */}

        <SwitchTableCell>
          {scope === 'msg-specific'
            ? <ActiveTripleSwitch
                item={aggregatedItem}
                onChange={(posAnonymizeStatus) => {
                  const includingAliases = true
                  handleChangeActiveSwitch(item, posAnonymizeStatus, includingAliases)
                }}
              />
            : (
                // Global rule switch is only shown here if there are no aliases.
                // (Otherwise, switches will be shown for each alias item + the main item)
                aliasItems.length === 0 && <RuleTripleSwitch
                  item={item}
                  onChange={(state) => { handleChangeGlobalRuleSwitch(item, state) }}
                />
              )
          }
        </SwitchTableCell>
      </TableRow>

      {/* Aliases (if any) are shown below */}
      {
        // Also show the main item if it is an alias, to create a more logical grouping
        aliasItems.length > 0 && [item, ...aliasItems].map((aliasItem, idx) => (
          <TableRow
            className='review-state-item-alias-row'
            key={idx}
          >
            {/* Alias cleartext and button the remove the alias rule */}
            <ReviewTableCell component="th" scope="row">
              <ReviewTableCellContainer>
                <Tooltip title={aliasItem !== item && aliasForTooltip(cleartext)}>
                  <Typography sx={aliasItemTypographySx(item)}>
                    <AliasIcon />
                    {aliasItem.cleartext}
                  </Typography>
                </Tooltip>
                {scope === 'global' && aliasItem !== item && <Tooltip title={deleteAliasTooltip}>
                  <IconButton
                    size="small"
                    onClick={() => { handleDeleteAlias(aliasItem.cleartext) } }
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>}
                {/* {!isDefaultSetting && <Tooltip title='Make this alias the default one when de-anonymizing'><IconButton
                  size="small"
                  onClick={() => { handlePromoteAlias(alias) } }
                  sx={{ ml: 1 }}
                >
                  <ArrowUpwardIcon sx={{ color: 'primary.main' }} />
                </IconButton></Tooltip>} */}
              </ReviewTableCellContainer>
            </ReviewTableCell>
            <ReviewTableCell>
            </ReviewTableCell>

            {/* Anon rule switch (msg-specific or global) */}
            <SwitchTableCell>
              {scope === 'msg-specific'
                ? <ActiveTripleSwitch
                    item={aliasItem}
                    onChange={(posAnonymizeStatus) => {
                      const includingAliases = false
                      handleChangeActiveSwitch(aliasItem, posAnonymizeStatus, includingAliases)
                    }}
                  />
                : <RuleTripleSwitch
                    item={aliasItem}
                    onChange={(state) => { handleChangeGlobalRuleSwitch(aliasItem, state) }}
                  />
              }
            </SwitchTableCell>
          </TableRow>
        ))
      }
    </>
  )
}

export default ReviewStateItemRow
