import HelpIcon from '@mui/icons-material/Help'
import { List, ListItem, Paper } from '@mui/material'
import type Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type React from 'react'
import { type Message } from 'features/messages/types'
import ExportDocxButton from '../../features/export/components/ExportDocxButton'
import ExportEmailButton from '../../features/export/components/ExportEmailButton'
import ReportModal from './ReportModal'
import { useSwipeable } from 'react-swipeable'
import PaneSection from '../PaneSection'
import { OutlinedButton } from '../buttons/OutlinedButton'
import { RightPaneContainer } from './RightPane.styles'
import { useIntl } from 'react-intl'
import { openHelpPage } from 'components/left-pane/GatewaiLogo'

interface RightPaneProps {
  lastAssistantMessage?: Message
  agentStatus: string
  containerProps?: React.ComponentProps<typeof Box>
  onClose?: () => void
}

export const RightPane: React.FC<RightPaneProps> = ({
  lastAssistantMessage,
  agentStatus,
  onClose
}) => {
  const intl = useIntl()

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => { onClose?.() }
  })

  const handleClickHelp = (): void => {
    openHelpPage(intl.locale)
  }

  const exportSectionTitle = intl.formatMessage({
    id: 'app.right-pane.export-section.title',
    defaultMessage: 'Export last output'
  })

  const agentStatusTitle = intl.formatMessage({
    id: 'app.right-pane.agent-status.title',
    defaultMessage: 'Agent status'
  })

  return (
    <RightPaneContainer className='right-pane' {...swipeHandlers}>
      {/* Export to */}
      <PaneSection
        sx={{ flexGrow: 1, marginBottom: 'auto' }}
        title={exportSectionTitle}
      >
        <ExportDocxButton lastAssistantMessage={lastAssistantMessage}/>
        <ExportEmailButton lastAssistantMessage={lastAssistantMessage}/>
      </PaneSection>

      {/* Help & Report bug */}
      <List
        sx={{ flexGrow: 0, display: 'flex', flexDirection: 'column' }}
        aria-labelledby="help-subheader"
      >
        <ListItem sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
          <OutlinedButton
            variant="outlined"
            startIcon={<HelpIcon />}
            onClick={handleClickHelp}
          >
            {
              intl.formatMessage({
                id: 'app.right-pane.help-button.label',
                defaultMessage: 'Help'
              })
            }
          </OutlinedButton>
        </ListItem>
        <ListItem sx={{ justifyContent: 'center' }}>
          <ReportModal initOpen={false} />
        </ListItem>
      </List>

      {/* Agent status message */}
      <PaneSection
        sx={{ flexGrow: 0 }}
        title={agentStatusTitle}
      >
        <Paper sx={{ minHeight: '10vh', p: 1, width: '100%' }}>
          <Typography sx={{ textAlign: 'center' }}>
            {agentStatus}
          </Typography>
        </Paper>
      </PaneSection>
    </RightPaneContainer>
  )
}
