import Cookies, { type CookieSetOptions } from 'universal-cookie'

const options: CookieSetOptions = {
  path: '/',
  sameSite: 'strict'
}

export const getCookies = (): Cookies => (
  new Cookies(null, options)
)

export const setCookie = (name: string, value: string): void => {
  getCookies().set(name, value, options)
}
