import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type PosAnonymizeStatus } from 'services/reviewState'
import { type AnonRule, type Tag, type EntityType } from '../types'

export const updateMsgAnonymizationRules = async (
  token: string,
  text: string,
  attachmentIds: string[],
  msgAnonymizationRules: AnonRule[],
  tags: Tag[],
  cleartexts: string[],
  entityType: EntityType,
  updatedStatus: PosAnonymizeStatus | null,
  user: User
): Promise<AnonRule[]> => {
  const response = await axiosInstance.post<AnonRule[]>(
    '/msg-anonymization-rules',
    {
      text,
      attachmentIds,
      msgAnonymizationRules,
      tags,
      cleartexts,
      entityType,
      updatedStatus
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}
