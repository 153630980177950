import { forwardRef } from 'react'
import { StyledIconButton } from './TopRightCloseButton.styles'
import CloseIcon from '@mui/icons-material/Close'

const TopRightCloseButton = forwardRef<HTMLDivElement, any>(function TopRightCloseButton (props, ref) {
  return <StyledIconButton ref={ref} {...props}>
    <CloseIcon />
  </StyledIconButton>
})

export default TopRightCloseButton
