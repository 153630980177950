import { type EntityGroup } from 'features/anonymization/types'

import { type Assistant } from 'features/assistants'
import { DOC_TYPE_CONTRACT, DOC_TYPE_NOT_CERTAIN } from 'features/documents/types'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type ConfidentialityLevel, type Globals, type GlobalsResponse } from '../types'

export const getGlobals = async (token: string): Promise<Globals> => {
  const authHeader = createAuthHeaderFromToken(token)
  return await axiosInstance.get(
    '/globals',
    {
      headers: authHeader,
      cache: {
        ttl: defaultTTL
      }
    }
  ).then((response) => {
    const {
      levels,
      defaultLevel,
      entityTypesByGroup,
      assistants,
      docTypes,
      attachments
    } = response.data as GlobalsResponse

    const _defaultLevel: ConfidentialityLevel | undefined = levels.find((c) => c.level === defaultLevel)
    if (_defaultLevel === undefined) {
      throw new Error(`Default level ${defaultLevel} not found!`)
    }

    const entityTypeToColor = new Map()
    for (const group of entityTypesByGroup) {
      for (const entityType of group.entityTypes) {
        entityTypeToColor.set(entityType.entityType, entityType.color)
      }
    }

    const entityTypesByGroupMap = new Map()
    const flattenedGroups: EntityGroup[] = []
    const defaultEntityTypesRulesMap = new Map()
    for (const group of entityTypesByGroup) {
      if (group.flatten) flattenedGroups.push(group.group)
      entityTypesByGroupMap.set(group.group, group.entityTypes)
      for (const entityType of group.entityTypes) {
        defaultEntityTypesRulesMap.set(entityType.entityType, entityType.enabledByDefault)
      }
    }

    if (assistants.length === 0) {
      throw new Error('No assistants found!')
    }
    const assistantsById = new Map<string, Assistant>()
    assistants.forEach((assistant) => {
      assistantsById.set(assistant.id, assistant)
    })
    const defaultAssistantId = assistants[0].id

    const docTypesMap = new Map()
    for (const docType of docTypes) {
      docTypesMap.set(docType.type, docType.desc)
    }
    // Check that the constants match the doc types from the backend
    if (!docTypesMap.has(DOC_TYPE_CONTRACT)) {
      throw new Error(`Doc type "${DOC_TYPE_CONTRACT}" not found in backend`)
    }
    if (!docTypesMap.has(DOC_TYPE_NOT_CERTAIN)) {
      throw new Error(`Doc type "${DOC_TYPE_NOT_CERTAIN}" not found in backend`)
    }

    return {
      levels,
      defaultLevel: _defaultLevel,
      entityTypesByGroup: entityTypesByGroupMap,
      defaultEntityTypesRulesMap,
      entityTypeToColor,
      flattenedGroups,
      assistantsById,
      defaultAssistantId,
      docTypes: docTypesMap,
      attachments
    }
  })
}
