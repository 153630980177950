import { SvgIcon, useTheme } from '@mui/material'
import type React from 'react'
import { fill, sendIconStyle } from './SendWithReviewIcon.styles'

interface Props {
  disabled: boolean
}

const SendWithReviewIcon: React.FC<Props> = ({ disabled }: Props) => {
  const theme = useTheme()
  return <SvgIcon style={sendIconStyle}>
    <svg
      fill={fill(disabled, theme.palette)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 13.397959,17.639876 17.588272,6.72524 6.7931486,11.214487 9.3581159,13.716918 15.446475,8.9268428 10.825853,15.144784 Z"
        id="path2" />
      <path
        d="M 12.265426,0.6436137 3.1034827,4.0793425 v 6.9745305 c 0,5.783475 3.9052782,11.177569 9.1619433,12.4946 5.256665,-1.317029 9.161943,-6.711125 9.161943,-12.4946 V 4.0793425 Z m 6.871457,10.4102593 c 0,4.580972 -2.920369,8.818369 -6.871457,10.112494 C 8.3143378,19.872242 5.3939684,15.646296 5.3939684,11.053873 V 5.6712295 l 6.8714576,-2.576796 6.871457,2.576796 z"
        id="path825" />
    </svg>
  </SvgIcon>
}

export default SendWithReviewIcon
