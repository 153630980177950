import { type ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { getUserSettings } from 'features/user/api/getUserSettings'
import { type UserSettings } from 'features/user/types'
import { updateUserSettings as updateUserSettingsApi } from 'features/user/api/updateUserSettings'
import { type User, useAuth } from './AuthContext'
import { useAuth0 } from '@auth0/auth0-react'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import Loading from 'components/Loading'

interface UserSettingsContextType {
  userSettings: UserSettings | null
  updateUserSettings: (newSettings: UserSettings, sessionId?: string) => void
}

export const UserSettingsContext = createContext<UserSettingsContextType>(
  { userSettings: null, updateUserSettings: () => {} }
)

interface Props {
  children: ReactNode
}

export const UserSettingsProvider = ({ children }: Props): JSX.Element => {
  const currentUser = useAuth() as User
  const { getAccessTokenSilently } = useAuth0()
  const { mutate } = useUpdateSession()

  const [userSettings, setUserSettings] = useState<UserSettings | null>(null)

  useEffect(() => {
    console.debug('> UserSettingsProvider [currentUser]')

    if (currentUser === undefined || currentUser === null) {
      return
    }

    getAccessTokenSilently().then(async (token) =>
      await getUserSettings(token, currentUser)
    ).then((userSettings) => {
      console.debug('User settings:', userSettings)
      setUserSettings(userSettings)
    }).catch((error: any) => {
      console.error(error)
    })
  }, [currentUser])

  if (userSettings === null) {
    return <Loading />
  }

  const updateUserSettings = (newSettings: UserSettings, sessionId?: string): void => {
    void (async function () {
      const token = await getAccessTokenSilently()

      // If a session ID is provided, update the session settings,
      // which will be used over the user settings for assistant and
      // confidentiality level.
      if (sessionId !== undefined) {
        const sessionUpdate = {
          settings: {
            assistantId: newSettings.assistantId,
            confidentialityLevel: newSettings.confidentialityLevel.level
          }
        }
        mutate({ sessionId, update: sessionUpdate })
      }
      // Call the API to update the user settings
      updateUserSettingsApi(token, currentUser, newSettings).catch((error: any) => {
        console.error(error)
      })

      setUserSettings(newSettings)
    })()
  }

  return (
    <UserSettingsContext.Provider value={{ userSettings, updateUserSettings }}>
      {children}
    </UserSettingsContext.Provider>
  )
}

export const useUserSettings = (): UserSettings => (
  useContext(UserSettingsContext).userSettings as UserSettings
)
