import { forwardRef } from 'react'
import { OutlinedButton } from './OutlinedButton'
import { useIntl } from 'react-intl'

const CancelButton = forwardRef<HTMLDivElement, any>(function CancelButton (props, ref) {
  const intl = useIntl()
  return (
    <OutlinedButton variant={'outlined'} {...props} ref={ref}>
      {
        intl.formatMessage({
          id: 'app.cancel-button.label',
          defaultMessage: 'Cancel'
        })
      }
    </OutlinedButton>
  )
})

export default CancelButton
