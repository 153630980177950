import { Box, CircularProgress, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

const UploadInProgress = (): JSX.Element => {
  const intl = useIntl()
  return <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
    <Box>
      <CircularProgress size={20} />
    </Box>
    <Typography>
      {
        // Uploading...
        intl.formatMessage({
          id: 'app.upload-in-progress',
          defaultMessage: 'Uploading...'
        })
      }
    </Typography>
  </Box>
}

export default UploadInProgress
