import { type SxProps, type Theme } from '@mui/material'

export const containerStyle: React.CSSProperties = {
  display: 'inline',
  position: 'relative',
  overflow: 'visible'
}

export const typographySx: SxProps<Theme> = {
  whiteSpace: 'pre-wrap'
}

export const popoverSx = {
  pointerEvents: 'none'
}

export const popoverSlotProps = {
  paper: {
    sx: { pointerEvents: 'auto' }
  }
}
