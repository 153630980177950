import { IconButton, alpha, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: '33%',
  mt: 'auto',
  mb: 'auto',
  padding: theme.spacing(0),
  borderRadius: theme.spacing(4),
  color: 'text.primary',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))
