import { Box, CircularProgress, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

interface Props {
  filename: string
}

const Processing: React.FC<Props> = ({ filename }: Props) => {
  const intl = useIntl()

  // Custom message for PDF files
  const extension = filename.split('.').pop()
  console.log('Processing extension:', extension)

  const processing = intl.formatMessage({
    id: 'app.processing',
    defaultMessage: 'Processing'
  })
  const ocrTimeWarning = intl.formatMessage({
    id: 'app.processing.ocr-time-warning',
    defaultMessage: 'OCR may take time'
  })
  const message = (
    extension === 'pdf'
      ? `${processing} (${ocrTimeWarning})...`
      : `${processing}...`
  )

  return <Box display="flex" alignItems="center" justifyItems={'center'} gap={1}>
    <Box>
      <CircularProgress size={30} />
    </Box>
    <Typography flexWrap={'wrap'}>
      {message}
    </Typography>
  </Box>
}

export default Processing
