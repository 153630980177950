import type React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GlobalsProvider } from './context/GlobalsContext'
import './index.css'
import AdminPage from './pages/AdminPage'
import AnonPlaygroundPage from './pages/AnonPlaygroundPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import NotFoundPage from './pages/NotFoundPage'
import CallbackPage from './pages/CallbackPage'
import { Auth0ProviderWithNavigate } from './context/Auth0ProviderWithNavigate'
import AuthenticationGuard from './components/AuthenticationGuard'
import { SessionProvider } from './context/SessionContext'
import { UserSettingsProvider } from './context/UserSettingsContext'
import SignUpPage from './pages/SignUpPage'
import WelcomePage from 'pages/WelcomePage'

const Root: React.FC = (): JSX.Element => {
  /**
   * Wrap the HomePage with the providers it needs to have access to.
   * This has to be done here because those provider need the user to be
   * authenticated, and the AuthenticationGuard requires a proper component
   * to be passed to it and not a JSX expression.
   */
  const HomePageWithProviders: React.FC = (): JSX.Element => {
    return (
      <GlobalsProvider>
        <SessionProvider>
          <UserSettingsProvider>
            <HomePage />
          </UserSettingsProvider>
        </SessionProvider>
      </GlobalsProvider>
    )
  }

  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <Routes>
          {/* TODO: could be a nested route, with the "/" page managing the redirect
              to the /:sessionId page. */}
          <Route path="/" element={
            <AuthenticationGuard component={HomePageWithProviders}/>
          } />
          <Route path="/:sessionId" element={
            <AuthenticationGuard component={HomePageWithProviders}/>
          } />

          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignUpPage />} />

          <Route path="/anon" element={
            <AuthenticationGuard component={AnonPlaygroundPage} />
          } />
          <Route path="/admin" element={
            <AuthenticationGuard component={AdminPage} />
          } />

          <Route path="/welcome" element={<WelcomePage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  )
}

export default Root
