import { Box, styled } from '@mui/material'

export const LeftPaneContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  overflowY: 'hidden'
}))

export const assistantConfigurationContainerSx = {
  gap: 1.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
