import { Box, FormControl, styled } from '@mui/material'
import { ModalContainer } from '../../../theme'

export const ReviewModalContainer = styled(ModalContainer)(({ theme }) => ({
  width: '95%',
  maxWidth: '95%',
  [theme.breakpoints.up('xs')]: {
    minWidth: '100%',
    height: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: '95%',
    height: '95%'
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '800px',
    height: '95%'
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: '800px',
    height: '95%'
  }
}))

export const InputReviewTab = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: '600px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflowY: 'hidden',
  ':hover': { overflowY: 'auto' }
}))

export const GlobalRulesTab = styled(Box)(() => ({
  flexGrow: 1,
  flexShrink: 0,
  flexDirection: 'column',
  flexBasis: '600px',
  display: 'flex',
  padding: '0px 8px 0px 8px'
}))

export const ReviewPane = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  p: { xs: 0, sm: theme.spacing(1) },
  maxWidth: '1100px',
  display: 'flex',
  flexDirection: 'column'
}))

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  p: theme.spacing(1)
}))

export const InputDiffs = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  p: { xs: 0, sm: theme.spacing(1) },
  scrollbarGutter: 'stable',
  overflowY: 'hidden',
  ':hover': { overflowY: 'auto' }
}))

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '90vh',
  gap: 1,
  justifyContent: 'center'
}))

export const FormButtons = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  flexShrink: 0,
  minHeight: '5vh',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(1)
}))

export const LeftSideButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  ml: 0,
  mr: 'auto',
  gap: theme.spacing(1)
}))

export const RightSideButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  ml: 'auto',
  mr: 0,
  gap: theme.spacing(1)
}))

export const Spacer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2)
}))
