import { type ReactNode, useEffect, useState } from 'react'
import 'firebaseui/dist/firebaseui.css'
import { getUserProfile } from 'features/user/api/getUserProfile'
import { AuthContext, User } from '../context/AuthContext'
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'
import Loading from './Loading'

interface Props {
  children: ReactNode
}

const AuthProvider = ({ children }: Props): JSX.Element => {
  const {
    user: auth0User,
    isLoading,
    getAccessTokenSilently
  } = useAuth0()

  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  )

  useEffect(() => {
    console.debug('> AuthProvider [isLoading, auth0User]')

    if (isLoading) {
      return
    }

    if (auth0User !== undefined) {
      void (async function () {
        const token = await getAccessTokenSilently()
        // Try a few time because the user profile might not be available yet.
        // This is because function creating the user profile is triggered by
        // the onAuthStateChanged event, so it is racing with that function.
        let userProfile
        for (let i = 0; i < 5; i++) {
          userProfile = await getUserProfile(token)
          if (userProfile !== null) {
            break
          }
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
        if (userProfile === null || userProfile === undefined) {
          console.error('User profile not found')
          return
        }

        setCurrentUser(new User(userProfile, auth0User))
      })()
    }
  }, [isLoading, auth0User])

  if (isLoading) {
    return <Loading />
  }

  if (auth0User === undefined) {
    console.debug('Not authenticated, redirect to /login')
    return <Navigate to="/login" />
  }

  if (currentUser === null) {
    console.debug('User profile not found, redirect to /callback')
    return <Navigate to="/callback" />
  }

  if (currentUser === undefined) {
    return <Loading />
  }

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
