import { useColorMode } from '../context/ColorModeContext'
import { StyledSwitch } from './ToggleColorMode.styles'

export default function ToggleColorMode (): JSX.Element {
  const { colorMode, setColorMode } = useColorMode()

  const toggleColorMode = (): void => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light')
  }

  return <StyledSwitch
    value={colorMode}
    onClick={toggleColorMode}
  />
}
