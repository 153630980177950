import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import type React from 'react'
import './index.css'
import ColorModeContext from './context/ColorModeContext'
import { useEffect, useMemo, useState } from 'react'
import { getTheme } from './theme'
import { getCookies, setCookie } from './services/cookies'
import Root from './Root'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from './services/react-query'
import { QueryClientProvider } from '@tanstack/react-query'
import { IntlProviderWrapper } from 'context/IntlProviderWrapper'

const App: React.FC = () => {
  const cookies = getCookies()
  const storedColorMode: 'light' | 'dark' | undefined = cookies.get('colorMode')
  const [colorMode, setColorMode] = useState<'light' | 'dark'>(storedColorMode ?? 'light')

  const theme = useMemo(
    () => getTheme(colorMode),
    [colorMode]
  )

  // Save color mode to cookies
  useEffect(() => {
    setCookie('colorMode', colorMode)
  }, [colorMode])

  return (
    <IntlProviderWrapper>
      <ColorModeContext.Provider value={{ colorMode, setColorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <Root />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </IntlProviderWrapper>
  )
}

export default App
