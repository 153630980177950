import { Box, styled } from '@mui/material'

export const FormButtonsContainer = styled(Box)(({ theme }) => ({
  flexBasis: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  padding: theme.spacing(1)
}))

export const ModalHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginRight: 0
}))

export const formControlSx = {
  display: 'flex',
  flexDirection: 'column'
}

export const textFieldSx = {
  overflow: 'auto',
  flexBasis: '500px'
}
