import SendIcon from '@mui/icons-material/Send'
import { Tooltip } from '@mui/material'
import type React from 'react'
import { StyledBox, StyledButton } from './SendIconButton.styles'
import SendWithReviewIcon from './SendWithReviewIcon'
import { sendIconStyle } from './SendWithReviewIcon.styles'
import { useIntl } from 'react-intl'

interface SendIconButtonProps {
  mayReview: boolean
  disabled: boolean
}

const SendIconButton: React.FC<SendIconButtonProps> = ({ mayReview, disabled }) => {
  const intl = useIntl()

  const title = (
    mayReview
      ? intl.formatMessage({
        id: 'app.send-with-review',
        defaultMessage: 'Review before sending'
      })
      : intl.formatMessage({
        id: 'app.send-directly',
        defaultMessage: 'Send directly'
      })
  )
  const icon = (
    mayReview
      ? <SendWithReviewIcon disabled={disabled} />
      : <SendIcon style={sendIconStyle} />
  )
  return (
    <Tooltip title={title}>
      <StyledBox className="send-icon-button-container">
        <StyledButton type="submit" disabled={disabled}>
          {icon}
        </StyledButton>
      </StyledBox>
    </Tooltip>
  )
}

export default SendIconButton
