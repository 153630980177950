import { Warning } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material'

interface Props {
  title: string
  errorMsg: string | null
  onClose: () => void
}

/**
 * A dialog to display user-induced errors, with a single OK button to close it.
 */
const UserErrorDialog: React.FC<Props> = ({ title, errorMsg, onClose }: Props) => {
  return <Dialog
    open={errorMsg !== null}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1
      }}
    >
      <Warning color='error' />
      <Typography sx={{ fontSize: '1.2em' }}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" whiteSpace={'pre-line'}>
        {errorMsg}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} autoFocus variant='outlined'>
        OK
      </Button>
    </DialogActions>
  </Dialog>
}

export default UserErrorDialog
