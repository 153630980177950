import { FormControl, FormGroup, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { type DocType } from '../types'
import { useSelectableDocTypes } from '../hooks/useSelectableDocTypes'
import { useIntl } from 'react-intl'

interface Props {
  docType: DocType
  onChange: (docType: string) => void
}

const DocTypeSelector: React.FC<Props> = ({ docType, onChange }: Props) => {
  const intl = useIntl()

  const selectableDocTypes = useSelectableDocTypes()

  if (!selectableDocTypes.includes(docType)) {
    throw new Error(`Invalid docType: ${docType}`)
  }

  const handleChangeDocType = (event: SelectChangeEvent<string>): void => {
    event.preventDefault()
    onChange(event.target.value)
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormGroup>
        <Select
          value={docType}
          onChange={handleChangeDocType}
          fullWidth
        >
          {selectableDocTypes.map((key) => (
            <MenuItem key={key} value={key}>
              {
                intl.formatMessage({
                  id: `app.doc-type.${key}`,
                  defaultMessage: key
                })
              }
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    </FormControl>
  )
}

export default DocTypeSelector
