import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'

export const acceptTerms = async (
  token: string
): Promise<void> => {
  await axiosInstance.post(
    '/users/me/accept-terms',
    {},
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
}
