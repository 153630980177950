import { scrollbarFix } from '../../../theme'
import { Stack, type SxProps, styled, type Theme } from '@mui/material'

export const chatLayoutContainerSx = (windowHeight: number): SxProps<Theme> => ({
  display: 'flex',
  // Take up all the remain space available, once the compose container
  // has taken up the space it needs
  flexGrow: 1,
  ...scrollbarFix
})

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1)
}))
