import { AnalysisTaskAction, SanityCheckOption, type UserInputForm } from 'features/userinputform/types'
import { getEnumKeyByValue } from '../../../services/utils'
import OptionsSelect, { type Option } from './OptionsSelect'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const SanityCheckOptionsSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  return (
    <OptionsSelect
      items={
        Object.values(SanityCheckOption).map((option, idx) => {
          // id 0 is reserved for 'Select all' option
          return { id: idx + 1, name: option }
        })
      }
      label='Select options'
      onChange={(selectedOps) => {
        const sanityCheckOptions: SanityCheckOption[] = selectedOps.map((option: Option) =>
          getEnumKeyByValue(SanityCheckOption, option.name)
        ).filter((option) => option !== undefined) as SanityCheckOption[]
        onChange({
          ...userInputForm,
          taskParams: {
            action: AnalysisTaskAction.SanityCheck,
            sanityCheckOptions
          }
        })
      } }
    />
  )
}

export default SanityCheckOptionsSelect
