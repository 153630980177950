import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import DrawerPaneButton, { DRAWER_WIDTH } from './DrawerPaneButton'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface Props {
  initOpen: boolean
  onChange: (open: boolean) => void
  anchor: 'left' | 'right'
  children: React.ReactNode
  headerChildren?: React.ReactNode
}

/**
 * Renders a side panel that can be opened and closed.
 */
export const DrawerPane: React.FC<Props> = (
  { initOpen, onChange, anchor, children, headerChildren }: Props
) => {
  const [open, setOpen] = React.useState(initOpen)
  const [hovering, setHovering] = React.useState(false)

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setOpen(initOpen)
  }, [initOpen])

  const handleDrawerOpen = (): void => {
    setOpen(true)
    onChange(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
    onChange(false)
  }

  return (
    <Box
      className='drawer-pane-container'
      sx={{
        display: 'flex'
      }}
      onMouseEnter={() => {
        if (!open) return
        if (hoverTimeout.current !== null) clearTimeout(hoverTimeout.current)
        setHovering(true)
      }}
      onMouseLeave={() => {
        if (!open) return
        // If the mouse leaves the drawer, wait a bit before hiding the button
        // to close it in case the user moved their mouse too far
        hoverTimeout.current = setTimeout(() => {
          setHovering(false)
        }, 500)
      }}
    >
      {/* Button to open/close the drawer */}
      <DrawerPaneButton
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        anchor={anchor}
        hovering={hovering}
      />

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor={anchor}
        open={open}
      >
        <DrawerHeader>
          {headerChildren}
        </DrawerHeader>
        {children}
      </Drawer>
    </Box>
  )
}

export default DrawerPane
