import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type Message } from '../types'

export const updateMessage = async (
  token: string,
  user: User,
  sessionId: string,
  idx: number,
  update: Partial<Message>
): Promise<void> => {
  await axiosInstance.patch(
    `/sessions/${sessionId}/messages/${idx}`,
    update,
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
}
