import { Button, Tooltip } from '@mui/material'
import { useCreateSession } from '../api/createSession'
import { StyledBox } from './NewSession.styles'
import { useIntl } from 'react-intl'

const NewSession = (): JSX.Element => {
  const intl = useIntl()
  const { mutate, isError, isPending, error } = useCreateSession()

  const handleClick = (): void => {
    mutate({})
  }

  if (isError) return <p>Error: {error.message}</p>

  const tooltip = (
    isPending
      ? intl.formatMessage({
        id: 'app.new-session.tooltip.loading',
        defaultMessage: 'Loading, please wait'
      })
      : intl.formatMessage({
        id: 'app.new-session.tooltip.create-session',
        defaultMessage: 'Click to create a new session'
      })
  )

  return (
    <Tooltip title={tooltip}>
      <StyledBox>
        <Button
          onClick={handleClick}
          variant="contained"
          color="primary"
          disabled={isPending}
          fullWidth
        >
          {
            isPending
              ? intl.formatMessage({
                id: 'app.new-session.button.loading',
                defaultMessage: 'Loading...'
              })
              : intl.formatMessage({
                id: 'app.new-session.button.new-session',
                defaultMessage: 'New session'
              })
          }
        </Button>
      </StyledBox>
    </Tooltip>
  )
}

export default NewSession
