import { TableCell, styled } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: 'center'
}))

export const filenameCellSx = { width: '300px' }

export const selectorCellSx = {
  width: '200px',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderStyle: 'border-box'
}

export const buttonCellSx = {
  paddingLeft: '0px',
  paddingRight: '0px'
}
