import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type UserSettings } from '../types'

export const getUserSettings = async (
  token: string,
  user: User
): Promise<UserSettings> => {
  const response = await axiosInstance.get<UserSettings>(
    '/users/me/settings',
    {
      headers: createAuthHeaderFromToken(token),
      id: 'get-user-settings',
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}
