import { MenuItem, Select } from '@mui/material'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/userinputform/types'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  contractIsAttached: boolean
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

// Customize the Select component
const AnalysisTaskActionSelect: React.FC<Props> = (
  { userInputForm, contractIsAttached, onChange }: Props
) => {
  const intl = useIntl()

  const renderValue = (value: AnalysisTaskAction | ''): string => (
    value === ''
      ? intl.formatMessage({
        id: 'app.analysis-task-action-select.placeholder',
        defaultMessage: 'Select an action'
      })
      : value
  )

  const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined

  return (
    <Select
      value={taskParams?.action ?? ''}
      displayEmpty
      renderValue={renderValue}
      onChange={(event) => {
        const action = event.target.value as AnalysisTaskAction
        onChange({
          ...userInputForm,
          taskParams: {
            ...userInputForm.taskParams,
            action
          }
        })
      }}
    >
      {/* Show a menu item for each analysis action */}
      {Object.values(AnalysisTaskAction).filter(
        // If contract is attached, allow all actions,
        // otherwise, exclude SpecificAnalysis
        (action) => contractIsAttached || action !== AnalysisTaskAction.SpecificAnalysis
      ).map((action, idx) => (
        <MenuItem key={idx} value={action}>
          {action}
        </MenuItem>
      ))}
    </Select>
  )
}

export default AnalysisTaskActionSelect
