import { Button, styled } from '@mui/material'

export const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  ':hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  variant: 'outlined'
}))
