import { Box, FormControl, styled } from '@mui/material'

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const FormButtonsContainer = styled(Box)(({ theme }) => ({
  minHeight: '5vh',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(1)
}))
