import { Avatar, Button, Tooltip } from '@mui/material'
import { saveAs } from 'file-saver'
import { type Message } from 'features/messages/types'
import docxIcon from 'assets/file-icon-docx.png'
import { useSession } from 'context/SessionContext'
import { generateDocxOutput } from '../api/exportDocx'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'components/Loading'
import { useIntl } from 'react-intl'

interface Props {
  lastAssistantMessage?: Message
}

const ExportDocxButton: React.FC<Props> = ({ lastAssistantMessage }: Props) => {
  const intl = useIntl()
  const { selectedSession } = useSession()
  const { getAccessTokenSilently } = useAuth0()
  const isDisabled = lastAssistantMessage === undefined

  if (selectedSession === null) {
    return <Loading />
  }

  function handleClick (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.preventDefault()

    console.log('lastAssistantMessage: ', lastAssistantMessage)

    if (lastAssistantMessage === undefined) {
      return
    }
    if (selectedSession === null) {
      throw new Error('Selected session is not loaded yet')
    }
    if (lastAssistantMessage.idx === undefined || lastAssistantMessage.idx === null) {
      throw new Error('Message index is undefined or null')
    }
    const messageId = lastAssistantMessage.idx
    void getAccessTokenSilently().then(async (token) =>
      await generateDocxOutput(token, selectedSession.id, messageId)
    ).then(blob => {
      saveAs(blob, 'Copilex Export.docx')
    })
  }

  const tooltip = intl.formatMessage({
    id: 'app.export-docx-button.tooltip',
    defaultMessage: 'Export to Word'
  })

  return <Tooltip title={tooltip} arrow>
    <span>
      <Button
        startIcon={
          <Avatar src={docxIcon} sx={{
            opacity: (isDisabled ? 0.5 : 1.0),
            width: '32px',
            height: '32px'
          }} />}
        disabled={isDisabled}
        onClick={handleClick}
        sx={{
          // Show a grey background on hover to be consistent with the other buttons
          '&:hover': {
            // bgcolor: primaryMainColor,
            backgroundOrigin: 'content-box'
          },
          // Remove the extra right margin to center the icon
          '.MuiButton-startIcon': {
            m: 0
          }
        }}
      />
    </span>
  </Tooltip>
}

export default ExportDocxButton
