import { Divider, List, type SxProps, type Theme, Typography } from '@mui/material'

interface Props {
  title: string
  children?: React.ReactNode
  sx?: SxProps<Theme>
}

/**
 * Creates a section in a pane, with a title and children elements.
 */
const PaneSection: React.FC<Props> = ({ title, children, sx }: Props) => {
  return <List
    aria-labelledby="section-subheader"
    subheader={
      <Typography
        component="div"
        id="section-subheader"
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {title}
      </Typography>
    }
    sx={{
      ...(sx ?? {})
    }}
  >
    <Divider sx={{ mb: 1 }} />
    {children}
  </List>
}

export default PaneSection
