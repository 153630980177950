import gatewaiLogoBlack from '../../../assets/logo.png'
import gatewaiLogoWhite from '../../../assets/logo-white.png'
import openaiLogo from '../../../assets/openai-logo.png'
import mistralLogo from '../../../assets/mistral-logo.jpeg'
import anthropicLogo from '../../../assets/anthropic-logo.png'
import { useColorMode } from '../../../context/ColorModeContext'
import { Avatar } from '@mui/material'
import { type AssistantSettings } from 'features/assistants/types'
import { useIntl } from 'react-intl'

interface Props {
  settings?: AssistantSettings
}

const AIAvatar: React.FC<Props> = ({ settings }: Props) => {
  const intl = useIntl()
  const { colorMode } = useColorMode()

  // FIXME: should refactor the msg settings to contain an assistant id,
  // and not expose the agentType and modelName
  let src
  if (settings !== undefined) {
    if (settings.agentType.startsWith('copilex') || settings.agentType === 'master agent') {
      src = colorMode === 'dark' ? gatewaiLogoWhite : gatewaiLogoBlack
    } else {
      if (settings.modelName.startsWith('gpt-')) {
        src = openaiLogo
      } else if (settings.modelName.startsWith('Mistral')) {
        src = mistralLogo
      } else if (settings.modelName.startsWith('Claude')) {
        src = anthropicLogo
      }
    }
  }

  return <Avatar src={src}>
    {
      intl.formatMessage({
        id: 'app.ai-avatar.label',
        defaultMessage: 'AI'
      })
    }
  </Avatar>
}

export default AIAvatar
