import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material'
import Loading from 'components/Loading'
import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { type Globals } from 'features/globals/types'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import { type Session } from 'features/sessions/types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export const getAssistantId = (session: Session, globals: Globals): string => (
  session.settings?.assistantId ?? globals.defaultAssistantId
)

export const AssistantSelect: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const globals = useGlobals() as Globals
  const { selectedSession: session } = useSession()
  const updateSessionMutation = useUpdateSession()
  // const { userSettings, updateUserSettings } = useUserSettings()
  const [assistantId, setAssistantId] = useState<string | null>(null)

  useEffect(() => {
    console.debug('AssistantSelect > [globals, session, userSettings]')

    if (globals === null || session === null) return

    // Use session-specific assistant if available,
    // otherwise use the default assistant from user settings if available,
    // otherwise use the default assistant from globals.
    const newAssistantId = getAssistantId(session, globals)
    setAssistantId(newAssistantId)
  }, [globals, session])

  const handleChange = (event: SelectChangeEvent<string>): void => {
    if (session === null) throw new Error('Session not set!')

    const selectedAssistant = globals.assistantsById.get(event.target.value)
    if (selectedAssistant === undefined) {
      throw new Error(`Unknown assistant ID: ${event.target.value}`)
    }

    const sessionUpdate = { settings: { ...session.settings, assistantId: selectedAssistant.id } }
    // updateSession(sessionUpdate)
    updateSessionMutation.mutate({ sessionId: session.id, update: sessionUpdate })

    // setAssistantId(selectedAssistant.id)
  }

  if (assistantId === null) {
    return <Loading />
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel
          id="model-select-label"
          style={{
            // Use the theme's text color to increase the contrast.
            // (Default is grayish)
            color: theme.palette.text.primary,
            // For some reason, the theme doesn't set the background color
            // to avoid having the outline strike over the text.
            // We fix this by setting the background color explicitly,
            // and also adding some padding to make it look like the
            // other input labels.
            backgroundColor: theme.palette.background.paper,
            padding: '0 5px'
          }}
        >
          {
            intl.formatMessage({
              id: 'app.assistant-select.label',
              defaultMessage: 'Assistant'
            })
          }
        </InputLabel>
        <Select
          labelId='model-select-label'
          id='model-select'
          value={assistantId}
          onChange={handleChange}
          sx={{ width: '250px' }}
          aria-label="Model"
        >
          {Array.from(globals.assistantsById).map(([assistantId, assistant], idx) => (
            <MenuItem key={idx} value={assistantId}>
              {assistant.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default AssistantSelect
