import { IconButton, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from '@mui/material'
import { useUserSettings } from '../context/UserSettingsContext'
import { useGlobals } from '../context/GlobalsContext'
import { listUserSpecificRules } from 'features/anonymization/api/listUserSpecificRules'
import { type EntityTypeInfo, type EntityType, type Entity, type AnonymizationSettings, type EntityTypeRule, type AliasRule, type RuleKey } from 'features/anonymization/types'
import { type Globals } from 'features/globals/types'
import ReviewStateItemRow from './review-modal/side-pane/ReviewStateItemRow'
import { useEffect, useState } from 'react'
import { aliasOf, createEntityTypeToActiveEntitiesCleartext, findAliasItems, type ReviewStateItem } from '../services/reviewState'
import { type User, useAuth } from '../context/AuthContext'
import AddEntityForm from './review-modal/side-pane/AddEntityForm'
import EntityTypeRuleSwitch from './review-modal/switches/EntityTypeRuleSwitch'
import ExpandMore from '@mui/icons-material/ExpandMore'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { GlobalRulesPaneContainer, StyleTableContainer, TabContainer } from './GlobalRulesPane.styles'
import { useAuth0 } from '@auth0/auth0-react'
import { useIntl } from 'react-intl'

const createEntityTypeRuleMap = (entityTypesRules: EntityTypeRule[]): Map<EntityType, boolean> => {
  const entityTypesRulesMap = new Map()
  entityTypesRules.forEach((entityTypeRule) => {
    entityTypesRulesMap.set(entityTypeRule.entityType, entityTypeRule.enabled)
  })
  return entityTypesRulesMap
}

interface Props {
  anonymizationSettings: AnonymizationSettings
  existingEntitiesWithAliases: Entity[]
  onChange: (prevItem: ReviewStateItem, item: ReviewStateItem, newItem?: ReviewStateItem) => void
  onChangeAliasRule: (aliasRule: AliasRule) => void
  onDeleteAnonRule: (key: RuleKey) => void
  onChangeAnonymizationSettings: (anonymizationSettings: AnonymizationSettings) => void
  onAddExactMatchAsGlobalRule: (
    cleartext: string,
    entityType: EntityType,
    asAliasOf: string | undefined
  ) => void
}

/**
 * Pane for settings global rules, i.e., which entity types should be anonymized
 * by the anonymizer service (outside specific rules, which always apply).
 */
const GlobalRulesPane = ({
  anonymizationSettings,
  existingEntitiesWithAliases,
  onChange,
  onChangeAliasRule,
  onDeleteAnonRule,
  onChangeAnonymizationSettings,
  onAddExactMatchAsGlobalRule
}: Props): JSX.Element => {
  const intl = useIntl()
  const userSettings = useUserSettings()
  const globals = useGlobals() as Globals
  const currentUser = useAuth() as User
  const { getAccessTokenSilently } = useAuth0()

  const [tabsValue, setTabsValue] = useState<number>(0)
  const [items, setItems] = useState<ReviewStateItem[]>([])
  const [openedGroups, setOpenedGroups] = useState<boolean[]>(
    Array.from(globals.entityTypesByGroup).map(() => true)
  )

  useEffect(() => {
    void getAccessTokenSilently().then(async (token) =>
      await listUserSpecificRules(
        token,
        anonymizationSettings,
        existingEntitiesWithAliases,
        currentUser
      )
    ).then((items) => {
      setItems(items)
    })
  }, [anonymizationSettings])

  const entityTypesRulesMap: Map<EntityType, boolean> = (
    anonymizationSettings.entityTypesRules.length > 0
      ? createEntityTypeRuleMap(anonymizationSettings.entityTypesRules)
      : globals.defaultEntityTypesRulesMap
  )

  // Create a map from each entity group to its items, sorted appropriately.
  // Also filter out aliases, because they will be handled by their parent.

  // First, create a map from each entity type to its group
  const entityTypeToGroup = new Map<EntityType, string>()
  Array.from(globals.entityTypesByGroup).forEach(([group, entityTypeInfos]) => {
    entityTypeInfos.forEach((entityTypeInfo) => {
      entityTypeToGroup.set(entityTypeInfo.entityType, group)
    })
  })
  const groupToItems = new Map<string, ReviewStateItem[]>()
  items.forEach((item) => {
    const group = entityTypeToGroup.get(item.entityType)
    if (group === undefined) {
      return
    }
    if (aliasOf(item, items) !== null) {
      return
    }
    if (groupToItems.has(group)) {
      groupToItems.get(group)?.push(item)
    } else {
      groupToItems.set(group, [item])
    }
  })
  groupToItems.forEach((items) => {
    // Sort items by (case-insensitive) cleartext then entity type
    items.sort((item1, item2) => {
      const cleartext1 = item1.cleartext.toLowerCase()
      const cleartext2 = item2.cleartext.toLowerCase()
      if (cleartext1 < cleartext2) {
        return -1
      } else if (cleartext1 > cleartext2) {
        return 1
      } else {
        return item1.entityType < item2.entityType ? -1 : 1
      }
    })
  })

  const handleChange = (entityTypeInfo: EntityTypeInfo, checked: boolean): void => {
    const newEntityTypesRulesMap = new Map(entityTypesRulesMap)
    newEntityTypesRulesMap.set(entityTypeInfo.entityType, checked)
    const newEntityTypesRules = Array.from(newEntityTypesRulesMap).map(([entityType, enabled]) => ({
      entityType,
      enabled
    }))
    onChangeAnonymizationSettings({
      ...userSettings.anonymizationSettings,
      entityTypesRules: newEntityTypesRules
    })
  }

  const entityTypeToActiveEntitiesCleartext = createEntityTypeToActiveEntitiesCleartext(items)

  const toggleOpenedGroup = (idx: number): void => {
    const newOpenedGroups = [...openedGroups]
    newOpenedGroups[idx] = !openedGroups[idx]
    setOpenedGroups(newOpenedGroups)
  }

  const aiGeneralRulesTabLabel = intl.formatMessage({
    id: 'app.global-rules-pane.ai-general-rules-tab.label',
    defaultMessage: 'AI General Rules'
  })
  const userSpecificRulesTab = intl.formatMessage({
    id: 'app.global-rules-pane.user-specific-rules-tab.label',
    defaultMessage: 'User specific rules'
  })

  return (
    <GlobalRulesPaneContainer>
      <Tabs
        value={tabsValue}
        onChange={(event, value) => { setTabsValue(value) }}
        aria-label="left-pane-tabs"
      >
        <Tab label={aiGeneralRulesTabLabel} />
        <Tab label={userSpecificRulesTab} />
      </Tabs>

      {/* Entity type rules */}
      {tabsValue === 0 && (
        <TabContainer
          className='entity-type-rules-pane'
          role='tabpanel'
          id={'tabpanel-entity-type-rules'}
          aria-labelledby={'entity-type-rules-tab'}
        >
          <StyleTableContainer component={Paper}>
            <Table aria-label="entity-type-rules-table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {
                      intl.formatMessage({
                        id: 'app.global-rules-pane.entity-type-table-header.entity-type',
                        defaultMessage: 'Entity type'
                      })
                    }
                  </TableCell>
                  <TableCell>
                    {
                      intl.formatMessage({
                        id: 'app.global-rules-pane.entity-type-table-header.enabled',
                        defaultMessage: 'Enabled'
                      })
                    }
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => [
                  <TableRow key={idx + 1}>
                    <TableCell colSpan={2}>
                      {/* Button to fold / un-fold the group */}
                      <IconButton
                        aria-label="expand"
                        size="small"
                        onClick={() => { toggleOpenedGroup(idx) }}
                      >
                        {openedGroups[idx] ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                      </IconButton>
                      <b>
                        {
                          intl.formatMessage({
                            id: `app.entity-group.${group}`,
                            defaultMessage: group
                          })
                        }
                      </b>
                    </TableCell>
                  </TableRow>,
                  (openedGroups[idx]
                    ? [...entityTypeInfos.map((entityTypeInfo, idx2) => (
                        <TableRow key={(idx + 1) * 100 + idx2}>
                          <TableCell>
                            {
                              intl.formatMessage({
                                id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                                defaultMessage: entityTypeInfo.entityType
                              }) + ' (' +
                              intl.formatMessage({
                                id: `app.entity-type.${entityTypeInfo.entityType}.type`,
                                defaultMessage: entityTypeInfo.entityType
                              }) + ')'
                            }
                          </TableCell>
                          <TableCell>
                            <EntityTypeRuleSwitch
                              active={entityTypesRulesMap.get(entityTypeInfo.entityType) ?? false}
                              entityType={entityTypeInfo.entityType}
                              onChangeActive={(active) => { handleChange(entityTypeInfo, active) } }
                            />
                          </TableCell>
                        </TableRow>
                      ))]
                    : []
                  )
                ])}
              </TableBody>
            </Table>
          </StyleTableContainer>
        </TabContainer>
      )}

      {/* User specific rules */}
      {tabsValue === 1 && (
        <TabContainer
          className='user-specific-rules-pane'
          role='tabpanel'
          id={'tabpanel-user-specific-rules'}
          aria-labelledby={'user-specific-rules-tab}'}
        >
          <StyleTableContainer component={Paper}>
            <Table aria-label="user-specific-rules-table" stickyHeader>
              <TableHead>
                <AddEntityForm
                  scope='global'
                  existingEntities={items.map(({ cleartext }) => cleartext)}
                  onAddAnonRule={onAddExactMatchAsGlobalRule}
                />
              </TableHead>
              <TableBody>
                {Array.from(globals.entityTypesByGroup).map(([group, _], idx) => {
                  const rows = [
                    <TableRow key={idx + 1}>
                      <TableCell colSpan={3}>
                        {/* Button to fold / un-fold the group */}
                        <IconButton
                          aria-label="expand"
                          size="small"
                          onClick={() => {
                            const newOpenedGroups = [...openedGroups]
                            newOpenedGroups[idx] = !openedGroups[idx]
                            setOpenedGroups(newOpenedGroups)
                          }}
                        >
                          {openedGroups[idx] ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                        <b>
                          {
                            intl.formatMessage({
                              id: `app.entity-group.${group}`,
                              defaultMessage: group
                            })
                          }
                        </b>
                      </TableCell>
                    </TableRow>
                  ]
                  if (openedGroups[idx]) {
                    const groupItems = groupToItems.get(group)
                    if (groupItems !== undefined) {
                      rows.push(
                        ...groupItems.map((item, idx2) => (
                          <ReviewStateItemRow
                            key={(idx + 1) * 100 + idx2}
                            item={item}
                            items={items}
                            aliasItems={findAliasItems(item, items)}
                            entityTypeToActiveEntitiesCleartext={entityTypeToActiveEntitiesCleartext}
                            scope='global'
                            onChange={onChange}
                            onChangeAliasRule={onChangeAliasRule}
                            onDeleteAnonRule={onDeleteAnonRule}
                          />
                        ))
                      )
                    }
                  }
                  return rows
                })}

              </TableBody>
            </Table>
          </StyleTableContainer>
        </TabContainer>
      )}
    </GlobalRulesPaneContainer>
  )
}

export default GlobalRulesPane
