import { IconButton, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0,
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}))
