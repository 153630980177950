import { Box, Tooltip, Typography } from '@mui/material'
import { FilePresent } from '@mui/icons-material'
import { StyledTypography } from './FilenameWithIcon.styles'

interface Props {
  filename: string
}

/**
 * Display a filename with an icon representing the file type.
 */
const FilenameWithIcon: React.FC<Props> = ({ filename }: Props) => {
  const extension = filename.split('.').pop()
  if (extension === undefined) {
    return <Typography>{filename}</Typography>
  }

  const iconForExtension = (ext: string): JSX.Element => {
    // Check if we have a specific icon for the file extension
    // If not, use a generic file icon
    // Icon files are assets/file-icon-<ext>.png
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const src = require(`assets/file-icon-${ext}.png`)
      return <img src={src} width={32} height={32} />
    } catch {
      // Make it roughly the same size as the image-based icons.
      // Did not find a way to specify the size in pixels for the Material UI icons.
      return <FilePresent style={{ fontSize: 35 }} />
    }
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {iconForExtension(extension)}
      <Tooltip title={filename}>
        <StyledTypography>
          {filename}
        </StyledTypography>
      </Tooltip>
    </Box>
  )
}

export default FilenameWithIcon
