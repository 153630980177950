import { Box, styled, type SxProps, type Theme } from '@mui/material'
import { type Message, Role } from 'features/messages/types'

export const containerSx = (msg: Message): SxProps<Theme> => ({
  display: 'flex',
  // When screen is too small, show the avatar on top of the message content.
  flexDirection: { xs: 'column', sm: 'row' },
  padding: { xs: 1, sm: 1, md: 2 },
  gap: 2, // To space out the avatar and the message content
  borderRadius: 0,
  bgcolor: 'background.paper',
  textAlign: 'left',
  width: { xs: '100%', sm: '95%' },
  alignSelf: (msg.role === Role.USER ? 'flex-start' : 'flex-end'),
  alignItems: 'flex-start'
})

export const MessageContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    alignSelf: 'flex-start'
  },
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center'
  },
  gap: theme.spacing(1)
}))

export const FeedbackButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start'
}))

export const AvatarContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    scale: '0.5'
  },
  [theme.breakpoints.up('sm')]: {
    scale: '0.75'
  },
  [theme.breakpoints.up('md')]: {
    scale: '1'
  }
}))

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

export const BottomSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5)
}))

export const Spacer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))
