import { SvgIcon, type SvgIconProps } from '@mui/material'

export const MagnetIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} >
    <svg viewBox="0 0 256 256">
      <path d="M116.4,10.6c-7.6,0.9-14.7,2.4-22.3,4.9C50,29.9,19.4,71.7,19.3,117.5c0,6,5.7,73.8,6.5,77.9l0.2,1.4h29.5c22.4,0,29.5-0.2,29.5-0.7c0-0.4-1.5-17.9-3.4-38.9c-2.5-27.8-3.3-39.6-3.1-43.7c1-17.6,11.2-32.7,27.1-40.3c7.1-3.4,11.2-4.4,20.5-4.7c6.9-0.3,9-0.1,14,1c21.3,4.9,36.2,22.4,37.5,44c0.2,3.9-0.6,16.2-2.8,42.1c-1.7,20.1-3.2,37.6-3.4,38.9l-0.2,2.3H201c16.3,0,29.8-0.2,29.8-0.4c0-0.2,1.4-16.7,3.1-36.5c4-45.7,3.9-52.6-1-70.4c-8-29.4-29.3-55-57.2-68.5C157.5,12.2,136,8.4,116.4,10.6z"/>
      <path d="M27.9,218.4c0,2.1,1.7,20.8,2.2,25l0.3,2.6h29.5h29.4v-1.7c0-1.8-1.5-18.6-2.2-24.6l-0.4-3.2H57.3H27.9V218.4z"/>
      <path d="M169.6,217.2c-0.3,1-2.2,24.8-2.2,27v1.7h29.4h29.5l0.3-3.3c0.5-4.2,2.2-24.2,2.2-25.3c0-0.8-4.1-0.9-29.5-0.9C176.3,216.5,169.8,216.7,169.6,217.2z"/>
    </svg>
  </SvgIcon>
)
