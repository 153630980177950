import { type AxiosError } from 'axios'
import { type UUID } from 'crypto'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL, handleAPIError } from 'services/axios'
import { type UserProfile } from '../types'
import { getUserProfile } from './getUserProfile'

export const getOrCreateUserProfile = async (token: string): Promise<UserProfile | null> => {
  // Try first to get it, if it exists
  const existingUserProfile = await getUserProfile(token)
  if (existingUserProfile !== null) return existingUserProfile

  // If the user profile does not exist, create it
  try {
    await axiosInstance.post<PostUsersResponse>(
      '/users',
      {},
      {
        headers: createAuthHeaderFromToken(token),
        cache: {
          ttl: defaultTTL
        }
      }
    )
  } catch (err: unknown) {
    const error = err as AxiosError
    if (error.response === undefined) throw error
    throw error
  }

  // Get the newly user profile to have the full data
  return await getUserProfile(token)
}

export interface PostUsersResponse {
  userId: UUID
}

export const createUserProfile = async (
  token: string,
  email: string,
  firstName: string,
  lastName: string,
  orgName: string | null,
  title: string | null,
  role: string | null,
  regToken: string | null
): Promise<UserProfile> => {
  const existingUserProfile = await getUserProfile(token)
  if (existingUserProfile !== null) return existingUserProfile

  if (regToken === null) {
    throw new Error('A registration token is required to signup')
  }

  const response = await axiosInstance.post<PostUsersResponse>(
    '/users',
    {
      email,
      firstName,
      lastName,
      orgName,
      title,
      role,
      regToken
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  ).catch(handleAPIError)

  return {
    id: response.data.userId,
    email,
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    orgName,
    title,
    role,
    termsAccepted: false
  }
}
