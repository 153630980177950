import { Box, Typography, styled } from '@mui/material'

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
  maxWidth: '350px'
}))

export const FilenameTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const DocTypeTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  flexShrink: 0
}))
