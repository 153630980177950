import {
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material'
import AttachmentItem from './AttachmentItem'
import { StyledTableCell, filenameCellSx, selectorCellSx } from './UploadsTable.styles'
import { type AttachmentOrUploadListItem, type Limits } from '../types'
import { useIntl } from 'react-intl'

interface Props {
  attachmentsAndUploads: AttachmentOrUploadListItem[] | undefined
  limits: Limits
}

const UploadsTable: React.FC<Props> = ({
  attachmentsAndUploads,
  limits
}: Props) => {
  const intl = useIntl()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell sx={filenameCellSx}>
            {
              intl.formatMessage({
                id: 'app.uploads-table.doc-title-header',
                defaultMessage: 'Doc title'
              })
            }
          </StyledTableCell>
          <StyledTableCell sx={selectorCellSx}>
            {
              intl.formatMessage({
                id: 'app.uploads-table.doc-type-header',
                defaultMessage: 'Doc type{br}(soon automatic)'
              }, {
                br: <br />
              })
            }
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {attachmentsAndUploads?.map((a, index) =>
          <AttachmentItem
            key={index}
            attachmentOrUpload={a}
            limits={limits}
          />
        )}
      </TableBody>
    </Table>
  )
}

export default UploadsTable
