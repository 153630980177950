import { type AnonymizationSettings } from '../types'

import { type User } from 'context/AuthContext'
import { type ConfidentialityLevel } from 'features/globals'
import { createAuthHeaderFromToken, axiosInstance, defaultTTL } from 'services/axios'

interface IsSensitiveResponse {
  sensitive: boolean
}

export const mustReview = async (
  token: string,
  text: string,
  attachmentIds: string[],
  confidentialityLevel: ConfidentialityLevel,
  anonymizationSettings: AnonymizationSettings,
  user: User
): Promise<boolean> => {
  // Depending on the user's confidentiality level,
  // we may need to review the text
  if (confidentialityLevel.neverReview) return false
  if (confidentialityLevel.alwaysReview) return true

  // Call the API to determine whether the text must be reviewed
  const authHeader = createAuthHeaderFromToken(token)
  const { data } = await axiosInstance.post<IsSensitiveResponse>(
    '/check-sensitive',
    {
      text,
      attachmentIds,
      settings: anonymizationSettings
    },
    {
      headers: authHeader,
      id: 'check-sensitive',
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return data.sensitive

  // // TODO: try using caching and call anonymize instead
  // console.log('mustReview', {
  //   text,
  //   attachmentIds,
  //   settings: userSettings.anonymizationSettings
  // })
  // const response = await axiosInstance.post<AnonymizeResponse>(
  //   '/anonymize',
  //   {
  //     text,
  //     attachmentIds,
  //     settings: userSettings.anonymizationSettings
  //   },
  //   { headers: authHeader, id: 'anonymize' }
  // )
  // return response.data.matchedTags.length > 0
}
