import {
  Box,
  Button,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import type React from 'react'
import { useState } from 'react'
import { formControlSx } from './TermsForm.styles'
import { useIntl } from 'react-intl'

interface Props {
  onAccept: () => void
}

export const TermsForm: React.FC<Props> = ({ onAccept }) => {
  const intl = useIntl()
  const [accepted, setAccepted] = useState<boolean>(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    if (!accepted) throw new Error('Terms not accepted!')

    event.preventDefault()
    onAccept()
  }

  const editForm = (
    <form onSubmit={handleSubmit}>
      <Box id={'terms-form-form-container'} sx={formControlSx}>
        <FormControl>
          <FormControlLabel
            control={<Checkbox
              id="terms-forms-checkbox"
              value={accepted}
              onChange={e => { setAccepted(e.target.checked) }}
            />}
            label={
              intl.formatMessage({
                id: 'app.terms-form.checkbox.label',
                defaultMessage: 'I understand, and fully accept, the above terms of use.'
              })
            }
          />
        </FormControl>

        {/* Buttons */}
        <Button
          type="submit"
          variant="contained"
          disabled={!accepted}
        >
          {
            intl.formatMessage({
              id: 'app.terms-form.submit-button.label',
              defaultMessage: 'Confirm'
            })
          }
        </Button>
      </Box>
    </form>
  )

  const preamble = intl.formatMessage({
    id: 'app.terms-form.preamble',
    defaultMessage: (
      'You are about to access a Beta version of Copilex, Gatewai&apos;s Legal-AI assistant.{br}' +
      'Before you start, please read and accept the following terms of use.{br}' +
      'By using this Beta version, you understand and agree that:'
    )
  }, { br: <br /> })

  const term1 = intl.formatMessage({
    id: 'app.terms-form.term1',
    defaultMessage: (
      'At this early stage of development, Gatewai cannot yet fully guarantee the quality ' +
      'or reliability of the results generated by the assistant. ' +
      'Users are advised, and agree, that any decisions adopted based on these results ' +
      'is on their own account, at their own risk, and that they remain solely responsible ' +
      'for the consultation, choice, use and interpretation of the information provided by the assistant.'
    )
  })

  const term2 = intl.formatMessage({
    id: 'app.terms-form.term2',
    defaultMessage: (
      'While we are dedicating huge efforts to building the most secured and confidential solution ' +
      'of the market, we cannot yet fully guarantee the absolute confidentiality of the ' +
      'information shared by the User. ' +
      'Users are advised to use the Assistant carefully and to share only non confidential information, ' +
      'in accordance with their employers&apos; instructions.'
    )
  })

  const term3 = intl.formatMessage({
    id: 'app.terms-form.term3',
    defaultMessage: (
      'For technical reasons, all input and information entered into the assistant by Users ' +
      'may be accessible to the Gatewai team, with Users consent, ' +
      'for internal use and improvement purposes only.'
    )
  })

  const term4 = intl.formatMessage({
    id: 'app.terms-form.term4',
    defaultMessage: (
      'As this is a pre-commercialization version of the assistant, ' +
      'no link to or screenshot of the application shall be shared with ' +
      'third parties without prior explicit consent from the Gatewai team.'
    )
  })

  return (
    <Box id={'terms-form-container'}>
      <Typography id="terms-form-body" variant="body1" component={'div'}>
        {preamble}<br />
        <ul>
          <li>{term1}</li>
          <li>{term2}</li>
          <li>{term3}</li>
          <li>{term4}</li>
        </ul>
      </Typography>
      {editForm}
    </Box>
  )
}

export default TermsForm
