import AdjustIcon from '@mui/icons-material/Adjust'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { useEffect, useState, type FC } from 'react'
import { type ReviewStateItem, PosAnonymizeStatus } from '../../../services/reviewState'
import { toggleButtonSx } from './RuleTripleSwitch'
import { useIntl } from 'react-intl'

interface Props {
  item: ReviewStateItem
  onChange?: (value: PosAnonymizeStatus) => void
}

/**
 * Switch controlling the application of message-specific rules
 * to all/none occurrences of an entity in the text.
 */
const ActiveTripleSwitch: FC<Props> = ({ item, onChange }: Props) => {
  const intl = useIntl()
  const [value, setValue] = useState<PosAnonymizeStatus>(item.posAnonymizeStatus)

  useEffect(() => {
    setValue(item.posAnonymizeStatus)
  }, [item])

  const noneButtonTooltip = (
    value === PosAnonymizeStatus.NONE
      ? intl.formatMessage({
        id: 'app.active-triple-switch.none-button.disabled-tooltip',
        defaultMessage: 'No tags for this entity'
      })
      : intl.formatMessage({
        id: 'app.active-triple-switch.none-button.enabled-tooltip',
        defaultMessage: 'Click to remove all tags for this entity (only for this message)'
      })
  )

  const someButtonTooltip = (
    'Some occurrences of this entity are anonymized, but not all of them'
  )

  const allButtonTooltip = (
    value === PosAnonymizeStatus.ALL
      ? intl.formatMessage({
        id: 'app.active-triple-switch.all-button.enabled-tooltip',
        defaultMessage: 'All occurrences of this entity are anonymized'
      })
      : intl.formatMessage({
        id: 'app.active-triple-switch.all-button.disabled-tooltip',
        defaultMessage: 'Click to add tags for all occurrences not already tagged (only for this message)'
      })
  )

  return (
    <ToggleButtonGroup
      className='active-triple-switch'
      value={value}
      exclusive
      size='small'
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue)
          onChange?.(newValue)
        }
      }}
      aria-label='active switch'
    >
      {/* "None" button */}
      <ToggleButton
        className='active-triple-switch-none-button'
        value={PosAnonymizeStatus.NONE}
        aria-label={PosAnonymizeStatus.NONE.toString()}
        sx={toggleButtonSx('red')}
      >
        <Tooltip title={noneButtonTooltip}>
          <CancelIcon />
        </Tooltip>
      </ToggleButton>

      {/* "Some" button */}
      <ToggleButton
        className='active-triple-switch-some-button'
        disabled
        value={PosAnonymizeStatus.SOME}
        aria-label={PosAnonymizeStatus.SOME.toString()}
        sx={{
          ...toggleButtonSx('orange'),
          // Makes the tooltip visible when the switch is in this state,
          // even though the button is disabled
          ...(value === PosAnonymizeStatus.SOME && {
            '&.Mui-disabled': {
              pointerEvents: 'auto'
            }
          })
        }}
      >
        <Tooltip title={someButtonTooltip}>
          <AdjustIcon />
        </Tooltip>
      </ToggleButton>

      {/* "All" button */}
      <ToggleButton
        className='active-triple-switch-all-button'
        value={PosAnonymizeStatus.ALL}
        aria-label={PosAnonymizeStatus.ALL.toString()}
        sx={toggleButtonSx('lightgreen')}
      >
        <Tooltip title={allButtonTooltip}>
          <CheckCircleIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ActiveTripleSwitch
