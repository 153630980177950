import { useEffect, useRef, useState } from 'react'
import { TextField, Checkbox, type FilterOptionsState, type SxProps, type Theme } from '@mui/material'
import Autocomplete, { type AutocompleteChangeReason, createFilterOptions, type AutocompleteRenderOptionState } from '@mui/material/Autocomplete'
import { optionsSelectSx } from './OptionsSelect.styles'
import { useIntl } from 'react-intl'

export interface Option {
  id: number
  name: string
}

interface Props {
  items: Option[]
  label: string
  onChange: (selectedOptions: Option[]) => void
  limitTags?: number
  sx?: SxProps<Theme>
}

const OptionsSelect: React.FC<Props> = (
  { items, label, onChange, limitTags }: Props
) => {
  const intl = useIntl()
  const windowWidth = useRef<number>(0)

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([])
  const [defaultLimitTags, setDefaultLimitTags] = useState<number>(1)

  useEffect(() => {
    const onResize = (): void => {
      windowWidth.current = window.innerWidth
      // Default limit on tags displayed will depend on the screen width,
      // to avoid overflowing the screen.
      setDefaultLimitTags(
        windowWidth.current < 1600
          ? 1
          : windowWidth.current < 1900
            ? 2
            : 3
      )
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const allSelected = items.length === selectedOptions.length

  const selectAllLabel = intl.formatMessage({
    id: 'app.select-all',
    defaultMessage: 'Select all'
  })

  const handleToggleOption = (selectedOps: Option[]): void => { setSelectedOptions(selectedOps) }
  const handleClearOptions = (): void => { setSelectedOptions([]) }

  const handleSelectAll = (isSelected: boolean): void => {
    if (isSelected) {
      setSelectedOptions(items)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = (): void => {
    handleSelectAll?.(!allSelected)
  }

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedOps: Option[],
    reason: AutocompleteChangeReason
  ): void => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOps.find((option) => option.name === selectAllLabel) != null) {
        handleToggleSelectAll()
        const result = items.filter((el) => el.name !== selectAllLabel)
        onChange(result)
      } else {
        handleToggleOption?.(selectedOps)
        onChange(selectedOps)
      }
    } else if (reason === 'clear') {
      handleClearOptions?.()
    }
  }

  const filter = createFilterOptions<Option>()

  const filterOptions = (
    options: Option[],
    params: FilterOptionsState<Option>
  ): Option[] => {
    const filtered = filter(options, params)
    return [{ id: 0, name: selectAllLabel }, ...filtered]
  }

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    { selected }: AutocompleteRenderOptionState
  ): React.ReactNode => {
    // To control the state of 'select-all' checkbox
    const selectAllProps =
      option.name === selectAllLabel ? { checked: allSelected } : {}
    return (
      <li {...props}>
        <Checkbox checked={selected} {...selectAllProps} />
        {option.name}
      </li>
    )
  }

  return (
    <Autocomplete
      id='select-options'
      size='small'
      multiple
      disableCloseOnSelect
      options={items}
      value={selectedOptions}
      getOptionLabel={(option: Option) => option.name}
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      // Limit the number of tags that can be displayed,
      // and show the rest as a "+n" string.
      limitTags={limitTags ?? defaultLimitTags}
      sx={optionsSelectSx}
    />
  )
}

export default OptionsSelect
