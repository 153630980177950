import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'components/Loading'

/**
 * Forces a logout and redirects to the login page
 */
const LogoutPage = (): JSX.Element => {
  const { logout } = useAuth0()

  useEffect(() => {
    // The URL must be in the Allowed Logout URLs configured
    // for the application in the Auth0 dashboard.
    const logoutURL = window.location.origin + '/login'
    void logout({ logoutParams: { returnTo: logoutURL } })
  }, [])

  return <Loading />
}

export default LogoutPage
