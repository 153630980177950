import { type User } from 'context/AuthContext'
import { createAuthHeaderFromToken, axiosInstance, defaultTTL } from 'services/axios'

interface WhitelistedEmailResponse {
  registrationToken: string
}

export const whitelistEmail = async (
  token: string,
  email: string,
  user: User
): Promise<WhitelistedEmailResponse> => {
  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.post(
    '/admin/whitelisted-emails',
    { email },
    {
      headers: authHeader,
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}
