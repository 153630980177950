import SaveIcon from '@mui/icons-material/Save'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { Box, Button, CircularProgress, FormControl, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { useState, type FormEvent } from 'react'
import { ModalContainer } from '../../../theme'
import CancelButton from '../../buttons/CancelButton'
import { EditButton } from '../../buttons/ThumbsButtons'
import { useIntl } from 'react-intl'

interface Props {
  initCorrection: string
  previouslyEdited: boolean
  onSubmit: (correction: string) => void
}

const CorrectionModal = ({
  initCorrection,
  previouslyEdited,
  onSubmit
}: Props): JSX.Element => {
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [correction, setCorrection] = useState<string>(initCorrection)
  const [correctionEdited, setCorrectionEdited] = useState<boolean>(false)

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    onSubmit(correction)
    setOpen(false)
  }

  const resetTooltip = intl.formatMessage({
    id: 'app.reset-correction.tooltip',
    defaultMessage: 'Reset correction to previous one or original text'
  })

  const cancelTooltip = intl.formatMessage({
    id: 'app.cancel-correction.tooltip',
    defaultMessage: 'Close without saving the correction'
  })

  const submitTooltip = intl.formatMessage({
    id: 'app.submit-correction.tooltip',
    defaultMessage: 'Save correction for later improvement of the system'
  })

  const editForm = (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 1
          }}
        >
          <TextField
            sx={{
              flexBasis: '100%'
            }}
            multiline
            minRows={5}
            maxRows={15}
            id="correction-input"
            name="correction-input"
            variant="outlined"
            value={correction}
            onChange={(event) => {
              setCorrection(event.target.value)
              setCorrectionEdited(true)
            }}
          />

          <Box
            sx={{
              flex: 0,
              padding: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 1
            }}
          >
            <Tooltip title={resetTooltip}>
              <Button
                variant="outlined"
                onClick={() => {
                  setCorrection(initCorrection)
                  setCorrectionEdited(false)
                }}
                disabled={!correctionEdited}
                sx={{ ml: 0, mr: 'auto' }}
                startIcon={<SettingsBackupRestoreIcon />}
              >
                {
                  intl.formatMessage({
                    id: 'app.reset-correction.label',
                    defaultMessage: 'Reset'
                  })
                }
              </Button>
            </Tooltip>
            <Tooltip title={cancelTooltip}>
              <CancelButton onClick={() => { setOpen(false) }} />
            </Tooltip>
            <Tooltip title={submitTooltip}>
              <Button
                type="submit"
                variant="contained"
                disabled={!correctionEdited}
                startIcon={<SaveIcon />}
              >
                {
                  intl.formatMessage({
                    id: 'app.submit-correction.label',
                    defaultMessage: 'Submit'
                  })
                }
              </Button>
            </Tooltip>
          </Box>
        </FormControl>
      </form>
    </Box>
  )

  return <>
    <EditButton
      onClick={() => { setOpen(true) } }
      previouslyEdited={previouslyEdited}
    />
    {/* Correction modal */}
    <Modal
      open={open}
      onClose={() => { setOpen(false) }}
      aria-labelledby="correction-modal-title"
      aria-describedby="correction-modal-description"
    >
      <ModalContainer>
        <Typography id="correction-modal-title" variant="h4">
          {
            intl.formatMessage({
              id: 'app.correction-modal.title',
              defaultMessage: 'Suggest a correction'
            })
          }
        </Typography>
        <Typography id="correction-modal-description">
          {
            intl.formatMessage({
              id: 'app.correction-modal.description',
              defaultMessage: (
                'This will be used for further improving the system.{br}' +
                'Note that for now, the correction is NOT applied to the current conversation.'
              )
            }, { br: <br /> })
          }
        </Typography>
        <Box>
          {correction !== undefined ? editForm : <CircularProgress />}
        </Box>
      </ModalContainer>
    </Modal>
  </>
}

export default CorrectionModal
