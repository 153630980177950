import { createAuthHeaderFromToken, axiosInstance } from 'services/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useSession } from 'context/SessionContext'
import { type AttachmentsAndUploads } from '../types'

interface Props {
  withContent: boolean
  autoRefetch: boolean
}

/**
 * Fetches the list of attachments for the current session,
 * both uploaded and pending, without their content.
 */
export function useAttachments ({ withContent, autoRefetch }: Props): UseQueryResult<AttachmentsAndUploads, Error> {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()

  const queryFn = async (): Promise<AttachmentsAndUploads> => {
    if (selectedSession === null) {
      throw new Error('No session selected')
    }

    return await getAccessTokenSilently().then(async (token) =>
      await getAttachments(token, selectedSession.id, withContent)
    )
  }

  // If autoRefetch is true, we will refetch every 4 seconds.
  // This should give enough time for the Celery task to start,
  // such that it will be returned by the API.
  const refetchInterval = autoRefetch ? 4000 : false

  return useQuery({
    queryKey: ['sessions', selectedSession?.id ?? 'null', withContent ? 'attachmentsWithContent' : 'attachments'],
    queryFn,
    // If autoRefetch is true, we will refetch every 2 seconds
    refetchInterval,
    enabled: selectedSession !== null
  })
}

const getAttachments = async (
  token: string,
  sessionId: string,
  returnContent: boolean
): Promise<AttachmentsAndUploads> => {
  const authHeader = createAuthHeaderFromToken(token)
  const url = `/sessions/${sessionId}/attachments?return_pending=1&return_content=${returnContent ? '1' : '0'}`
  const response = await axiosInstance.get<AttachmentsAndUploads>(
    url,
    {
      headers: authHeader,
      cache: false
    }
  )
  return response.data
}
