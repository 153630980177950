import {
  type SxProps,
  type Theme
} from '@mui/material'
import { type AnonymizeResponse } from 'features/anonymization/api/anonymize'
import { applyAliasRulesToAnonymizationSettings, applyAnonRulesToAnonymizationSettings } from 'features/anonymization/api/updateAnonymizationSettings'
import { type AliasRule, type AnonRule, type AnonymizationSettings, type Entity, type EntityType, type MsgSpecificRules, type RuleKey, type Tag } from 'features/anonymization/types'
import { type Globals } from 'features/globals/types'
import { addMsgSpecificRules } from 'features/anonymization/api/addMsgSpecificRules'
import { type User } from '../context/AuthContext'

export enum PosAnonymizeStatus {
  NONE = 0,
  SOME = 1,
  ALL = 2
}

export const posAnonymizeStatusToBooleanOrNull = (status: PosAnonymizeStatus): boolean | null => {
  if (status === PosAnonymizeStatus.NONE) return false
  if (status === PosAnonymizeStatus.ALL) return true
  return null
}

export interface ReviewStateItem {
  cleartext: string
  entityType: EntityType
  entityId: number | null
  anonymize: boolean | null
  posAnonymizeStatus: PosAnonymizeStatus
  aliases: string[]
}

export interface ReviewState {
  texts: string[]
  anonTexts: string[]
  inputDiffs: Array<Array<[string, number, Tag | null]>>
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  items: ReviewStateItem[]
  existingEntities: Entity[]
  existingEntitiesWithAliases: Entity[]
  tags: Tag[]
}

export const compareReviewStateItems = (a: ReviewStateItem, b: ReviewStateItem): number => {
  return a.cleartext.toLowerCase().localeCompare(b.cleartext.toLowerCase())
}

/**
 * Create a mapping from entity type to all active entities cleartexts,
 * excluding the aliases cleartexts
 */
export const createEntityTypeToActiveEntitiesCleartext = (items: ReviewStateItem[]): Map<EntityType, string[]> => {
  // List all aliases cleartexts, in order to exclude them from the list of active entities
  const aliasesCleartexts = new Set(items.flatMap((item) => item.aliases))

  const mapping = new Map<EntityType, string[]>()
  items.forEach((item) => {
    if (
      item.anonymize === false ||
      aliasesCleartexts.has(item.cleartext)
    ) return
    if (mapping.has(item.entityType)) {
      mapping.get(item.entityType)?.push(item.cleartext)
    } else {
      mapping.set(item.entityType, [item.cleartext])
    }
  })

  return mapping
}

/**
 * Create the review modal state based on the anonymization API response
 */
export const responseToState = (
  response: AnonymizeResponse,
  settings?: AnonymizationSettings,
  globals?: Globals
): ReviewState => {
  // Sort the items
  const items = response.reviewItems
  items.sort(compareReviewStateItems)

  const entityTypeToActiveEntitiesCleartext = createEntityTypeToActiveEntitiesCleartext(items)

  const tags = (
    response.inputDiffs.flatMap((inputDiff) =>
      inputDiff.map(([cleartext, pos, tag]) => tag).filter((tag) => tag !== null) as Tag[]
    )
  )

  const existingEntitiesWithAliases = new Set<Entity>([...response.existingEntities])
  tags.forEach((tag) => {
    if (tag.entityId !== null) {
      const entity: Entity = {
        cleartext: tag.cleartext,
        entityType: tag.entityType,
        entityId: tag.entityId
      }
      if (!existingEntitiesWithAliases.has(entity)) {
        existingEntitiesWithAliases.add(entity)
      }
    }
  })

  return {
    texts: response.texts,
    anonTexts: response.anonTexts,
    inputDiffs: response.inputDiffs,
    entityTypeToActiveEntitiesCleartext,
    items,
    existingEntities: response.existingEntities,
    existingEntitiesWithAliases: Array.from(existingEntitiesWithAliases),
    tags
  }
}

/**
 * Returns the text and background color for the replacement tag
 * @param entityType Entity type of the tag
 * @param anonymize Whether the tag anonymizes something or not
 * @param entityTypeToColor Mapping from entity type to color
 * @returns [text color, background color]
 */
export const getReplacementColors = (
  entityType: EntityType | null,
  anonymize: boolean | null,
  entityTypeToColor: Map<EntityType, string>,
  palette: Theme['palette']
): [string, string] => {
  if (anonymize === false) {
    // Make it looks disabled
    // DEBUG: use theme !
    return ['disabled', 'background.main']
  }

  if (entityType === null) {
    // DEBUG: use theme !
    return ['yellow', 'green']
  }

  // Make sure that the tag's background color will be different from theme's background color
  const defaultBgColor = palette.mode === 'light' ? '#000000' : '#ffffff'
  let bgColor = entityTypeToColor.get(entityType) ?? defaultBgColor
  const colorModeBgColor = palette.mode === 'light' ? '#ffffff' : '#000000'
  if (bgColor === colorModeBgColor) {
    bgColor = defaultBgColor
  }

  const textColor = palette.getContrastText(bgColor)

  return [textColor, bgColor]
}

export const tagReplacementDefaultSx: SxProps<Theme> = {
  px: 0.1,
  mx: 0.1
}

export const itemReplacementSx = (
  item: ReviewStateItem,
  entityTypeToColor: Map<EntityType, string>,
  palette: Theme['palette']
): SxProps<Theme> => {
  const [color, bgcolor] = getReplacementColors(
    item.entityType,
    item.anonymize,
    entityTypeToColor,
    palette
  )
  return {
    ...tagReplacementDefaultSx,
    color,
    bgcolor
  }
}

export const tagReplacementSx = (
  tag: Tag | null,
  entityTypeToColor: Map<EntityType, string>,
  palette: Theme['palette']
): SxProps<Theme> => {
  const [color, bgcolor] = getReplacementColors(
    tag?.entityType ?? null,
    true,
    entityTypeToColor,
    palette
  )
  return {
    ...tagReplacementDefaultSx,
    color,
    bgcolor
  }
}

/**
 * Create styling for the replacement selector (dropdown menu to change an entity)
 * @param entityType Type of the entity, for which different colors are used
 * @param anonymize Whether the entity is anonymized, not anonymized or let the AI decide
 * @param entityTypeToColor Mapping from entity type to color
 * @returns SxProps for the replacement selector
 */
export const replacementSelectorSx = (
  entityType: EntityType,
  anonymize: boolean | null,
  isDefaultSetting: boolean,
  entityTypeToColor: Map<EntityType, string>,
  palette: Theme['palette']
): SxProps<Theme> => {
  const [color, bgcolor] = getReplacementColors(
    entityType,
    anonymize,
    entityTypeToColor,
    palette
  )

  const selectorDisabled = (isDefaultSetting || anonymize === null)

  return {
    color,
    bgcolor,
    WebkitTextFillColor: color,
    '.MuiTypography-root': {
      color,
      bgcolor,
      WebkitTextFillColor: color
    },
    // MuiSvgIcon-root is triangle icon on the right side
    '.MuiSvgIcon-root': {
      color: selectorDisabled ? bgcolor : color
    },
    '.MuiSvgIcon-root.Mui-disabled': {
      color: selectorDisabled ? bgcolor : 'gray'
    },
    '& .MuiSelect-select': {
      paddingRight: 4,
      paddingLeft: 2,
      paddingTop: 1,
      paddingBottom: 1
    }
  }
}

/**
 * A tag with a replacement ending in '_0' is a default setting.
 * We want to identify these tags so that they can't be disabled.
 */
export const isDefaultSettingTag = (tag: Tag): boolean => {
  return tag.entityId === 0
}

export const isDefaultSettingItem = (item: ReviewStateItem): boolean => {
  return item.entityId === 0
}

export const areAliasesEnabled = (item: ReviewStateItem, globals: Globals): boolean => {
  let aliasesEnabled = false
  globals.entityTypesByGroup.forEach((group) => {
    group.forEach((entityType) => {
      if (entityType.entityType === item.entityType && entityType.aliasesEnabled) {
        aliasesEnabled = true
      }
    })
  })
  return aliasesEnabled
}

export const tagToReplacement = (tag: Tag): string | null => {
  if (tag.entityId === null) return null
  return tag.entityType + '_' + tag.entityId.toString()
}

export const itemToReplacement = (item: ReviewStateItem): string | null => {
  if (item.entityId === null) return null
  return item.entityType + '_' + item.entityId.toString()
}

/**
 * Applies the user's change on whether to globally anonymize or not
 * a particular entity and returns the updated anonymization settings.
 * @param item The review item to change
 * @param user The user
 * @returns Updated anonymization settings
 */
export const changeItemInAnonSettings = async (
  token: string,
  item: ReviewStateItem,
  user: User
): Promise<AnonymizationSettings> => {
  let rulesToAdd: AnonRule[] = []
  let keysToDelete: RuleKey[] = []

  // Did the user change the rule to always or never anonymize?
  if (item.anonymize !== null) {
    const rule: AnonRule = {
      cleartext: item.cleartext,
      entityType: item.entityType,
      anonymize: item.anonymize
    }
    rulesToAdd = [rule]
  } else {
    // Otherwise, if we let the AI decide then we should delete the rule from the settings
    keysToDelete = [{
      cleartext: item.cleartext,
      entityType: item.entityType
    }]
  }

  return await applyAnonRulesToAnonymizationSettings(
    token,
    user,
    rulesToAdd,
    keysToDelete
  )
}

/**
 * Modify the anonymization settings to anonymize a particular
 * cleartext whenever it appears it the text.
 * @param cleartext Text to anonymize whenever it appears
 * @param entityType Entity type to associated with it
 * @param asAliasOf Optionally, cleartext to which it is an alias of
 * @param user The user
 * @param anonymizationSettings The current anonymization settings
 * @returns Updated anonymization settings
 */
export const addExactMatchToAnonSettings = async (
  token: string,
  cleartext: string,
  entityType: EntityType,
  asAliasOf: string | undefined,
  user: User,
  anonymizationSettings: AnonymizationSettings
): Promise<AnonymizationSettings> => {
  const rule: AnonRule = {
    cleartext,
    entityType,
    anonymize: true
  }
  const rulesToAdd = [rule]

  const newAnonymizationSettings = await applyAnonRulesToAnonymizationSettings(
    token,
    user,
    rulesToAdd,
    []
  )

  // Is there also an alias rule to add?
  if (asAliasOf !== undefined) {
    // If so, add it to the anonymization settings before applying the changes.

    // Find an existing alias rule for that cleartext, if any
    const existingRule: AliasRule | undefined = anonymizationSettings.aliasRules.find((rule) => rule.cleartext === asAliasOf && rule.entityType === entityType)
    const aliasRulesToAdd: AliasRule[] = [existingRule ?? {
      cleartext: asAliasOf,
      entityType,
      aliases: [cleartext]
    }]
    // FIXME: this could be done more efficiently with 1 API call
    // to create both the anon and alias rule! (and maybe call the anonymization
    // API as well)
    return await applyAliasRulesToAnonymizationSettings(
      token,
      user,
      aliasRulesToAdd,
      []
    )
  }

  // Otherwise just return the new anonymization settings
  return newAnonymizationSettings
}

export const addExactMatchToMsgSpecificRules = async (
  token: string,
  cleartext: string,
  entityType: EntityType,
  position: [number, number] | undefined,
  asAliasOf: string | undefined,
  msgSpecificRules: MsgSpecificRules,
  user: User
): Promise<MsgSpecificRules> => {
  return await addMsgSpecificRules(
    token,
    cleartext,
    entityType,
    position,
    asAliasOf,
    msgSpecificRules,
    user
  )
}

/**
 * Find the items that are aliases of the given item.
 * @param item item to find aliases of
 * @param items items to search in
 * @returns items that are aliases of the given item
 */
export const findAliasItems = (item: ReviewStateItem, items: ReviewStateItem[]): ReviewStateItem[] => {
  const aliasItems: ReviewStateItem[] = []
  items.forEach((otherItem) => {
    if (
      item !== otherItem &&
      item.aliases.includes(otherItem.cleartext) &&
      otherItem.entityType === item.entityType
    ) {
      aliasItems.push(otherItem)
    }
  })
  return aliasItems
}

/**
 * Find the item of which the given item is an alias of, if any
 * @param item item to find the parent of
 * @param items items to search in
 * @returns item of which the given item is an alias of, or null if none
 */
export const aliasOf = (item: ReviewStateItem, items: ReviewStateItem[]): ReviewStateItem | null => {
  let aliasOfItem: ReviewStateItem | null = null
  items.every((otherItem) => {
    if (
      item !== otherItem &&
      otherItem.aliases.includes(item.cleartext) &&
      otherItem.entityType === item.entityType
    ) {
      aliasOfItem = otherItem
      return false
    }
    return true
  })
  return aliasOfItem
}

export const aggregatePosAnonymizeStatuses = (items: ReviewStateItem[]): PosAnonymizeStatus => {
  let hasNone = false
  let hasSome = false
  let hasAll = false
  items.forEach((item) => {
    if (item.posAnonymizeStatus === PosAnonymizeStatus.NONE) {
      hasNone = true
    } else if (item.posAnonymizeStatus === PosAnonymizeStatus.SOME) {
      hasSome = true
    } else if (item.posAnonymizeStatus === PosAnonymizeStatus.ALL) {
      hasAll = true
    }
  })
  if (hasNone && !hasSome && !hasAll) return PosAnonymizeStatus.NONE
  if (!hasNone && !hasSome && hasAll) return PosAnonymizeStatus.ALL
  return PosAnonymizeStatus.SOME
}
