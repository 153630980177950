import { type User } from 'context/AuthContext'
import { createAuthHeaderFromToken, axiosInstance, defaultTTL } from 'services/axios'
import { type Report } from '../types'

export const createReport = async (
  token: string,
  report: Report,
  user: User
): Promise<void> => {
  const authHeader = createAuthHeaderFromToken(token)
  await axiosInstance.post(
    '/reports',
    report,
    {
      headers: authHeader,
      cache: {
        ttl: defaultTTL
      }
    }
  )
}
