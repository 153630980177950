import { TableCell, styled } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1em',
  fontWeight: 'bold',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}))

export const filenameCellSx = { width: '300px' }

export const selectorCellSx = {
  width: '200px',
  maxWidth: '200px'
}
