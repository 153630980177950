import IconButton from '@mui/material/IconButton'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { Tooltip, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import type * as React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  open: boolean
  handleDrawerOpen: () => void
  handleDrawerClose: () => void
  anchor: 'left' | 'right'
  hovering: boolean
}

export const DRAWER_WIDTH = 320

/**
 * Renders a button that opens or closes the side panel.
 */
const DrawerPaneButton: React.FC<Props> = (
  { open, handleDrawerOpen, handleDrawerClose, anchor, hovering }: Props
): JSX.Element => {
  const intl = useIntl()
  const theme = useTheme()

  const xOffset = open ? DRAWER_WIDTH - 12 : 12

  const tooltip = (
    open
      ? intl.formatMessage({
        id: 'app.close-side-panel',
        defaultMessage: 'Close side panel'
      })
      : intl.formatMessage({
        id: 'app.open-side-panel',
        defaultMessage: 'Open side panel'
      })
  )

  return <Box
      className='drawer-pane-button'
      sx={{
        position: 'absolute',
        top: '50%',
        ...(anchor === 'left' ? { left: xOffset } : { right: xOffset }),
        zIndex: 1201 // Above the drawer
      }}
    >
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            onClick={() => { open ? handleDrawerClose() : handleDrawerOpen() }}
            sx={{
              display: (hovering || !open) ? 'flex' : 'none',
              height: '33vh',
              transform: 'translateY(-50%)',
              mt: 'auto',
              mb: 'auto',
              p: 0,
              borderRadius: 4,
              color: 'primary.contrastText',
              backgroundColor: alpha(theme.palette.primary.main, open ? 0.5 : 0.1),
              '&:hover': {
                backgroundColor: 'primary.main'
              }
            }}
          >
            {open !== (anchor === 'left')
              ? <KeyboardDoubleArrowRightIcon />
              : <KeyboardDoubleArrowLeftIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
}

export default DrawerPaneButton
