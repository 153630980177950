import { useEffect, useState } from 'react'
import { acceptTerms } from 'features/user/api/acceptTerms'
import { getOrCreateUserProfile } from 'features/user/api/createUserProfile'
import { type UserProfile } from 'features/user/types'
import { Navigate, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthModalContainer } from '../components/AuthModalContainer'
import { BackToAuthButton } from '../components/BackToAuthButton'
import { ModalHeader } from '../theme'
import TermsForm from '../components/TermsForm'
import { useIntl } from 'react-intl'

/**
 * Page to which Auth0 redirects after the user has signed in.
 * If the user is new, it shows a form to create a new user profile.
 */
export const CallbackPage = (): JSX.Element => {
  const intl = useIntl()
  const {
    error,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0()
  const navigate = useNavigate()

  const [userProfile, setUserProfile] = useState<UserProfile | null | undefined>(undefined)

  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [verifyEmailFirst, setVerifyEmailFirst] = useState(false)

  const verifyEmailMsg = intl.formatMessage({
    id: 'app.callback-page.verify-email-msg',
    defaultMessage: 'Please verify your email before logging in.'
  })

  useEffect(() => {
    console.debug('> CallbackPage [error, isLoading, isAuthenticated]')

    if (error !== null && error !== undefined) {
      // Login will fail if the user's email is not verified,
      // so we need to inform the user about this.
      if (error.message === verifyEmailMsg) {
        setVerifyEmailFirst(true)
        return
      }
      // Otherwise, show the error message
      setErrorMsg(error.message)
      return
    }

    // Wait for loading to finish
    if (isLoading) {
      return
    }

    // Wait for the user to be retrieved by the Auth0 context
    if (user === undefined) {
      return
    }

    // Check if the user's email is verified
    if (!(user.email_verified ?? false)) {
      console.debug('User email not verified')
      setVerifyEmailFirst(true)
      return
    }

    // Try getting the user's profile from the API, if available
    void getAccessTokenSilently().then(async (token) =>
      await getOrCreateUserProfile(token)
    ).then((profile) => {
      setUserProfile(profile)
    }).catch((err) => {
      console.debug('Got error:', err)
      if (isAuthenticated) {
        // Show the error message if the user is authenticated
        setErrorMsg(err.message)
      } else {
        // Redirect to the home page if the user is not authenticated
        console.debug('Redirecting to the home page')
        navigate('/login', { replace: true })
      }
    })
  }, [error, isLoading, isAuthenticated])

  if (verifyEmailFirst) {
    return (
      <AuthModalContainer>
        <ModalHeader>
          <Typography variant="h4">
            {
              intl.formatMessage({
                id: 'app.callback-page.verify-email',
                defaultMessage: 'Please verify your email address'
              })
            }
          </Typography>
          <BackToAuthButton />
        </ModalHeader>
        <Typography variant="body1">
          {
            intl.formatMessage({
              id: 'app.callback-page.verify-email-instructions',
              defaultMessage: (
                'For security, we need to verify your email address before you can continue.{br}' +
                'Please check your email for a message from us and follow the instructions.'
              )
            }, {
              br: <br />
            })
          }
        </Typography>
        <Typography variant="body1">
          {
            intl.formatMessage({
              id: 'app.callback-page.verify-email-contact',
              defaultMessage: 'If you didn\'t receive the email, please {openA}contact us{closeA}.'
            }, {
              openA: <a href="mailto:contact@gatewai.tech">
              closeA: </a>
            })
          }
        </Typography>
      </AuthModalContainer>
    )
  }

  if (errorMsg !== null) {
    return (
      <AuthModalContainer>
        <ModalHeader>
          <Typography variant="h4">
            {
              intl.formatMessage({
                id: 'app.callback-page.error-modal.title',
                defaultMessage: 'Something went wrong'
              })
            }
          </Typography>
          <BackToAuthButton />
        </ModalHeader>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1">
            {
              intl.formatMessage({
                id: 'app.callback-page.error-modal.message',
                defaultMessage: 'Please contact us at {email} for help, including the following message:'
              }, {
                email: <a href="mailto:contact@gatewai.tech">contact@gatewai.tech</a>
              })
            }
          </Typography>
          {/* Error message in a text area, selectable but not changeable */}
          <TextField
            fullWidth
            multiline
            rows={10}
            value={errorMsg}
            contentEditable={false}
          />
        </Box>
      </AuthModalContainer>
    )
  }

  const pleaseWait = <>
    <Box sx={{ alignSelf: 'flex-end' }}>
      <BackToAuthButton />
    </Box>
    <Typography variant="h4">
      {
        intl.formatMessage({
          id: 'app.callback-page.please-wait',
          defaultMessage: 'Please wait'
        })
      }
    </Typography>
    <CircularProgress sx={{ ml: 'auto', mr: 'auto', mb: 2 }} />
  </>

  if (userProfile === undefined) {
    return (
      <AuthModalContainer>
        {pleaseWait}
      </AuthModalContainer>
    )
  }

  const handleAcceptTerms = (): void => {
    void getAccessTokenSilently().then(async (token) => {
      await acceptTerms(token)
    }).then(() => {
      navigate('/')
    }).catch((err) => {
      setErrorMsg(err.message)
    })
  }

  if (userProfile !== undefined && userProfile !== null) {
    // Must accept terms before continuing
    if (!userProfile.termsAccepted) {
      return (
        <AuthModalContainer sx={{ width: '800px' }}>
          <ModalHeader>
            <Typography variant="h4">
            {
              intl.formatMessage({
                id: 'app.callback-page.almost-there',
                defaultMessage: 'Almost there!'
              })
            }
            </Typography>
            <BackToAuthButton />
          </ModalHeader>
          <TermsForm onAccept={handleAcceptTerms} />
        </AuthModalContainer>
      )
    }

    // All good, redirect to the home page
    return (
      <AuthModalContainer>
        {pleaseWait}
        <Navigate to="/" />
      </AuthModalContainer>
    )
  }

  /**
   * Wait for the user profile to be created (?)
   */
  return (
    <AuthModalContainer>
      {pleaseWait}
    </AuthModalContainer>
  )
}

export default CallbackPage
