import { Box, styled } from '@mui/material'

export const TabContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: '400px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: theme.spacing(1),
  scrollbarGutter: 'stable',
  // Only show the vertical scrollbar when hovering,
  // to save some space
  overflowY: 'hidden',
  ':hover': { overflowY: 'auto' },
  // Never show the horizontal scrollbar.
  // The table should always be wide enough to show all columns,
  // but when the vertical bar is shown, the horizontal bar
  // is also shown, even if it is not needed.
  // This way, we keep the horizontal scrollbar hidden
  // at all times.
  overflowX: 'hidden'
}))

export const StyleTableContainer = styled(Box)(() => ({
  flexGrow: 1,
  flexShrink: 1,
  padding: '0px 8px 0px 8px'
}))

export const GlobalRulesPaneContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0
}))
