import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { Tooltip, type SxProps, type Theme } from '@mui/material'
import { useState } from 'react'
import { StyledIconButton } from './ThumbsButtons.styles'
import { useIntl } from 'react-intl'

interface CopyToClipboardButtonProps {
  buttonSxProps?: SxProps<Theme>
  onClick: () => void
  tooltip?: string
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  onClick,
  tooltip
}: CopyToClipboardButtonProps) => {
  const intl = useIntl()
  const [clicked, setClicked] = useState<boolean>(false)

  const handleClick = (): void => {
    setClicked(true)
    onClick()
    // Reset clicked state after 5 seconds (for the animation to end)
    setTimeout(() => { setClicked(false) }, 1000)
  }

  const title = tooltip ?? intl.formatMessage({
    id: 'app.copy-to-clipboard',
    defaultMessage: 'Copy to clipboard'
  })

  return <Tooltip title={title}>
    <StyledIconButton onClick={handleClick}>
      {clicked ? <AssignmentTurnedInIcon /> : <ContentPasteIcon />}
    </StyledIconButton>
  </Tooltip>
}
