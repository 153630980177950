import {
  Box,
  Button,
  Divider,
  Modal,
  Typography
} from '@mui/material'
import type React from 'react'
import { ModalContainer } from '../theme'
import { useIntl } from 'react-intl'

interface Props {
  open: boolean
  onClose: () => void
}

export const ErrorModal: React.FC<Props> = ({ open, onClose }) => {
  const intl = useIntl()
  return (
    <Modal
      open={open}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <ModalContainer>
        <Typography id="error-modal-title" variant="h4">
          {
            intl.formatMessage({
              id: 'app.error-modal.title',
              defaultMessage: 'Something went wrong'
            })
          }
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography id="error-modal-body" variant="body1">
          <p>{
            intl.formatMessage({
              id: 'app.error-modal.body.paragraph1',
              defaultMessage: 'We apologize for any inconvenience, but it seems there is a temporary technical issue on our end. Please try again later.'
            })
          }</p>
          <p>{
            intl.formatMessage({
              id: 'app.error-modal.body.paragraph2',
              defaultMessage: 'Our team has been notified of the issue and will work to resolve it as soon as possible.'
            })
          }</p>
        </Typography>
        <Box sx={{ p: 1, flexBasis: '50px', alignSelf: 'end' }}>
          <Button
            type="submit"
            variant="contained"
            onClick={() => { onClose() }}
          >
            OK
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ErrorModal
