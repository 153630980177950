import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type UserSettings } from '../types'

export const updateUserSettings = async (
  token: string,
  user: User,
  update: Partial<UserSettings>
): Promise<void> => {
  await axiosInstance.patch(
    '/users/me/settings',
    {
      assistantId: update.assistantId,
      confidentialityLevel: update.confidentialityLevel?.level,
      anonymizationSettings: update.anonymizationSettings
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        update: {
          'get-user-settings': 'delete'
        },
        ttl: defaultTTL
      }
    }
  )
}
