import { Auth0Provider, type AppState, type AuthorizationParams } from '@auth0/auth0-react'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

export const Auth0ProviderWithNavigate: React.FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE_URL

  const onRedirectCallback = (appState?: AppState): void => {
    const returnTo: string | undefined = appState?.returnTo
    const to = returnTo ?? window.location.pathname
    console.debug('Redirecting to:', to)
    navigate(to)
  }

  if (domain === undefined || clientId === undefined || audience === undefined) {
    throw new Error('REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID and REACT_APP_AUTH0_AUDIENCE_URL must be set')
  }

  const authorizationParams: AuthorizationParams = {
    ...(
      redirectUri !== undefined
        ? {
            redirect_uri: redirectUri
          }
        : {}
    ),
    audience
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}
